import styled from '@emotion/styled';
import React from 'react';

type ActivityPickerProps = {
	activity: string;
	onChange: (activity: string) => void;
};

function ActivityPicker({ activity, onChange }: ActivityPickerProps) {
	const Wrapper = styled('div')`
		display: flex;
		width: 100%;
		max-width: 1240px;
		margin: auto;
		padding: 10px;
		gap: 32px;
	`;

	type ActivityProps = {
		selected: boolean;
	};
	const Activity = styled('div')(
		(props: ActivityProps) => `
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 8px;
		cursor: pointer;
        &:hover {
			p {
            	font-weight: bold;
			}
			div {
				border: ${props.selected ? '2px solid var(--green)' : '2px solid var(--gold)'};
			}
        }
        p { font-weight:  ${props.selected ? 'bold' : 'normal'}; }
        div { border: ${props.selected ? '2px solid var(--green)' : '1px solid var(--separator)'};}
	`
	);

	type ImageProps = {
		src: string;
	};
	const ActivityImage = styled('div')(
		(props: ImageProps) => `
        background-color: var(--white);
        background-image: url("${props.src}");
        background-size: 90% 90%;
        aspect-ratio: 1;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 20px;
        height: 100px;
        padding: 12px;
    `
	);

	return (
		<Wrapper>
			<Activity selected={activity === 'Ultimate'} onClick={() => onChange('Ultimate')}>
				<ActivityImage src={'/images/partners/ultimate.svg'} />
				<p>Ultimate</p>
			</Activity>
			<Activity selected={activity === 'Quadball'} onClick={() => onChange('Quadball')}>
				<ActivityImage src={'/images/partners/quadball.svg'} />
				<p>Quad Ball</p>
			</Activity>
			<Activity selected={activity === 'Basketball'} onClick={() => onChange('Basketball')}>
				<ActivityImage src={'/images/partners/basketball.svg'} />
				<p>Basketball</p>
			</Activity>
			<Activity selected={activity === 'Maccabi'} onClick={() => onChange('Maccabi')}>
				<ActivityImage src={'/images/partners/maccabi.svg'} />
				<p>Maccabi</p>
			</Activity>
			<Activity selected={activity === 'Volleyball'} onClick={() => onChange('Volleyball')}>
				<ActivityImage src={'/images/partners/volleyball.svg'} />
				<p>Volleyball</p>
			</Activity>
		</Wrapper>
	);
}

export default ActivityPicker;
