import styled from '@emotion/styled';
import React from 'react';
import * as Icons from 'react-feather';

import { BundleProductFragment } from '../../generated/graphql';
import BundleProductPrice from './BundleProductPrice';

type Props = {
	product: BundleProductFragment;
	onClick: (product: BundleProductFragment) => void;
};

const BundleProductPreview = ({ product, onClick }: Props) => {
	const ProductPreview = styled('div')`
		display: flex;
		flex-direction: column;
		gap: 8px;
		user-select: none;

		@media only screen and (max-width: 900px) {
		}
	`;

	type PanelProps = {
		src: string;
	};
	const ProductImage = styled('div')(
		(props: PanelProps) => `
        background-color: var(--white);
        background-image: url("${props.src}");
        background-size: contain;
        aspect-ratio: 1;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 20px;
        border: 1px solid var(--separator);
        overflow: hidden;
        cursor: pointer;
        .overlay-panel {
            display: none;
        }
        &:hover {
            .overlay-panel {
                background-color: rgba(255, 255, 255, .8);
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    `
	);

	const ProductName = styled('h6')`
		margin-left: 8px;
		margin-top: 8px;
		margin-bottom: 0;
	`;

	return (
		<ProductPreview onClick={() => onClick(product)}>
			<ProductImage src={product.bundle_builder_image_url || 'placeholder.png'}>
				<div className={'overlay-panel'}>
					<Icons.PlusCircle size={64} />
				</div>
			</ProductImage>
			<ProductName>{product.label}</ProductName>
			<BundleProductPrice product={product} />
		</ProductPreview>
	);
};

export default BundleProductPreview;
