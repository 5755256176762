import { Product } from '../../stores/cart/action';

export const cleanNumberFormat = (inputNumber: string): string => {
	return inputNumber.replace(/[, ]+/g, '').trim();
};

export const formatCurrencyValueFromServer = (value: number | string) => {
	if (typeof value === 'string') {
		return formatValueWithCurrency(parseInt(value, 10) / 100);
	}
	return formatValueWithCurrency(value / 100);
};

export const formatValueWithCurrency = (value: number, includeDecimals = true): string => {
	return Intl.NumberFormat('en-us', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: includeDecimals ? 2 : 0
	}).format(value);
};

interface DiscountOptions {
	type?: 'dollar' | 'percent';
	discountRate?: number;
	incomingValue: number;
	allowNegativeValues?: boolean;
}

const MAX_DISCOUNT_PERCENT_RATE = 76;

export const handleStoreDiscount = (options: DiscountOptions): number => {
	if (options.type === 'dollar' && options.discountRate && options.discountRate > 0) {
		const updatedValue = options.incomingValue - options.discountRate;
		return updatedValue && updatedValue > 0 ? updatedValue : options.incomingValue;
	} else if (
		options.type === 'percent' &&
		options.discountRate &&
		options.discountRate > 0 &&
		options.discountRate < MAX_DISCOUNT_PERCENT_RATE
	) {
		const updatedValue = options.incomingValue - (options.discountRate / 100) * options.incomingValue;

		if (options.allowNegativeValues) {
			return updatedValue ? updatedValue : options.incomingValue;
		}
		const NonZeroDefault = 0;
		return updatedValue && updatedValue > 0 ? Math.round(updatedValue) : NonZeroDefault;
	}
	return options.incomingValue;
};

interface DisplableStringOptions {
	name: string;
	price?: string;
	ignorePriceModifier?: boolean;
	discountType?: 'percent' | 'dollar';
	discountRate?: number;
}

export const getProductOptionLabel = (options: DisplableStringOptions): string => {
	const { name, ignorePriceModifier, price, discountRate, discountType } = options;

	if (ignorePriceModifier && price && price !== '0' && parseInt(price, 10) < 0) {
		return name;
	}

	if (price && price !== '0') {
		if (discountType && discountType === 'percent' && discountRate && discountRate > 0) {
			const updatedValue = handleStoreDiscount({
				allowNegativeValues: true,
				incomingValue: parseInt(price),
				discountRate,
				type: discountType
			});
			return `${name} (${formatCurrencyValueFromServer(updatedValue)})`;
		}
		return `${name} (${formatCurrencyValueFromServer(price)})`;
	}
	return name;
};

interface AlteredValueOptions {
	price: number;
	ignorePriceModifier?: boolean;
	discountType?: 'percent' | 'dollar';
	discountRate?: number;
}

export const getProductOptionAlteredValue = (options: AlteredValueOptions): number => {
	const { price, discountRate, discountType } = options;
	if (discountType && discountType === 'percent' && discountRate && discountRate > 0) {
		return handleStoreDiscount({
			allowNegativeValues: true,
			incomingValue: price,
			discountRate: discountRate,
			type: discountType
		});
	}
	return price;
};

interface PriceModifierOptions {
	ignoreNegativeValue?: boolean;
	priceModifier?: number;
	discountType?: 'percent' | 'dollar';
	discountRate?: number;
}

export const calculatePriceModified = (options: PriceModifierOptions): number => {
	if (options.ignoreNegativeValue && options.priceModifier && options.priceModifier < 0) {
		return 0;
	} else {
		return getProductOptionAlteredValue({
			price: options.priceModifier || 0,
			discountRate: options.discountRate,
			discountType: options.discountType
		});
	}
};

export const calculateBundledItemsTotalAddonPrice = (bundleItems: Product[]) => {
	return bundleItems.reduce((total, item) => {
		// Add the base price of the item
		let itemTotal = 0;

		// Iterate through options and add priceModifiedAs if greater than 0
		Object.values(item.options).forEach((option) => {
			if (option.priceModifiedAs > 0) {
				itemTotal += option.priceModifiedAs;
			}
		});

		return total + itemTotal;
	}, 0);
};
