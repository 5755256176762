import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { RouteDefinitions } from '../routeDefinitions';
import Button from './Button';
import ProductPreview, { ProductPreviewProps } from './ProductPreview';

const products: ProductPreviewProps[] = [
	{
		id: '123',
		image: '/images/bundles/legend-bundle.jpg',
		name: 'Legendary Bundle',
		stars: 4.5,
		basePrice: 25000,
		customPriceOverride: 11000,
		navPath: RouteDefinitions.TEAM_APPAREL()
	},
	{
		id: '456',
		image: '/images/bundles/epic-bundle.jpg',
		name: 'Epic Bundle',
		stars: 3.5,
		basePrice: 24000,
		customPriceOverride: 10000,
		navPath: RouteDefinitions.TEAM_APPAREL()
	},
	{
		id: '789',
		image: '/images/bundles/mvp-bundle.jpg',
		name: 'MVP Bundle',
		basePrice: 23500,
		customPriceOverride: 9000,
		navPath: RouteDefinitions.TEAM_APPAREL()
	},
	{
		id: '999',
		image: '/images/bundles/all-star-bundle.jpg',
		name: 'All Star Bundle',
		basePrice: 12000,
		customPriceOverride: 7500,
		navPath: RouteDefinitions.TEAM_APPAREL()
	}
];
const FeaturedDealsSectionWrapper = styled('section')`
	display: flex;
	flex-direction: column;
	width: 95%;
	max-width: 1240px;
	margin: auto;
	overflow: hidden;
	gap: 50px;
	align-items: center;
	justify-content: center;
	padding: 70px;
	@media only screen and (max-width: 900px) {
		padding: 24px;
	}
`;

function FeaturedDealsSection() {
	return (
		<FeaturedDealsSectionWrapper>
			<h2>CUSTOMIZE YOUR TEAM BUNDLE</h2>
			<Grid container rowSpacing={2} columnSpacing={2}>
				{products.map((p) => (
					<Grid key={p.id} item xs={6} md={3}>
						<ProductPreview {...p} />
					</Grid>
				))}
			</Grid>

			<NavLink to={RouteDefinitions.TEAM_APPAREL()}>
				<Button color={'white'}>Start Now!</Button>
			</NavLink>
		</FeaturedDealsSectionWrapper>
	);
}

export default FeaturedDealsSection;
