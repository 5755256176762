import { useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';

import { BundleProductFragment } from '../generated/graphql';

export type Bundle = {
	name: string;
	products: BundleProductFragment[];
	total: number;
};

const useBundleStorage = () => {
	// const bundleCache = window.localStorage.getItem('bundle');
	const defaultBundle: Bundle = {
		name: 'My Bundle',
		products: [],
		total: 0
	};
	// const [bundle, setBundle] = useState<Bundle>(bundleCache ? JSON.parse(bundleCache) : defaultBundle);
	const [bundle, setBundleValue] = useLocalStorage('bundle', defaultBundle);

	useEffect(() => {
		window.localStorage.setItem('bundle', JSON.stringify(bundle));
	}, [bundle]);

	const addBundleProduct = (product: BundleProductFragment) => {
		const products = [...bundle.products];
		products.push(product);
		const newBundle: Bundle = { ...bundle, products: products };
		recalcTotal(newBundle);
		setBundleValue(newBundle);
		return newBundle;
	};

	const removeBundleProduct = (index: number) => {
		const products = [...bundle.products];
		products.splice(index, 1);
		const newBundle: Bundle = { ...bundle, products: products };
		recalcTotal(newBundle);
		setBundleValue(newBundle);
		return newBundle;
	};

	const updateBundleName = (name: string) => {
		const newBundle = { ...bundle, name: name };
		setBundleValue(newBundle);
		return newBundle;
	};

	const resetBundleStorage = () => {
		setBundleValue(defaultBundle);
		return defaultBundle;
	};

	const recalcTotal = (bundleToCalc: Bundle) => {
		let total = 0;
		bundleToCalc.products.forEach((p) => {
			total += p.base_price!;
		});
		bundleToCalc.total = total * 0.8;
	};

	return {
		bundle,
		addBundleProduct,
		removeBundleProduct,
		updateBundleName,
		resetBundleStorage
	};
};

export default useBundleStorage;
