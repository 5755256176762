import React from 'react';

import { formatCurrencyValueFromServer } from '../../core/utilities/currency';
import { Package, Product } from '../../stores/cart/action';

interface Props {
	detailClassName?: string;
	orderItem: Package | Product;
}

const TeamCartItemHeader = ({ orderItem }: Props) => {
	const quantity = orderItem.type === 'product' ? parseInt(`${orderItem.quantity}`, 10) : 1;

	const { label, price } = orderItem;
	return (
		<div style={{ display: 'flex', justifyContent: 'space-between' }}>
			<h5 className={'no-margin'}>{label}</h5>
			<p>{formatCurrencyValueFromServer((quantity > 0 ? quantity : 1) * price)}</p>
		</div>
	);
};

export default TeamCartItemHeader;
