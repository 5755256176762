import { useMemo } from 'react';

import { TeamStoreOrderFragment } from '../generated/graphql';
import { OptionValue, Package, Product } from '../stores/cart/action';

const useFormatServerOrders = (serverItems?: TeamStoreOrderFragment) => {
	const productsAndPackages: (Product | Package)[] | null = useMemo(() => {
		if (!serverItems) {
			return null;
		}
		const formattedPackages = serverItems.order_items
			.filter((o) => o.order_package_id)
			.reduce<{ [key: string]: Package }>((prev, cur) => {
				if (!cur.order_package_id) {
					return prev;
				}
				const {
					id,
					order_package,
					order_package_id,
					order_item_options,
					image_url,
					store_product,
					total_price,
					custom_name,
					custom_number
				} = cur;

				const orderOptionDefaults: { [key: string]: OptionValue } = {};

				const pkg: Package = {
					id: order_package_id,
					image: order_package?.image_url || '',
					label: order_package?.store_package.label || '',
					price: order_package?.total_price || 0,
					order_for: cur.order_for || '',
					type: 'package',
					products: [
						{
							id: id,
							image: image_url || '',
							label: store_product?.label || '',
							price: total_price,
							custom_name: custom_name || undefined,
							custom_number: custom_number || undefined,
							type: 'product',
							quantity: 1,
							options: order_item_options.reduce((previous, opts) => {
								const optionValue: OptionValue = {
									availableOptionId: opts.available_option_id,
									availableOptionValueId: opts.available_option_value_id,
									label:
										opts.available_option.display_name && opts.available_option.display_name !== 'null'
											? opts.available_option.display_name
											: opts.available_option.name,
									priceModifiedAs: opts.price_modifier || 0,
									value: opts.value,
									imageUrl: opts.image_url || undefined
								};
								return {
									...previous,
									[opts.id]: optionValue
								};
							}, orderOptionDefaults)
						}
					]
				};

				if (prev[cur.order_package_id]) {
					return {
						...prev,
						[cur.order_package_id]: {
							...prev[cur.order_package_id],
							products: [...prev[cur.order_package_id].products, ...pkg.products]
						}
					};
				}

				return {
					...prev,
					[cur.order_package_id]: pkg
				};
			}, {});
		const products: Product[] = serverItems.order_items
			.filter((o) => !o.order_package_id)
			.map((order) => {
				return {
					id: order.id,
					_sortProductId: order.store_product?.id || 0,
					image: order.image_url || '',
					label: order.store_product?.label || '',
					order_for: order.order_for || '',
					price: order.total_price,
					type: 'product',
					custom_name: order.custom_name || undefined,
					custom_number: order.custom_number || undefined,
					quantity: order.quantity,
					options: order.order_item_options.reduce((previous, opts) => {
						const optionValue: OptionValue = {
							availableOptionId: opts.available_option_id,
							availableOptionValueId: opts.available_option_value_id,
							label:
								opts.available_option.display_name && opts.available_option.display_name !== 'null'
									? opts.available_option.display_name
									: opts.available_option.name,
							priceModifiedAs: opts.price_modifier || 0,
							value: opts.value,
							imageUrl: opts.image_url || undefined
						};
						return {
							...previous,
							[opts.id]: optionValue
						};
					}, {})
				};
			});

		return [...products, ...Object.values(formattedPackages)];
	}, [serverItems]);

	return productsAndPackages;
};

export default useFormatServerOrders;
