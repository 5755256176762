import styled from '@emotion/styled';
import EmailIcon from '@mui/icons-material/Email';
import { Breadcrumbs, Button, Grid } from '@mui/material';
import { GoogleLogin } from '@react-oauth/google';
import React from 'react';
import AppleSignin from 'react-apple-signin-auth';
import { ChevronRight } from 'react-feather';
import { useForm } from 'react-hook-form';
import { NavLink, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useAuth } from '../providers/authProvider';

const BreadcrumbsWrapper = styled('div')`
	display: flex;
	flex-direction: column;
	width: 95%;
	max-width: 1240px;
	margin: auto;
`;

const Separator = styled('div')`
	width: 100%;
	border-bottom: 1px solid var(--separator);
	margin: 0 0 24px;
`;

const BodyWrapper = styled('div')`
	display: block;
	width: 95%;
	max-width: 1240px;
	margin: 24px auto;
	background-color: var(--grey);
	border-radius: 15px;
	padding: 45px;
	@media (min-width: 568px) {
		max-width: 538px;
	}
`;

const OrDivider = styled('div')`
	display: flex;
	align-content: center;
	align-items: center;
	margin: 0.8rem 0;
	text-align: center;
	text-transform: uppercase;
	h5,
	h6 {
		margin: 0.5rem;
	}
	&::before,
	&::after {
		border-top: 1px solid #dfdfdf;
		content: '';
		display: block;
		flex: 1;
	}
`;

const EmailTextField = styled('input')`
	background-color: white;
	font-size: 16px;
	padding: 10px 12px;
	width: 100%;
	border-radius: 4px;
	border: 1px solid var(--separator);
`;

const EmailButton = styled(Button)`
	margin-top: 16px;
	width: 100%;
	background-color: #f9f9f9;
	text-transform: initial;
	font-size: 16px;
`;
function Login() {
	const { token } = useParams();
	const [search] = useSearchParams();
	const navigate = useNavigate();
	const { register, handleSubmit } = useForm();
	const { user, loginWithEmail, loginWithProvider } = useAuth();
	const next = search.get('next');

	const handleEmailSubmit = handleSubmit((formData: any) => {
		loginWithEmail(formData.email, navigate);
	});

	if (token) {
		loginWithProvider('email', { token }, navigate).then(() => {
			navigate(next || '/');
		});
	}

	if (user) {
		navigate('/My/Account');
	}

	return (
		<>
			<BreadcrumbsWrapper>
				<Separator />
				<Breadcrumbs aria-label="breadcrumb" separator={<ChevronRight width={18} height={18} />}>
					<NavLink to={'/'}>
						<p className={'no-margin'}>Home</p>
					</NavLink>
					<NavLink to={'/Login'}>
						<p className={'no-margin'}>
							<strong>Login</strong>
						</p>
					</NavLink>
				</Breadcrumbs>
			</BreadcrumbsWrapper>
			<BodyWrapper>
				<Grid container columnSpacing={2} rowSpacing={2}>
					<Grid item xs={12}>
						<h5 className={'centered'}>Sign in / Create account</h5>
					</Grid>
					<Grid item xs={12}>
						<form onSubmit={handleEmailSubmit}>
							<EmailTextField {...register('email')} placeholder={'Email Address'} />
							<EmailButton
								startIcon={<EmailIcon />}
								variant={'contained'}
								color={'secondary'}
								onClick={handleEmailSubmit}
							>
								Email me a sign-in link
							</EmailButton>
						</form>
					</Grid>
					<Grid item xs={12}>
						<OrDivider>
							<h6>OR</h6>
						</OrDivider>
					</Grid>
					<Grid item sm={6} xs={12} display={'flex'} justifyContent={'center'}>
						<div className={'signin-button'}>
							<GoogleLogin
								onSuccess={(credentialResponse) => loginWithProvider('google', credentialResponse, navigate)}
								onError={() => {}}
								width={'205px'}
							/>
						</div>
					</Grid>
					<Grid item sm={6} xs={12} display={'flex'} justifyContent={'center'}>
						<AppleSignin
							authOptions={{
								clientId: 'com.example.web',
								redirectURI: 'https://example.com',
								scope: 'email name',
								state: 'state',
								nonce: 'nonce',
								usePopup: true
							}}
							buttonExtraChildren={'Sign in with Apple'}
							className={'signin-button'}
							uiType="light"
							onError={(err: any) => console.error(err)}
							onSuccess={(credentialResponse: any) => loginWithProvider('apple', credentialResponse, navigate)}
						/>
					</Grid>
					<Grid item xs={1}></Grid>
					<Grid item xs={10} textAlign={'center'}>
						<small>
							By continuing, you agree to our account Terms and Conditions, Cookie Usage, and Privacy Policy.
						</small>
					</Grid>
					<Grid item xs={1}></Grid>
				</Grid>
			</BodyWrapper>
		</>
	);
}

export default Login;
