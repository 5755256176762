import styled from '@emotion/styled';
import React, { ReactNode } from 'react';

import Footer from '../components/Footer';
import Header from '../components/Header';

type Props = {
	children: ReactNode;
};

const Page = ({ children }: Props) => {
	const StyledPage = styled('div')`
		min-height: 100vh;
		display: flex;
		flex-direction: column;
	`;
	const MainBlock = styled('section')`
		flex: 1;
	`;
	return (
		<StyledPage>
			<Header />
			<MainBlock className={'main-block'}>{children}</MainBlock>
			<Footer />
		</StyledPage>
	);
};

export default Page;
