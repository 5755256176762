import styled from '@emotion/styled';
import React, { Fragment, ReactElement } from 'react';

import PriceComponent from '../../components/PriceComponent';
import useProductPrice from '../../core/hooks/useProductPrice';
import { useConfigStore } from '../../stores/productConfiguration/useConfigureProduct';
import { SimplifiedProduct } from './ParseServerProductUtilities';

interface Props {
	product: SimplifiedProduct;
}

const ProductPriceWrapper = ({ product }: Props): ReactElement => {
	const { currentConfig } = useConfigStore();
	const price = useProductPrice({
		basePrice: product.basePrice!,
		customPriceOverride: product.customPriceOverride || undefined,
		customNamePrice: product.customNamePrice || undefined,
		customNumberPrice: product.customNumberPrice || undefined,
		discountRate: product.discountRate,
		discountType: product.discountType,
		calculateCustomizations: true
	});

	return (
		<PriceComponent
			price={price}
			basePrice={product.basePrice!}
			customPriceOverride={product.customPriceOverride}
			discountRate={product.discountRate}
			discountType={product.discountType}
		/>
	);
};

export default ProductPriceWrapper;
