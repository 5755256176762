import styled from '@emotion/styled';
import { Breadcrumbs, Grid } from '@mui/material';
import React from 'react';
import { ChevronRight } from 'react-feather';
import { NavLink } from 'react-router-dom';

import ProductPreview, { Product } from '../components/ProductPreview';
import Page from './Page';

function InStock() {
	const products: Product[] = [
		{
			id: '123',
			image: '/images/products/RedWhiteJersey.png',
			name: 'Red White Jersey',
			stars: 4.0,
			basePrice: 65
		},
		{
			id: '456',
			image: '/images/products/RedBucketHat.png',
			name: 'Red Red Bucket Hat',
			stars: 3.5,
			basePrice: 40
		},
		{
			id: '789',
			image: '/images/products/RedFlagJersey.png',
			name: 'Red Flag Dark Jersey',
			stars: 4.5,
			basePrice: 60
		},
		{
			id: '999',
			image: '/images/products/RedPanelHat.png',
			name: 'Red 5 Panel Black Design Hat',
			stars: 5.0,
			basePrice: 30
		},
		{
			id: '123',
			image: '/images/products/allstarbundle.png',
			name: 'All Star Bundle',
			stars: 4.5,
			basePrice: 75
		},
		{
			id: '456',
			image: '/images/products/epicbundle.png',
			name: 'Epic Bundle',
			stars: 3.5,
			basePrice: 95
		},
		{
			id: '789',
			image: '/images/products/barginbintshirt.png',
			name: 'Bargin Bin Short Sleeve*',
			stars: 4.5,
			basePrice: 20
		},
		{
			id: '999',
			image: '/images/products/indyredjersey.png',
			name: 'Indy Red Red Jersey',
			stars: 4.5,
			basePrice: 60
		}
	];

	const BreadcrumbsWrapper = styled('div')`
		display: flex;
		flex-direction: column;
		width: 95%;
		max-width: 1240px;
		margin: auto;
	`;

	const Separator = styled('div')`
		width: 100%;
		border-bottom: 1px solid var(--separator);
		margin: 0 0 24px;
	`;

	const BodyWrapper = styled('div')`
		display: block;
		width: 95%;
		max-width: 1240px;
		margin: 24px auto;
	`;

	return (
		<>
			<BreadcrumbsWrapper>
				<Separator />
				<Breadcrumbs aria-label="breadcrumb" separator={<ChevronRight width={18} height={18} />}>
					<NavLink to={'/'}>
						<p className={'no-margin'}>Home</p>
					</NavLink>
					<NavLink to={'/Custom'}>
						<p className={'no-margin'}>
							<strong>In Stock</strong>
						</p>
					</NavLink>
				</Breadcrumbs>
			</BreadcrumbsWrapper>
			<BodyWrapper>
				<Grid container rowSpacing={2} columnSpacing={2}>
					{products.map((p) => (
						<Grid key={p.id} item xs={6} md={3}>
							<ProductPreview {...p} />
						</Grid>
					))}
				</Grid>
			</BodyWrapper>
		</>
	);
}

export default InStock;
