import { ReactElement, useEffect } from 'react';
import React from 'react';
import { Outlet } from 'react-router-dom';

import BreadcrumbConnectedWrapper from '../../core/components/BreadcrumbConnectedWrapper';
import { useBreadcrumbs } from '../../providers/breadcrumbProvider';
import { RouteDefinitions } from '../../routeDefinitions';

const CartContainer = (): ReactElement => {
	const { addBreadcrumb, removeBreadcrumb } = useBreadcrumbs();

	useEffect(() => {
		const breadcrumb = { name: 'Cart', path: RouteDefinitions.CART() };
		addBreadcrumb(breadcrumb);

		return () => {
			removeBreadcrumb(breadcrumb.path);
		};
	}, []);

	return (
		<>
			<BreadcrumbConnectedWrapper />
			<Outlet />
		</>
	);
};

export default CartContainer;
