import React, { Suspense } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import DNF404 from './404';
import MyAccountLoader from './loaders/myAccountLoader';
import About from './pages/About';
import AppContainer from './pages/AppContainer';
import Account from './pages/Authenticated/Account';
import My from './pages/Authenticated/My';
import BundleBuilderV2 from './pages/BundleBuilderCustom/BundleBuilderV2';
import BundleDetail from './pages/BundleDetail/BundleDetail';
import BundleDetailLoader from './pages/BundleDetail/BundleDetailDataLoader';
import Callback from './pages/Callback';
import CartContainer from './pages/Cart/CartContainer';
import CartView from './pages/Cart/CartView';
import TeamCart from './pages/Cart/TeamCart';
import CustomerSupport from './pages/CustomerSupport';
import Custom from './pages/CustomGear/Custom';
import DesignInspiration from './pages/Design/Inspiration';
import DesignSizing from './pages/Design/Sizing';
import DesignTemplates from './pages/Design/Templates';
import FAQ from './pages/Faq/FAQ';
import Group from './pages/Group';
import Home from './pages/Home';
import InStock from './pages/InStock';
import Login from './pages/Login';
import PartnersSearch from './pages/Partners/PartnersSearch';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ProductDetailLoader from './pages/ProductDetail/ProductDetailDataLoader';
import ProductDetailWrapper from './pages/ProductDetail/ProductDetailWrapper';
import Search from './pages/Search';
import Styleguide from './pages/Styleguide';
import Team from './pages/Team';
import TeamApparelContainer from './pages/TeamApparel/TeamApparelContainer';
import TeamApparelItem from './pages/TeamApparel/TeamApparelItem';
import TeamApparelStore from './pages/TeamApparel/TeamApparelStore';
import TeamStoreContainer from './pages/TeamStore/TeamStoreContainer';
import TeamStoreDataLoader from './pages/TeamStore/TeamStoreDataLoader';
import TeamStorePage from './pages/TeamStore/TeamStorePage';
import TermsAndConditions from './pages/TermsAndConditions';
import { RouteDefinitions } from './routeDefinitions';

const router = createBrowserRouter([
	{
		path: RouteDefinitions.HOME(),
		element: <AppContainer />,
		children: [
			{
				path: RouteDefinitions.HOME(),
				element: <Home />,
				errorElement: <DNF404 />
			},
			{
				path: RouteDefinitions.ABOUT(),
				element: <About />
			},

			{
				path: RouteDefinitions.CUSTOM(),
				element: <Custom />
			},
			{
				path: RouteDefinitions.GROUP(),
				element: <Group />,
				children: [
					{
						path: RouteDefinitions.TEAM(),
						element: <Team />
					}
				]
			},
			{
				path: RouteDefinitions.IN_STOCK(),
				element: <InStock />
			},
			{
				path: RouteDefinitions.LOGIN(),
				element: <Login />
			},
			{
				path: RouteDefinitions.PARTNERS(),
				element: <PartnersSearch />
			},
			{
				path: RouteDefinitions.PRODUCT_DETAIL(),
				element: <ProductDetailWrapper />
			},
			{
				path: RouteDefinitions.SEARCH(),
				element: <Search />,
				children: [
					{
						path: RouteDefinitions.SEARCH_WITH_PARAM(),
						element: <Search />
					}
				]
			},
			{
				path: RouteDefinitions.CART(),
				element: <CartContainer />,
				children: [
					{
						path: '',
						element: <CartView />
					},
					{
						path: RouteDefinitions.TEAM_STORE_CART(),
						loader: TeamStoreDataLoader,
						element: <TeamCart />
					}
				]
			},
			{
				path: RouteDefinitions.TEAM_STORE(),
				element: <TeamStoreContainer />,
				loader: TeamStoreDataLoader,
				children: [
					{ path: '', element: <TeamStorePage /> },
					{
						path: RouteDefinitions.TEAM_STORE_PRODUCT(),
						element: (
							<Suspense fallback={<div>Loading...</div>}>
								<ProductDetailWrapper />
							</Suspense>
						),
						loader: ProductDetailLoader
					},
					{
						path: RouteDefinitions.TEAM_STORE_BUNDLE(),
						element: (
							<Suspense fallback={<div>Loading...</div>}>
								<BundleDetail />
							</Suspense>
						),
						loader: BundleDetailLoader
					}
				]
			},
			{
				path: RouteDefinitions.TEAM_APPAREL(),
				element: <TeamApparelContainer />,
				children: [
					{
						path: '',
						element: <TeamApparelStore />
					},
					{
						path: RouteDefinitions.TEAM_APPAREL_ITEM(),
						element: <TeamApparelItem />
					},

					{
						path: 'custom-team-apparel/:category?/:subcategory?',
						element: <TeamApparelStore />
					}
				]
			},
			{
				path: RouteDefinitions.MY(),
				element: <My />,
				children: [
					{
						path: RouteDefinitions.MY_ACCOUNT(),
						element: (
							<Suspense fallback={<div>Loading...</div>}>
								<Account />
							</Suspense>
						),
						loader: MyAccountLoader
					}
				]
			},
			{
				path: RouteDefinitions.BUNDLE_BUILDER(),
				element: <BundleBuilderV2 />
			},
			{
				path: RouteDefinitions.STYLE_GUIDE(),
				element: <Styleguide />
			},
			{
				path: RouteDefinitions.NOT_FOUND(),
				element: <DNF404 />
			},
			{
				path: RouteDefinitions.CHECKOUT_CALLBACK(),
				element: <Callback />
			},
			{
				path: RouteDefinitions.DESIGN_INSPIRATION(),
				element: <DesignInspiration />
			},
			{
				path: RouteDefinitions.DESIGN_TEMPLATES(),
				element: <DesignTemplates />
			},
			{
				path: RouteDefinitions.DESIGN_SIZING(),
				element: <DesignSizing />
			},
			{
				path: RouteDefinitions.TERMS_AND_CONDITIONS(),
				element: <TermsAndConditions />
			},
			{
				path: RouteDefinitions.PRIVACY_POLICY(),
				element: <PrivacyPolicy />
			},
			{
				path: RouteDefinitions.CUSTOMER_SUPPORT(),
				element: <CustomerSupport />
			},
			{
				path: 'custom-team-apparel/custom-team-bundles',
				element: <Navigate to="/TeamApparel/custom-team-apparel/bundle" replace />
			},
			{
				path: RouteDefinitions.FAQ(),
				element: <FAQ />
			}
		]
	}
]);

export default router;
