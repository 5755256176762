import styled from '@emotion/styled';
import React from 'react';
import { Search } from 'react-feather';
import { useNavigate } from 'react-router-dom';

function SearchBox() {
	const navigate = useNavigate();

	const openLink = (url: string) => {
		navigate(url);
	};

	const InnerSearch = styled('div')`
		flex: 1;
		display: flex;
		align-items: center;
		gap: 12px;
		padding: 12px 16px;
	`;

	const SearchInput = styled('input')`
		flex: 1;
		background: transparent;
		border: none;
		outline: none;
		font-family: Satoshi-Variable, sans-serif;
		font-size: 16px;
	`;

	return (
		<InnerSearch className="grey-box rounded" style={{ flex: 1 }}>
			<Search onClick={() => openLink('/Search')} />
			<SearchInput type={'search'} placeholder={'Search for products...'} />
		</InnerSearch>
	);
}

export default SearchBox;
