import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { OptionValue, Product } from '../cart/action';

interface ConfigState {
	currentConfig: Partial<Product> | null;
	initConfig: (args: { baseProduct: Partial<Product> }) => void;
	updateOption: (args: { optionId: string; value: OptionValue }) => void;
	updateCustomField: (args: { field: 'custom_name' | 'custom_number'; value: string }) => void;
	updateQuantity: (args: { quantity: number }) => void;
	clearConfig: () => void;
	getConfiguredProduct: () => Product | null;
}

export const useConfigStore = create<ConfigState>()(
	devtools(
		(set, get) => ({
			currentConfig: null,

			initConfig: ({ baseProduct }) => {
				const baseConfig = { ...baseProduct, quantity: 1 };
				// Add options if it's a Product
				if ('type' in baseProduct && baseProduct.type === 'product') {
					(baseConfig as Product).options = {};
				}
				set({ currentConfig: baseConfig }, false, 'initConfig');
			},

			updateOption: ({ optionId, value }) =>
				set(
					(state) => {
						return {
							currentConfig: {
								...state.currentConfig,
								options: {
									...(state.currentConfig as Product).options,
									[optionId]: value
								}
							}
						};
					},
					false,
					`updateOption-${optionId}`
				),

			updateCustomField: ({ field, value }) =>
				set(
					(state) => ({
						currentConfig: state.currentConfig ? { ...state.currentConfig, [field]: value } : null
					}),
					false,
					`updateCustomField-${field}`
				),

			updateQuantity: ({ quantity }) =>
				set(
					(state) => ({
						currentConfig: state.currentConfig ? { ...state.currentConfig, quantity } : null
					}),
					false,
					`updateQuantity-${quantity}`
				),

			clearConfig: () => set({ currentConfig: null }, false, 'clearConfig'),

			getConfiguredProduct: () => {
				const config = get().currentConfig;
				if (!config || !config.id || !config.price) return null;
				return config as Product;
			}
		}),
		{
			name: 'Product Configuration Store' // This name will appear in the Redux DevTools
		}
	)
);
