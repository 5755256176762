import styled from '@emotion/styled';

const ProductSection = styled('section')`
	display: flex;
	max-width: 1240px;
	margin: auto;
	padding: 24px;
	flex: 1;
	justify-content: center;
	align-items: center;
`;

export default ProductSection;
