import { Grid } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useLoaderData } from 'react-router-dom';

import ProductSection from '../../components/ProductSection';
import { Product } from '../../stores/cart/action';
import { BundleDataLoaderReturn } from './BundleDetailDataLoader';
import BundleDetailHeader from './BundleDetailHeader';
import BundleProductDetailWrapper from './BundleProductDetailWrapper';
import ProductSteps from './BundleProductSteps';
import ReviewBundle from './ReviewBundle';

interface PackageProductState {
	[key: string]: Product;
}
export interface PriceModifier {
	[key: string]: number;
}

function BundleDetail() {
	const data = useLoaderData() as BundleDataLoaderReturn;
	const [activeStep, setActiveStep] = useState(0);

	const totalPrice = data.bundleInfo.store_package[0].custom_price;

	const totalSteps = useMemo(() => data.bundleProducts.length, [data]);
	const isReviewStep = activeStep === totalSteps;

	const handleNext = (): void => setActiveStep((prevStep) => prevStep + 1);
	const handleStepClick = (index: number): void => {
		if (index <= activeStep) {
			setActiveStep(index);
		}
	};

	return (
		<ProductSection>
			<Grid container>
				<Grid item flex={1} overflow={'hidden'} border={'1px solid var(--separator)'} borderRadius={'20px'}>
					<BundleDetailHeader bundleName={data.bundleInfo.store_package[0].label || ''} totalPrice={totalPrice}>
						<ProductSteps
							products={data.bundleProducts}
							activeStep={activeStep}
							isReviewStep={isReviewStep}
							totalSteps={totalSteps}
							onStepClick={handleStepClick}
						/>
					</BundleDetailHeader>

					{isReviewStep ? (
						<ReviewBundle bundleInfo={data.bundleInfo} />
					) : (
						data.bundleProducts.map((product, index) => (
							<Grid
								key={product.id}
								container
								rowSpacing={2}
								style={{
									display: activeStep === index ? 'flex' : 'none',
									transition: 'opacity 0.3s ease',
									opacity: activeStep === index ? 1 : 0
								}}
							>
								<BundleProductDetailWrapper
									isActive={activeStep === index}
									handleNext={handleNext}
									product={product}
								/>
							</Grid>
						))
					)}
				</Grid>
			</Grid>
		</ProductSection>
	);
}

export default BundleDetail;
