import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';

import { getImageUrl } from '../utils/imageHelper';

const CarouselContainer = styled.div`
	width: 100%;
	height: 500px;
	position: relative;
	overflow: hidden;
	border-radius: 20px;
`;

const CarouselImage = styled.img<{ active: boolean }>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	opacity: ${(props) => (props.active ? 1 : 0)};
	transition: opacity 1s ease-in-out;
`;

const images = [
	getImageUrl('carousel_2.jpg'),
	getImageUrl('carousel_3.jpg'),
	getImageUrl('carousel_4.jpg'),
	getImageUrl('carousel_5.jpg'),
	getImageUrl('carousel_6.jpg'),
	getImageUrl('large/layout.jpg')
];

const RandomImageCarousel: React.FC = () => {
	const [currentImageIndex, setCurrentImageIndex] = useState(0);

	useEffect(() => {
		const shuffledImages = [...images].sort(() => Math.random() - 0.5);
		let index = 0;

		const intervalId = setInterval(() => {
			setCurrentImageIndex(index);
			index = (index + 1) % shuffledImages.length;
		}, 5000); // Change image every 5 seconds

		return () => clearInterval(intervalId);
	}, []);

	return (
		<CarouselContainer>
			{images.map((src, index) => (
				<CarouselImage key={src} src={src} alt={`Carousel image ${index + 1}`} active={index === currentImageIndex} />
			))}
		</CarouselContainer>
	);
};

export default RandomImageCarousel;
