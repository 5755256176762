import styled from '@emotion/styled';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Breadcrumbs,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Grid
} from '@mui/material';
import React from 'react';
import { ChevronDown, ChevronRight } from 'react-feather';
import { NavLink } from 'react-router-dom';

import ProductPreview, { Product } from '../components/ProductPreview';
import SearchBox from '../components/SearchBox';

function Search() {
	const products: Product[] = [
		{
			id: '123',
			image: '/images/products/RedWhiteJersey.png',
			name: 'Red White Jersey',
			stars: 4.0,
			basePrice: 65
		},
		{
			id: '456',
			image: '/images/products/RedBucketHat.png',
			name: 'Red Red Bucket Hat',
			stars: 3.5,
			basePrice: 40
		},
		{
			id: '789',
			image: '/images/products/RedFlagJersey.png',
			name: 'Red Flag Dark Jersey',
			stars: 4.5,
			basePrice: 60
		},
		{
			id: '999',
			image: '/images/products/RedPanelHat.png',
			name: 'Red 5 Panel Black Design Hat',
			stars: 5.0,
			basePrice: 30
		},
		{
			id: '123',
			image: '/images/products/allstarbundle.png',
			name: 'All Star Bundle',
			stars: 4.5,
			basePrice: 75
		},
		{
			id: '456',
			image: '/images/products/epicbundle.png',
			name: 'Epic Bundle',
			stars: 3.5,
			basePrice: 95
		},
		{
			id: '789',
			image: '/images/products/barginbintshirt.png',
			name: 'Bargin Bin Short Sleeve*',
			stars: 4.5,
			basePrice: 20
		},
		{
			id: '999',
			image: '/images/products/indyredjersey.png',
			name: 'Indy Red Red Jersey',
			stars: 4.5,
			basePrice: 60
		}
	];

	const BreadcrumbsWrapper = styled('div')`
		display: flex;
		flex-direction: column;
		width: 95%;
		max-width: 1240px;
		margin: auto;
	`;

	const Separator = styled('div')`
		width: 100%;
		border-bottom: 1px solid var(--separator);
		margin: 0 0 24px;
	`;

	const BodyWrapper = styled('div')`
		display: block;
		width: 95%;
		max-width: 1240px;
		margin: 24px auto;
	`;

	return (
		<>
			<BreadcrumbsWrapper>
				<Separator />
				<Breadcrumbs aria-label="breadcrumb" separator={<ChevronRight width={18} height={18} />}>
					<NavLink to={'/'}>
						<p className={'no-margin'}>Home</p>
					</NavLink>
					<NavLink to={'/Search'}>
						<p className={'no-margin'}>
							<strong>Search</strong>
						</p>
					</NavLink>
				</Breadcrumbs>
			</BreadcrumbsWrapper>
			<BodyWrapper>
				<Grid container columnSpacing={2} rowSpacing={2}>
					<Grid item xs={12}>
						<SearchBox />
					</Grid>

					<Grid item xs={3}>
						<Accordion elevation={0} defaultExpanded={true}>
							<AccordionSummary expandIcon={<ChevronDown />}>
								<p className={'no-margin'}>Groups</p>
							</AccordionSummary>
							<AccordionDetails>
								<FormGroup>
									<FormControlLabel
										control={<Checkbox size={'small'} checked={true} />}
										label={<small>Premier Ultimate League</small>}
									/>
									<FormControlLabel
										control={<Checkbox size={'small'} />}
										label={<small>Western Ultimate League</small>}
									/>
									<FormControlLabel control={<Checkbox size={'small'} />} label={<small>BUDA</small>} />
									<FormControlLabel
										control={<Checkbox size={'small'} />}
										label={<small>Minnesota Ultimate</small>}
									/>
								</FormGroup>
							</AccordionDetails>
						</Accordion>

						<Accordion elevation={0} defaultExpanded={true}>
							<AccordionSummary expandIcon={<ChevronDown />}>
								<p className={'no-margin'}>Teams</p>
							</AccordionSummary>
							<AccordionDetails>
								<FormGroup>
									<FormControlLabel
										control={<Checkbox size={'small'} />}
										label={<small>Columbus Pride</small>}
									/>
									<FormControlLabel
										control={<Checkbox size={'small'} />}
										label={<small>Indianapolis Red</small>}
									/>
									<FormControlLabel
										control={<Checkbox size={'small'} />}
										label={<small>Nashville Nightshade</small>}
									/>
								</FormGroup>
							</AccordionDetails>
						</Accordion>

						<Accordion elevation={0} defaultExpanded={true}>
							<AccordionSummary expandIcon={<ChevronDown />}>
								<p className={'no-margin'}>Products</p>
							</AccordionSummary>
							<AccordionDetails>
								<FormGroup>
									<FormControlLabel control={<Checkbox size={'small'} />} label={<small>Short Sleeve</small>} />
									<FormControlLabel control={<Checkbox size={'small'} />} label={<small>Tank</small>} />
									<FormControlLabel control={<Checkbox size={'small'} />} label={<small>Long Sleeve</small>} />
									<FormControlLabel control={<Checkbox size={'small'} />} label={<small>Hoodies</small>} />
									<FormControlLabel control={<Checkbox size={'small'} />} label={<small>Hat</small>} />
								</FormGroup>
							</AccordionDetails>
						</Accordion>
					</Grid>

					<Grid item xs={9}>
						<Grid container rowSpacing={2} columnSpacing={2}>
							{products.map((p) => (
								<Grid key={p.id} item xs={6} md={3}>
									<ProductPreview {...p} />
								</Grid>
							))}
						</Grid>
					</Grid>
				</Grid>
			</BodyWrapper>
		</>
	);
}

export default Search;
