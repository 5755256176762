import './scss/app.reset.scss';
import './scss/app.typography.scss';
import './scss/app.main.scss';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { RouterProvider } from 'react-router-dom';

import messages from './intl/en-US.json';
import { AuthProvider } from './providers/authProvider';
import { BreadcrumbProvider } from './providers/breadcrumbProvider';
import router from './router';

function App() {
	const authToken = localStorage.getItem('authToken');

	return (
		<AuthProvider authToken={authToken}>
			<IntlProvider locale={'en-US'} messages={messages}>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<BreadcrumbProvider>
						<RouterProvider router={router} />
					</BreadcrumbProvider>
				</LocalizationProvider>
			</IntlProvider>
		</AuthProvider>
	);
}

export default App;
