import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

export const MenuLink = styled(NavLink)`
	font-family: Satoshi-Variable, sans-serif;
	font-size: 16px;
	line-height: 22px;
	font-weight: 400;
	margin-right: 24px;
`;
export default MenuLink;
