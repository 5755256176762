import { LoaderFunction } from 'react-router-dom';

import { useFriendlyKeyHelper } from '../../core/hooks/useFriendlyKeyHelper';
import { GetProductByIdDocument, GetProductByIdQuery } from '../../generated/graphql';
import { createApolloClient } from '../../utils/apolloClient';
import { PreprocessProduct } from './ParseServerProductUtilities';

const apolloClient = createApolloClient();

interface LoaderParams {
	productId?: string;
}

const ProductDetailLoader: LoaderFunction = async ({ params }: { params: LoaderParams }) => {
	const { productId } = params;

	if (!productId) {
		throw new Error('Product ID is missing');
	}

	const variables = useFriendlyKeyHelper(productId);

	try {
		const { data } = await apolloClient.query<GetProductByIdQuery>({
			query: GetProductByIdDocument,
			variables
		});

		if (!data) {
			throw new Error('Failed to fetch product data');
		}

		const simplifiedProduct = PreprocessProduct(data.store_product[0]);

		return simplifiedProduct;
	} catch (error) {
		throw new Error(`Loader error:`);
	}
};

export default ProductDetailLoader;
