import styled from '@emotion/styled';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';

import { Feature } from './types';

interface FeatureCardProps extends Feature {
	onClick: () => void;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ title, subtitle, imageUrl, onClick, bgColor }) => (
	<CardItem onClick={onClick}>
		<CardContainer>
			<CardModifier>
				<CardImage src={imageUrl} alt={title} />

				<CardGradient bgColor={bgColor || '#4AA98C'}>
					<CardTitle>{title}</CardTitle>
				</CardGradient>
			</CardModifier>
		</CardContainer>
	</CardItem>
);

const CardItem = styled.div`
	flex: 0 0 auto;
	scroll-snap-align: start;
	position: relative;
	cursor: pointer;
	height: 100%;

	transition: transform 0.3s cubic-bezier(0, 0, 0.5, 1);

	&:hover {
		transform: scale(1.05);
	}
`;
const CardContainer = styled.div`
	position: relative;
	cursor: pointer;
	height: 100%;
`;

const CardModifier = styled.div`
	width: 405px;
	max-height: 804px;
	min-height: 740px;
	border-radius: 20px;
	position: relative;
	overflow: hidden;
`;

const CardGradient = styled.div<{ bgColor: string }>`
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 260px;
	background: linear-gradient(to top, ${(props) => props.bgColor} 0%, ${(props) => props.bgColor}99 50%, transparent 100%);
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding: 16px;
`;

const CardTitle = styled.h2`
	/* font-size: 24px;
	line-height: 1.16667;
	font-weight: 600; */
	margin-bottom: 4px;
	color: #ffffff;
`;

const CardSubtitle = styled.p`
	font-size: 17px;
	line-height: 1.47059;
	font-weight: 400;
	color: #ffffff;
`;

const CardImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

const ExpandButton = styled.button`
	align-self: flex-end;
	background: none;
	border: none;
	cursor: pointer;
	color: #fff;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export default FeatureCard;
