import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useLoaderData, useLocation } from 'react-router-dom';

import ProductSection from '../../components/ProductSection';
import { useBreadcrumbs } from '../../providers/breadcrumbProvider';
import { useConfigStore } from '../../stores/productConfiguration/useConfigureProduct';
import { SimplifiedProduct, TransformProductForConfig } from './ParseServerProductUtilities';
import ProductDetail from './ProductDetail';

function ProductDetailWrapper() {
	const product = useLoaderData() as SimplifiedProduct;

	const { addBreadcrumb, removeBreadcrumb } = useBreadcrumbs();
	const location = useLocation();

	const initConfig = useConfigStore((state) => state.initConfig);

	useEffect(() => {
		addBreadcrumb({
			name: product.label as string,
			path: location.pathname
		});

		// Transform the product and initialize the configuration
		const configProduct = TransformProductForConfig(product);
		initConfig({ baseProduct: configProduct });

		return () => {
			removeBreadcrumb(location.pathname);
			useConfigStore.getState().clearConfig();
		};
	}, [product, addBreadcrumb, removeBreadcrumb, location, initConfig]);

	return (
		<ProductSection>
			<Grid container rowSpacing={2} columnSpacing={3}>
				<ProductDetail product={product} />
			</Grid>
		</ProductSection>
	);
}

export default ProductDetailWrapper;
