import React from 'react';

type Props = {
	stat: string;
	name: string;
};

const PromoStat = ({ stat, name }: Props) => {
	return (
		<div>
			<h3 className={'no-margin'}>{stat}</h3>
			<cite className={'no-margin'}>{name}</cite>
		</div>
	);
};

export default PromoStat;
