import styled from '@emotion/styled';

const MenuWrapper = styled('div')`
	display: flex;
	gap: 24px;
	width: 95%;
	max-width: 1240px;
	margin: 24px auto;
	align-items: center;
	white-space: nowrap;
`;

export default MenuWrapper;
