import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';

import BodyWrapper from '../core/components/BodyWrapper';
import BreadcrumbConnectedWrapper from '../core/components/BreadcrumbConnectedWrapper';

const TermsAndConditions: React.FC = () => {
	return (
		<>
			<BreadcrumbConnectedWrapper />
			<BodyWrapper>
				<Grid container columnSpacing={2} rowSpacing={2}>
					<Grid item xs={12}>
						<h3>Terms and Conditions</h3>
					</Grid>

					<Grid item xs={12} md={8}>
						<Grid container direction="column">
							<Typography variant="body1" paragraph>
								Welcome to Breakmark. By using our website and services, you agree to comply with and be bound by
								the following terms and conditions. Please review them carefully. If you do not agree with these
								terms, you should not use our website or services.
							</Typography>

							<Typography variant="h5" gutterBottom>
								1. Use of the Website
							</Typography>
							<Typography variant="body1" paragraph>
								You agree to use our website for lawful purposes only and in a way that does not infringe the
								rights of, restrict, or inhibit anyone else's use and enjoyment of the website. You must not use
								our website in a way that could cause damage to the website or impair its availability or
								accessibility.
							</Typography>

							<Typography variant="h5" gutterBottom>
								2. Products and Orders
							</Typography>
							<Typography variant="body1" paragraph>
								All products displayed on our website are subject to availability. We reserve the right to
								discontinue any product at any time or modify its price without notice. Orders are subject to
								acceptance by Breakmark, and we may cancel any order for any reason.
							</Typography>

							<Typography variant="h5" gutterBottom>
								3. Payment and Billing
							</Typography>
							<Typography variant="body1" paragraph>
								All prices listed on the website are in USD unless otherwise stated. You are responsible for
								providing accurate payment and billing information. If we do not receive payment from your payment
								provider, we reserve the right to cancel your order.
							</Typography>

							<Typography variant="h5" gutterBottom>
								4. Shipping and Delivery
							</Typography>
							<Typography variant="body1" paragraph>
								We will make every effort to ship products in a timely manner, but shipping times may vary based
								on location and product availability. Breakmark is not responsible for any delays caused by the
								shipping carrier.
							</Typography>

							<Typography variant="h5" gutterBottom>
								5. Returns and Refunds
							</Typography>
							<Typography variant="body1" paragraph>
								You may return products in accordance with our Return Policy, which is available on our website.
								Refunds will be processed in the same method of payment used during the original purchase.
								Products must be in their original condition and packaging for a return to be accepted.
							</Typography>

							<Typography variant="h5" gutterBottom>
								6. Intellectual Property
							</Typography>
							<Typography variant="body1" paragraph>
								All content on the Breakmark website, including text, images, and designs, is the intellectual
								property of Breakmark or its licensors. You may not reproduce, distribute, or use any of this
								content without our written permission.
							</Typography>

							<Typography variant="h5" gutterBottom>
								7. Limitation of Liability
							</Typography>
							<Typography variant="body1" paragraph>
								Breakmark will not be liable for any indirect, incidental, or consequential damages arising from
								the use of our website or products. Our liability is limited to the purchase price of the products
								ordered.
							</Typography>

							<Typography variant="h5" gutterBottom>
								8. Changes to Terms
							</Typography>
							<Typography variant="body1" paragraph>
								We may update these terms and conditions at any time. Your continued use of our website after any
								changes are made constitutes your acceptance of the new terms. We recommend you review these terms
								regularly.
							</Typography>

							<Typography variant="h5" gutterBottom>
								9. Governing Law
							</Typography>
							<Typography variant="body1" paragraph>
								These terms and conditions are governed by and construed in accordance with the laws of the State
								of [State], without regard to its conflict of law provisions. Any legal action related to these
								terms will be brought in the state or federal courts located in [State].
							</Typography>

							<Typography variant="body1" paragraph>
								If you have any questions about these terms, please contact us at support@breakmark.com.
							</Typography>
						</Grid>
					</Grid>

					<Grid item xs={12} md={4}></Grid>
				</Grid>
			</BodyWrapper>
		</>
	);
};

export default TermsAndConditions;
