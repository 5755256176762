import styled from '@emotion/styled';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Breadcrumbs,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Grid
} from '@mui/material';
import React, { useState } from 'react';
import { ChevronDown, ChevronRight } from 'react-feather';
import { NavLink } from 'react-router-dom';

// import {useAuth} from "../../providers/authProvider";
import Page from '../Page';
import BundleProductList, { BundleCategories } from './BundleProductList';
import BundleStoreViewer from './BundleStoreViewer';

function BundleBuilderV2() {
	// const {user} = useAuth();
	const [categories, setCategories] = useState<BundleCategories>({
		bundle: false,
		solid_sub: false,
		full_sub: false
	});

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCategories({ ...categories, [event.target.value]: event.target.checked });
	};

	const BreadcrumbsWrapper = styled('div')`
		display: flex;
		flex-direction: column;
		width: 95%;
		max-width: 1240px;
		margin: auto;
	`;

	const Separator = styled('div')`
		width: 100%;
		border-bottom: 1px solid var(--separator);
		margin: 0 0 24px;
	`;

	const BodyWrapper = styled('div')`
		display: block;
		width: 95%;
		max-width: 1240px;
		margin: 24px auto;
	`;

	return (
		<>
			<BreadcrumbsWrapper>
				<Separator />
				<Breadcrumbs aria-label="breadcrumb" separator={<ChevronRight width={18} height={18} />}>
					<NavLink to={'/'}>
						<p className={'no-margin'}>Home</p>
					</NavLink>
					<NavLink to={'/My/BundleBuilder'}>
						<p className={'no-margin'}>
							<strong>Bundle Builder</strong>
						</p>
					</NavLink>
				</Breadcrumbs>
			</BreadcrumbsWrapper>
			<BodyWrapper>
				<Grid container columnSpacing={2} rowSpacing={2}>
					<Grid item xs={9}>
						<BundleProductList categories={categories} />
					</Grid>

					<Grid item xs={3}>
						<Accordion elevation={0} defaultExpanded={true}>
							<AccordionSummary expandIcon={<ChevronDown />}>
								<p className={'no-margin'}>Categories</p>
							</AccordionSummary>
							<AccordionDetails>
								<FormGroup>
									<FormControlLabel
										control={
											<Checkbox
												size={'small'}
												value={'bundle'}
												checked={categories.bundle}
												onChange={handleChange}
											/>
										}
										label={<small>Team Bundles</small>}
									/>
									<FormControlLabel
										control={
											<Checkbox
												size={'small'}
												value={'full_sub'}
												checked={categories.full_sub}
												onChange={handleChange}
											/>
										}
										label={<small>Custom Full Sub</small>}
									/>
									<FormControlLabel
										control={
											<Checkbox
												size={'small'}
												value={'solid_sub'}
												checked={categories.solid_sub}
												onChange={handleChange}
											/>
										}
										label={<small>Custom Solid Sub</small>}
									/>
								</FormGroup>
							</AccordionDetails>
						</Accordion>

						<BundleStoreViewer />
					</Grid>
				</Grid>
			</BodyWrapper>
		</>
	);
}

export default BundleBuilderV2;
