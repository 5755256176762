import { Grid } from '@mui/material';
import { useMemo } from 'react';
import React from 'react';
import { useOutletContext } from 'react-router-dom';

import BundlePreview from '../../components/BundlePreview';
import ProductPreview from '../../components/ProductPreview';
import BodyWrapper from '../../core/components/BodyWrapper';
import { TeamStoreFragment } from '../../generated/graphql';
import { useFormattedStoreProduct } from '../../hooks/useFormattedStoreProduct';

interface OutletProps {
	storeData: TeamStoreFragment;
	discountRate: number | undefined;
	discountType: 'dollar' | 'percent' | undefined;
}

function TeamStorePage() {
	const { storeData, discountRate, discountType } = useOutletContext<OutletProps>();

	const formattedProducts = useFormattedStoreProduct(storeData.store_products);
	const orderedProducts = useMemo(() => {
		return formattedProducts.sort((a, b) => a.order - b.order);
	}, [formattedProducts]);

	return (
		<BodyWrapper>
			<>
				<Grid container rowSpacing={2} columnSpacing={2}>
					<>
						{storeData.store_packages.map((pkg) => (
							<Grid key={pkg.id} item xs={6} md={3}>
								<BundlePreview pkg={pkg} discountRate={discountRate} discountType={discountType} />
							</Grid>
						))}
						{orderedProducts.map((product) => (
							<Grid key={product.id} item xs={6} md={3}>
								<ProductPreview
									id={product.id}
									image={product.display_photo}
									name={product.label!}
									stars={5}
									basePrice={product.product.base_price}
									discountRate={discountRate}
									discountType={discountType}
								/>
							</Grid>
						))}
					</>
				</Grid>
			</>
		</BodyWrapper>
	);
}

export default TeamStorePage;
