import { Grid } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import React from 'react';

import { AdditionalImage } from '../../core/types/ProductDef';
import { useConfigStore } from '../../stores/productConfiguration/useConfigureProduct';
import { SimplifiedProduct } from './ParseServerProductUtilities';
import { ImageSelector, ProductImage } from './ProductDetailStyledComponents';

interface Props {
	product: SimplifiedProduct;
}

const extractImages = (product: SimplifiedProduct): string[] => {
	const images: string[] = [];

	if (product.displayPhoto) {
		images.push(product.displayPhoto);
	}

	if (product.additionalImages && product.additionalImages.length > 0) {
		images.push(
			...product.additionalImages
				.sort((a: AdditionalImage, b: AdditionalImage) => a.index - b.index)
				.map((img: AdditionalImage) => img.url)
		);
	}

	if (product.options && product.options.length > 0) {
		product.options.forEach((option) => {
			if (option.values && option.values.length > 0) {
				option.values.forEach((value) => {
					if (value.imageUrl) {
						images.push(value.imageUrl);
					}
					if (value.secondaryImageUrl) {
						images.push(value.secondaryImageUrl);
					}
				});
			}
		});
	}

	return images;
};

function ProductDetailImageViewer({ product }: Props) {
	const { currentConfig } = useConfigStore();
	const [images, setImages] = useState<string[]>([]);
	const [selectedImage, setSelectedImage] = useState<string>();

	useEffect(() => {
		if (product) {
			const images = extractImages(product);
			if (images.length > 0) {
				setImages(images);
				setSelectedImage(images[0]);
			}
		}
	}, [product, setImages, setSelectedImage]);

	// useEffect(() => {
	// 	Object.values(currentConfig?.options || {}).forEach((opt) => {
	// 		if (opt.imageUrl && opt.imageUrl.length > 0) {
	// 			const optImages = [opt.imageUrl];
	// 			// if (opt.secondary_image_url && opt.secondary_image_url.length > 0) {
	// 			// 	optImages.push(opt.secondary_image_url);
	// 			// }
	// 			setImages(optImages);
	// 			setSelectedImage(optImages[0]);
	// 		}
	// 	});
	// }, [currentConfig?.options, setImages, setSelectedImage]);

	return (
		<Fragment>
			<Grid item md={1} sm={4} xs={12}>
				<ImageSelector>
					{images.map((image) => {
						return (
							<ProductImage
								src={image}
								key={image}
								onClick={() => {
									setSelectedImage(image);
								}}
								selected={selectedImage === image}
							/>
						);
					})}
				</ImageSelector>
			</Grid>
			<Grid item md={5} sm={8} xs={12}>
				<ProductImage src={selectedImage!} />
			</Grid>
		</Fragment>
	);
}

export default ProductDetailImageViewer;
