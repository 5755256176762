import styled from '@emotion/styled';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import React from 'react';

const AccordionContainer = styled(Box)`
	width: 100%;
	max-width: 600px;
	margin: 20px auto;
`;

const StyledAccordion = styled(Accordion)`
	background-color: transparent;
	box-shadow: none;
	border-bottom: 1px solid #e0e0e0;

	&:before {
		display: none;
	}

	&:last-child {
		border-bottom: none;
	}
`;

const StyledAccordionSummary = styled(AccordionSummary)`
	padding: 0;
	min-height: 48px;

	.MuiAccordionSummary-content {
		margin: 12px 0;
	}

	.MuiAccordionSummary-expandIconWrapper {
		color: #666;
	}

	&.Mui-expanded {
		min-height: 48px;

		.MuiAccordionSummary-content {
			margin: 12px 0;
		}
	}
`;

const StyledAccordionDetails = styled(AccordionDetails)`
	padding: 0 0 16px 0;
`;

const SectionTitle = styled(Typography)`
	font-size: 1rem;
	font-weight: 500;
	color: #333;
`;

const QuestionTitle = styled(Typography)`
	font-size: 0.9rem;
	font-weight: 500;
	color: #444;
	margin-top: 12px;
`;

const AnswerText = styled(Typography)`
	font-size: 0.85rem;
	color: #666;
	margin-top: 4px;
`;

const data = [
	{
		title: 'Orders',
		content: [
			{
				question: 'How do I start an order?',
				answer: 'Starting an order is easy! Click the Start your order button on any product or bundle page.'
			},
			{
				question: 'How long does it take to get my team order?',
				answer: 'Production is about 20-25 business days from start to ship. We start production when we have all order information complete.'
			},
			{
				question: 'What is a team store?',
				answer: "A team store is our way to collect all of your player's order information, simplifying the process with no spreadsheets or cash handling."
			}
		]
	},
	{
		title: 'Designs',
		content: [
			{
				question: 'What is the design process?',
				answer: 'We work with you to help figure out what you want to purchase and how you’d like to design it.'
			},
			{
				question: "I don't have any design experience, what do I do?",
				answer: 'No problem! Our talented team of designers will help bring your designs to life with any input you provide.'
			},
			{
				question: 'Can I get samples before placing the order?',
				answer: 'You pay a $20/sample fully refundable deposit. Once samples are returned, we refund the deposit.'
			}
		]
	},
	{
		title: 'Collaboration Store',
		content: [
			{
				question: 'What is the Collaboration Store?',
				answer: 'A collaboration store is a way for teams, events, and organizations to offer their products and fundraise.'
			},
			{
				question: 'How much money goes back to the team?',
				answer: 'Teams receive 20% of the sale.'
			},
			{
				question: 'When do teams get paid?',
				answer: 'Teams get paid at the end of their collaboration store period.'
			}
		]
	},
	{
		title: 'Shipping & Returns',
		content: [
			{
				question: 'Is shipping free?',
				answer: 'Team orders ship to 1 location for free, but individual player orders have associated shipping costs.'
			},
			{
				question: 'If something doesn’t fit can I get a refund?',
				answer: 'All products are custom and made to order, so we do not offer returns or exchanges.'
			},
			{
				question: 'What happens if there is something wrong with my order?',
				answer: 'If we made a mistake, we will remake your item at no charge.'
			}
		]
	}
];

const TeamApparelFAQ = () => {
	return (
		<AccordionContainer>
			<Typography variant="h6" gutterBottom sx={{ mb: 2, color: '#444' }}>
				Frequently Asked Questions
			</Typography>
			{data.map((section, index) => (
				<StyledAccordion key={index}>
					<StyledAccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls={`panel${index}-content`}
						id={`panel${index}-header`}
					>
						<SectionTitle>{section.title}</SectionTitle>
					</StyledAccordionSummary>
					<StyledAccordionDetails>
						{section.content.map((item, idx) => (
							<Box key={idx} mb={idx !== section.content.length - 1 ? 2 : 0}>
								<QuestionTitle>{item.question}</QuestionTitle>
								<AnswerText>{item.answer}</AnswerText>
							</Box>
						))}
					</StyledAccordionDetails>
				</StyledAccordion>
			))}
		</AccordionContainer>
	);
};

export default TeamApparelFAQ;
