import React, { ReactElement, useEffect, useMemo } from 'react';
import { Outlet, useLoaderData, useNavigate } from 'react-router-dom';

import BreadcrumbConnectedWrapper from '../../core/components/BreadcrumbConnectedWrapper';
import HeroContainer from '../../core/components/HeroContainer';
import { GetStoreContentsQuery } from '../../generated/graphql';
import { useBreadcrumbs } from '../../providers/breadcrumbProvider';

const TeamStoreContainer = (): ReactElement => {
	const navigate = useNavigate();
	const { addBreadcrumb, removeBreadcrumb } = useBreadcrumbs();

	const data = useLoaderData() as GetStoreContentsQuery;
	const storeData = data.team_store[0];

	useEffect(() => {
		const breadcrumb = { name: storeData.name, path: `/TeamStore/${storeData.id}` };
		addBreadcrumb(breadcrumb);

		return () => {
			removeBreadcrumb(breadcrumb.path);
		};
	}, []);

	useEffect(() => {
		if (storeData?.use_as_catalog) {
			navigate(`/group/${storeData.friendly_url_key || storeData.id}`, { replace: true });
		}
	}, [storeData?.use_as_catalog, storeData?.friendly_url_key, navigate]); // Adjust the dependencies

	// useEffect(() => {
	// 	if (data?.team_store?.[0]) {
	// 		uiDispatch(setCreditCardOption(data?.team_store?.[0].enable_credit_card_payment));
	// 		uiDispatch(setCheckOption(data?.team_store?.[0].enable_check_payment));
	// 	}
	// }, [data, uiDispatch]);

	// useEffect(() => {
	// 	if (called && !error && data?.team_store?.[0]) {
	// 		dispatch(setStoreId(data.team_store[0].id));
	// 	}
	// }, [called, error, data, dispatch]);

	const [discountRate, discountType] = useMemo((): [number | undefined, 'dollar' | 'percent' | undefined] => {
		const rate = storeData?.store_discount_rate || undefined;
		const type =
			storeData?.store_discount_type === 'dollar' || storeData?.store_discount_type === 'percent'
				? storeData.store_discount_type
				: undefined;

		return [rate, type];
	}, [storeData?.store_discount_rate, storeData?.store_discount_type]);

	if (!storeData) {
		return <></>;
	}
	return (
		<>
			<HeroContainer
				heading={storeData?.name || 'Team Store'}
				label={'Look your best!'}
				// imageUrl="https://pbs.twimg.com/profile_banners/207797283/1681574844/1500x500"
			/>
			<BreadcrumbConnectedWrapper />
			<Outlet
				context={{
					storeData,
					discountRate,
					discountType
				}}
			/>
		</>
	);
};

export default TeamStoreContainer;
