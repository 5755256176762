import { getImageUrl } from '../../utils/imageHelper';
import { SimplifiedProduct } from '../ProductDetail/ParseServerProductUtilities';

export interface TaggedSimplifiedProduct extends SimplifiedProduct {
	tags: string[];
}

export const TeamBundleApparelJson: TaggedSimplifiedProduct[] = [
	{
		id: 'bundle-1',
		label: 'Pro Bundle',
		name: 'Pro Bundle',
		basePrice: 7500,
		customPriceOverride: 6500,
		description: `
###### Production and Shipping:
- **Standard Production Timeline:** 20 - 25 Business Days
- **Minimum Order:** 12 Bundles
- **Minimum Reorder:** 1 Piece
- **Art Fees:** 2 Hours Included with Purchase
- **Additional Art Time:** $50/Hour (Paid Upfront)
- **Set Up, Names, and Numbers:** Free with Purchase
- **Shipping:** 5-7 Business Days (Free to one location)
---

###### What's Included:
- Pick **Two Solid Sub Short Sleeves** or **One Solid Sub Short Sleeve** and **One Pair of Solid Sub Shorts**

---

###### Fabric and Customization:
- **EnduraBounce Fabric**: Lightweight, breathable, performance poly to keep you cool.
- **Solid Sublimated**: Create a 12" x 16" design with unlimited colors. Pick the main and number color for shorts.
- Upgrade to **Flash Weave** fabric for +$5 (Same fit, lighter and faster drying).
	`,
		options: [],
		customNameEnabled: false,
		customNumberEnabled: false,
		displayPhoto: '/images/bundles/pro-bundle.jpg',
		tags: ['bundle', 'shirt', 'shorts', 'youth', 'women', 'men', 'customizable']
	},
	{
		id: 'bundle-2',
		label: 'All Star Bundle',
		name: 'All Star Bundle',
		basePrice: 12000,
		customPriceOverride: 7500,
		description: `
###### Production and Shipping:
- **Standard Production Timeline:** 20 - 25 Business Days
- **Minimum Order:** 12 Bundles
- **Minimum Reorder:** 1 Piece
- **Art Fees:** 3 Hours Included with Purchase
- **Additional Art Time:** $50/Hour (Paid Upfront)
- **Set Up, Names, and Numbers:** Free with Purchase
- **Shipping:** 5-7 Business Days (Free to one location)
---
###### What's Included:
- Two **Solid Sub Short Sleeves** and **Solid Sub Shorts**

---

###### Fabric and Customization:
- **EnduraBounce Fabric**: Lightweight, breathable, performance poly to keep you cool.
- **Solid Sublimated**: Create a 12" x 16" design for tops with unlimited colors. Pick the main and number color for shorts. 
- One top must be **white**.
- Upgrade to **Flash Weave** fabric for +$5 (Same fit, lighter and faster drying).
	`,
		options: [],
		customNameEnabled: false,
		customNumberEnabled: false,
		displayPhoto: '/images/bundles/all-star-bundle.jpg',
		tags: ['bundle', 'shirt', 'shorts', 'youth', 'women', 'men', 'customizable', 'white']
	},
	{
		id: 'bundle-3',
		label: 'MVP Bundle',
		name: 'MVP Bundle',
		basePrice: 23500,
		customPriceOverride: 9500,
		description: `
###### Production and Shipping:
- **Standard Production Timeline:** 20 - 25 Business Days
- **Minimum Order:** 12 Bundles
- **Minimum Reorder:** 1 Piece
- **Art Fees:** 4 Hours Included with Purchase
- **Additional Art Time:** $50/Hour (Paid Upfront)
- **Set Up, Names, and Numbers:** Free with Purchase
- **Shipping:** 5-7 Business Days (Free to one location)
---
###### What's Included:
- **FREE** Custom 5 Panel Hat (Reversible)
- One **Full Sub Short Sleeve** Jersey
- One **Solid Sub Short Sleeve** Jersey
- One Pair of **Full Sub Shorts**

---

###### Fabric and Customization:
- **EnduraBounce Fabric**: Lightweight, breathable, performance poly to keep you cool.
- **Fully Sublimated**: Customize with unlimited design options.
- **Solid Sublimated**: Pick the main and number color. The solid sub jersey must be **white**.
- Upgrade to **Flash Weave** fabric for +$5 (Same fit, lighter and faster drying).
	`,
		options: [],
		customNameEnabled: false,
		customNumberEnabled: false,
		displayPhoto: '/images/bundles/mvp-bundle.jpg',
		tags: ['bundle', 'shirt', 'shorts', 'hat', 'youth', 'women', 'men', 'customizable', 'white']
	},
	{
		id: 'bundle-4',
		label: 'Epic Bundle',
		name: 'Epic Bundle',
		basePrice: 24000,
		customPriceOverride: 10000,
		description: `
###### Production and Shipping:
- **Standard Production Timeline:** 20 - 25 Business Days
- **Minimum Order:** 12 Bundles
- **Minimum Reorder:** 1 Piece
- **Art Fees:** 5 Hours Included with Purchase
- **Additional Art Time:** $50/Hour (Paid Upfront)
- **Set Up, Names, and Numbers:** Free with Purchase
- **Shipping:** 5-7 Business Days (Free to one location)
---
###### What's Included:
- **FREE** Custom 5 Panel Hat (Reversible)
- Two **Full Sub Short Sleeves**
- One Pair of **Solid Sub Shorts**

---

###### Fabric and Customization:
- **EnduraBounce Fabric**: Lightweight, breathable, performance poly to keep you cool.
- **Fully Sublimated**: Customize with unlimited design options for tops. 
- **Solid Sublimated**: Pick the main and number color for shorts.
- Upgrade to **Flash Weave** fabric for +$5 (Same fit, lighter and faster drying).
	`,
		options: [],
		customNameEnabled: false,
		customNumberEnabled: false,
		displayPhoto: '/images/bundles/epic-bundle.jpg',
		tags: ['bundle', 'shirt', 'shorts', 'hat', 'youth', 'women', 'men', 'customizable']
	},
	{
		id: 'bundle-5',
		label: 'Legend Bundle',
		name: 'Legend Bundle',
		basePrice: 25000,
		customPriceOverride: 11000,
		description: `
###### Production and Shipping:
- **Standard Production Timeline:** 20 - 25 Business Days
- **Minimum Order:** 12 Bundles
- **Minimum Reorder:** 1 Piece
- **Art Fees:** 6 Hours Included with Purchase
- **Additional Art Time:** $50/Hour (Paid Upfront)
- **Set Up, Names, and Numbers:** Free with Purchase
- **Shipping:** 5-7 Business Days (Free to one location)
---

###### What's Included:
- **FREE** Custom 5 Panel Hat (Reversible)
- Two **Full Sub Short Sleeves**
- One Pair of **Full Sub Shorts**

---

###### Fabric and Customization:
- **EnduraBounce Fabric**: Lightweight, breathable, performance poly to keep you cool.
- **Fully Sublimated**: Customize with unlimited design options.
- Upgrade to **Flash Weave** fabric for +$5 (Same fit, lighter and faster drying).
	`,
		options: [],
		customNameEnabled: false,
		customNumberEnabled: false,
		displayPhoto: '/images/bundles/legend-bundle.jpg',
		tags: ['bundle', 'shirt', 'shorts', 'hat', 'youth', 'women', 'men', 'customizable']
	},
	{
		id: 'product-1756',
		label: 'Solid Sub Sleeveless Hood Team Custom Product',
		name: 'Solid Sub Sleeveless Hood Team Custom Product',
		basePrice: 5000,
		description: `
This sleeveless hood is made from EnduraBounce, our lightweight, breathable, performance poly fabric to keep you feeling cool. Our classic Jersey, for all types of players.

This jersey is solid sublimated that allows you to make this jersey as funky as you want with unlimited design options.

###### Production and Shipping:
- **Standard Production Timeline:** 20 - 25 Business Days
- **Minimum Order:** 12 Pieces
- **Minimum Reorder:** 1 Piece
- **Art Fees:** Two Hours With Purchase
- **Additional Art Time:** $50/Hour (Paid Upfront)
- **Set Up, Names, and Numbers:** Free with Purchase
- **Shipping:** 5-7 Business Days (Free to one location)
		`,
		options: [],
		customNameEnabled: false,
		customNumberEnabled: false,
		displayPhoto: getImageUrl('Solid_114_Front__87271.1698685906.png'),
		tags: ['shirt', 'sleeveless', 'hood', 'youth', 'women', 'men', 'customizable', 'solid']
	},
	{
		id: 'product-1755',
		label: 'Solid Sub Polo Team Custom Product',
		name: 'Solid Sub Polo Team Custom Product',
		basePrice: 5000,
		description: `
This polo is made from EnduraBounce, our lightweight, breathable, performance poly fabric to keep you feeling cool. Our classic Jersey, for all types of players.

This jersey is solid sublimated that allows you to make this jersey as funky as you want with unlimited design options.

###### Production and Shipping:
- **Standard Production Timeline:** 20 - 25 Business Days
- **Minimum Order:** 12 Pieces
- **Minimum Reorder:** 1 Piece
- **Art Fees:** Two Hours With Purchase
- **Additional Art Time:** $50/Hour (Paid Upfront)
- **Set Up, Names, and Numbers:** Free with Purchase
- **Shipping:** 5-7 Business Days (Free to one location)
		`,
		options: [],
		customNameEnabled: false,
		customNumberEnabled: false,
		displayPhoto: getImageUrl('Solid_Polo_111_Front___52470.1698685769.png'),
		tags: ['shirt', 'polo', 'youth', 'women', 'men', 'customizable', 'solid']
	},
	{
		id: 'product-1754',
		label: 'Full Sub Sleeveless Hood Team Custom Product',
		name: 'Full Sub Sleeveless Hood Team Custom Product',
		basePrice: 6500,
		description: `
This sleeveless hood is made from EnduraBounce, our lightweight, breathable, performance poly fabric to keep you feeling cool. Our classic Jersey, for all types of players.

This jersey is fully sublimated that allows you to make this jersey as funky as you want with unlimited design options.

###### Production and Shipping:
- **Standard Production Timeline:** 20 - 25 Business Days
- **Minimum Order:** 12 Pieces
- **Minimum Reorder:** 1 Piece
- **Art Fees:** Two Hours With Purchase
- **Additional Art Time:** $50/Hour (Paid Upfront)
- **Set Up, Names, and Numbers:** Free with Purchase
- **Shipping:** 5-7 Business Days (Free to one location)
		`,
		options: [],
		customNameEnabled: false,
		customNumberEnabled: false,
		displayPhoto: getImageUrl('FullSUb_114_Front__78366.1698685686.png'),
		tags: ['shirt', 'sleeveless', 'hood', 'youth', 'women', 'men', 'customizable', 'full']
	},
	{
		id: 'product-1753',
		label: 'Full Sub Polo Team Custom Product',
		name: 'Full Sub Polo Team Custom Product',
		basePrice: 6500,
		description: `
This polo is made from EnduraBounce, our lightweight, breathable, performance poly fabric to keep you feeling cool. Our classic Jersey, for all types of players.

This jersey is fully sublimated that allows you to make this jersey as funky as you want with unlimited design options.

###### Production and Shipping:
- **Standard Production Timeline:** 20 - 25 Business Days
- **Minimum Order:** 12 Pieces
- **Minimum Reorder:** 1 Piece
- **Art Fees:** Two Hours With Purchase
- **Additional Art Time:** $50/Hour (Paid Upfront)
- **Set Up, Names, and Numbers:** Free with Purchase
- **Shipping:** 5-7 Business Days (Free to one location)
		`,
		options: [],
		customNameEnabled: false,
		customNumberEnabled: false,
		displayPhoto: getImageUrl('FullSub_Polo_111_Front___98869.1698685718.png'),
		tags: ['shirt', 'polo', 'youth', 'women', 'men', 'customizable', 'full']
	},
	{
		id: 'product-1750',
		label: 'Full Sub 5 Panel Hat Pattern',
		name: 'Full Sub 5 Panel Hat Pattern',
		basePrice: 3000,
		description: `
These hats are fully sublimated which allows you to make it as funky as you want with unlimited design options. Have our artists help you create a repeating pattern that will cover the hat. Hats are made with our Flash Weave fabric, a sweatband sewn into the base, and a snapback closure.

###### Production and Shipping:
- **Standard Production Timeline:** 20 - 25 Business Days
- **Minimum Order:** 12 Pieces
- **Minimum Reorder:** 1 Piece
- **Art Fees:** Two Hours With Purchase
- **Additional Art Time:** $50/Hour (Paid Upfront)
- **Set Up, Names, and Numbers:** Free with Purchase
- **Shipping:** 5-7 Business Days (Free to one location)
		`,
		options: [],
		customNameEnabled: false,
		customNumberEnabled: false,
		displayPhoto: getImageUrl('Tulips_5Panel_Angled__40903.1694704558.png'),
		tags: ['hat', 'customizable', 'full']
	},
	{
		id: 'product-1749',
		label: 'Full Sub 5 Panel Hat Custom',
		name: 'Full Sub 5 Panel Hat Custom',
		basePrice: 3000,
		description: `
These hats are fully sublimated which allows you to make it as funky as you want with unlimited design options. Each panel is customizable and you can have different designs on the top and bottom brim. Hats are made with our Flash Weave fabric, a sweatband sewn into the base, and a snapback closure.

###### Production and Shipping:
- **Standard Production Timeline:** 20 - 25 Business Days
- **Minimum Order:** 12 Pieces
- **Minimum Reorder:** 1 Piece
- **Art Fees:** Two Hours With Purchase
- **Additional Art Time:** $50/Hour (Paid Upfront)
- **Set Up, Names, and Numbers:** Free with Purchase
- **Shipping:** 5-7 Business Days (Free to one location)
		`,
		options: [],
		customNameEnabled: false,
		customNumberEnabled: false,
		displayPhoto: getImageUrl('Colorblock_5Panel_Angled__34335.1694646195.png'),
		tags: ['hat', 'customizable', 'full']
	},
	{
		id: 'product-819',
		label: 'Full Sub Blanket Custom Product',
		name: 'Full Sub Blanket Custom Product',
		basePrice: 5000,
		description: `
###### Size:
- 50" x 60"

Blankets are fully sublimated which allows you to make it as funky as you want with unlimited design options. Blankets are made out of velveteen fleece and are 50" x 60".
		`,
		options: [],
		customNameEnabled: false,
		customNumberEnabled: false,
		displayPhoto: getImageUrl('Horizontals_Red_Full_Dark_Blanket__45380.1633712830.png'),
		tags: ['blanket', 'customizable', 'full']
	},
	{
		id: 'product-809',
		label: 'Full Sub Banas Custom Product',
		name: 'Full Sub Banas Custom Product',
		basePrice: 2000,
		description: `
These banas are fully sublimated which allows you to make it as funky as you want with unlimited design options. Newly improved banas are now made with our Helio Lite fabric with finished top and bottom hem. Now with one side seam to allow for larger designs.

###### Production and Shipping:
- **Standard Production Timeline:** 20 - 25 Business Days
- **Minimum Order:** 12 Pieces
- **Minimum Reorder:** 1 Piece
- **Art Fees:** Two Hours With Purchase
- **Additional Art Time:** $50/Hour (Paid Upfront)
- **Set Up, Names, and Numbers:** Free with Purchase
- **Shipping:** 5-7 Business Days (Free to one location)
		`,
		options: [],
		customNameEnabled: false,
		customNumberEnabled: false,
		displayPhoto: getImageUrl('Vee_Orange_Full_Dark_Bana__12109.1633712915.png'),
		tags: ['banas', 'customizable', 'full']
	},
	{
		id: 'product-808',
		label: 'Full Sub Socks Custom Product',
		name: 'Full Sub Socks Custom Product',
		basePrice: 2000,
		description: `
These socks are fully sublimated which allows you to make it as funky as you want with unlimited design options. These are made up of a Cotton/Poly/Spandex blend.

###### Production and Shipping:
- **Standard Production Timeline:** 20 - 25 Business Days
- **Minimum Order:** 12 Pieces
- **Minimum Reorder:** 1 Piece
- **Art Fees:** Two Hours With Purchase
- **Additional Art Time:** $50/Hour (Paid Upfront)
- **Set Up, Names, and Numbers:** Free with Purchase
- **Shipping:** 5-7 Business Days (Free to one location)
		`,
		options: [],
		customNameEnabled: false,
		customNumberEnabled: false,
		displayPhoto: getImageUrl('Deco_Yellow_Full_Dark_Socks__23480.1633712970.png'),
		tags: ['socks', 'customizable', 'full']
	},
	{
		id: 'product-805',
		label: 'Solid Sub Reversible Team Custom Product',
		name: 'Solid Sub Reversible Team Custom Product',
		basePrice: 5000,
		description: `
This reversible is made from Feather Mesh, our lightweight, breathable, mini mesh to keep you feeling cool. This is solid sublimated which allows you to make it as funky as you want with unlimited design options.

###### Production and Shipping:
- **Standard Production Timeline:** 20 - 25 Business Days
- **Minimum Order:** 12 Pieces
- **Minimum Reorder:** 1 Piece
- **Art Fees:** Two Hours With Purchase
- **Additional Art Time:** $50/Hour (Paid Upfront)
- **Set Up, Names, and Numbers:** Free with Purchase
- **Shipping:** 5-7 Business Days (Free to one location)
		`,
		options: [],
		customNameEnabled: false,
		customNumberEnabled: false,
		displayPhoto: getImageUrl('Horizontals_Red_Solid_RV__23594.1633713560.png'),
		tags: ['shirt', 'reversible', 'youth', 'women', 'men', 'customizable', 'solid']
	},
	{
		id: 'product-804',
		label: 'Solid Sub Shorts Team Custom Product',
		name: 'Solid Sub Shorts Team Custom Product',
		basePrice: 3000,
		description: `
This pair of shorts is made from EnduraBounce, our lightweight, breathable, performance poly fabric to keep you feeling cool. Our classic Shorts, for all types of players.

Upgrade to Flash Weave, our quick-dry shorts for + $5. Same fit, lighter and faster drying.

These shorts are solid sublimated which allows you to select one main color. Then you can select the number font and color.

###### Production and Shipping:
- **Standard Production Timeline:** 20 - 25 Business Days
- **Minimum Order:** 12 Pieces
- **Minimum Reorder:** 1 Piece
- **Art Fees:** Two Hours With Purchase
- **Additional Art Time:** $50/Hour (Paid Upfront)
- **Set Up, Names, and Numbers:** Free with Purchase
- **Shipping:** 5-7 Business Days (Free to one location)
		`,
		options: [],
		customNameEnabled: false,
		customNumberEnabled: false,
		displayPhoto: getImageUrl('Vee_Orange_Solid_Dark_Shorts_Front__41774.1633713748.png'),
		tags: ['shorts', 'youth', 'women', 'men', 'customizable', 'solid']
	},
	{
		id: 'product-803',
		label: 'Solid Sub Tank Team Custom Product',
		name: 'Solid Sub Tank Team Custom Product',
		basePrice: 4500,
		description: `
This tank is made from EnduraBounce, our lightweight, breathable, performance poly fabric to keep you feeling cool. Our classic Jersey, for all types of players.

This jersey is solid sublimated that allows you to make this jersey as funky as you want with unlimited design options.

###### Production and Shipping:
- **Standard Production Timeline:** 20 - 25 Business Days
- **Minimum Order:** 12 Pieces
- **Minimum Reorder:** 1 Piece
- **Art Fees:** Two Hours With Purchase
- **Additional Art Time:** $50/Hour (Paid Upfront)
- **Set Up, Names, and Numbers:** Free with Purchase
- **Shipping:** 5-7 Business Days (Free to one location)
		`,
		options: [],
		customNameEnabled: false,
		customNumberEnabled: false,
		displayPhoto: getImageUrl('Deco_Yellow_Solid_Dark_TT_Front__70552.1633713620.png'),
		tags: ['shirt', 'tank', 'youth', 'women', 'men', 'customizable', 'solid']
	},
	{
		id: 'product-802',
		label: 'Solid Sub Long Sleeve Hood Team Custom Product',
		name: 'Solid Sub Long Sleeve Hood Team Custom Product',
		basePrice: 5500,
		description: `
This long sleeve hood is made from EnduraBounce, our lightweight, breathable, performance poly fabric to keep you feeling cool. Our classic Jersey, for all types of players.

This jersey is solid sublimated that allows you to make this jersey as funky as you want with unlimited design options.

###### Production and Shipping:
- **Standard Production Timeline:** 20 - 25 Business Days
- **Minimum Order:** 12 Pieces
- **Minimum Reorder:** 1 Piece
- **Art Fees:** Two Hours With Purchase
- **Additional Art Time:** $50/Hour (Paid Upfront)
- **Set Up, Names, and Numbers:** Free with Purchase
- **Shipping:** 5-7 Business Days (Free to one location)
		`,
		options: [],
		customNameEnabled: false,
		customNumberEnabled: false,
		displayPhoto: getImageUrl('Sash_Green_Solid_Dark_LSH_Front__97705.1633713657.png'),
		tags: ['shirt', 'long sleeve', 'hood', 'youth', 'women', 'men', 'customizable', 'solid']
	},
	{
		id: 'product-801',
		label: 'Solid Sub Long Sleeve Team Custom Product',
		name: 'Solid Sub Long Sleeve Team Custom Product',
		basePrice: 5000,
		description: `
This long sleeve is made from EnduraBounce, our lightweight, breathable, performance poly fabric to keep you feeling cool. Our classic Jersey, for all types of players.

This jersey is solid sublimated that allows you to make this jersey as funky as you want with unlimited design options.

###### Production and Shipping:
- **Standard Production Timeline:** 20 - 25 Business Days
- **Minimum Order:** 12 Pieces
- **Minimum Reorder:** 1 Piece
- **Art Fees:** Two Hours With Purchase
- **Additional Art Time:** $50/Hour (Paid Upfront)
- **Set Up, Names, and Numbers:** Free with Purchase
- **Shipping:** 5-7 Business Days (Free to one location)
		`,
		options: [],
		customNameEnabled: false,
		customNumberEnabled: false,
		displayPhoto: getImageUrl('Gems_Blue_Solid_Dark_LS_Front__75085.1633713699.png'),
		tags: ['shirt', 'long sleeve', 'youth', 'women', 'men', 'customizable', 'solid']
	},
	{
		id: 'product-800',
		label: 'Solid Sub Short Sleeve Team Custom Product',
		name: 'Solid Sub Short Sleeve Team Custom Product',
		basePrice: 4500,
		description: `
This short sleeve is made from EnduraBounce, our lightweight, breathable, performance poly fabric to keep you feeling cool. Our classic Jersey, for all types of players.

This jersey is solid sublimated that allows you to make this jersey as funky as you want with unlimited design options.

###### Production and Shipping:
- **Standard Production Timeline:** 20 - 25 Business Days
- **Minimum Order:** 12 Pieces
- **Minimum Reorder:** 1 Piece
- **Art Fees:** Two Hours With Purchase
- **Additional Art Time:** $50/Hour (Paid Upfront)
- **Set Up, Names, and Numbers:** Free with Purchase
- **Shipping:** 5-7 Business Days (Free to one location)
		`,
		options: [],
		customNameEnabled: false,
		customNumberEnabled: false,
		displayPhoto: getImageUrl('Rise_Violet_Solid_Dark_SS_Front__41682.1633713726.png'),
		tags: ['shirt', 'short sleeve', 'youth', 'women', 'men', 'customizable', 'solid']
	},
	{
		id: 'product-793',
		label: 'Full Sub Reversible Team Custom Product',
		name: 'Full Sub Reversible Team Custom Product',
		basePrice: 6000,
		description: `
This reversible is made from Feather Mesh, our lightweight, breathable, mini mesh to keep you feeling cool. This is fully sublimated which allows you to make it as funky as you want with unlimited design options.

###### Production and Shipping:
- **Standard Production Timeline:** 20 - 25 Business Days
- **Minimum Order:** 12 Pieces
- **Minimum Reorder:** 1 Piece
- **Art Fees:** Two Hours With Purchase
- **Additional Art Time:** $50/Hour (Paid Upfront)
- **Set Up, Names, and Numbers:** Free with Purchase
- **Shipping:** 5-7 Business Days (Free to one location)
		`,
		options: [],
		customNameEnabled: false,
		customNumberEnabled: false,
		displayPhoto: getImageUrl('Sash_Green_Full_RV__13977.1633713018.png'),
		tags: ['shirt', 'reversible', 'youth', 'women', 'men', 'customizable', 'full']
	},
	{
		id: 'product-792',
		label: 'Full Sub Shorts Team Custom Product',
		name: 'Full Sub Shorts Team Custom Product',
		basePrice: 4000,
		description: `
This pair of shorts is made from EnduraBounce, our lightweight, breathable, performance poly fabric to keep you feeling cool. Our classic Shorts, for all types of players.

Upgrade to Flash Weave, our quick-dry shorts for + $5. Same fit, lighter and faster drying.

These shorts are fully sublimated that allows you to make it as funky as you want with unlimited design options.

###### Production and Shipping:
- **Standard Production Timeline:** 20 - 25 Business Days
- **Minimum Order:** 12 Pieces
- **Minimum Reorder:** 1 Piece
- **Art Fees:** Two Hours With Purchase
- **Additional Art Time:** $50/Hour (Paid Upfront)
- **Set Up, Names, and Numbers:** Free with Purchase
- **Shipping:** 5-7 Business Days (Free to one location)

		`,
		options: [],
		customNameEnabled: false,
		customNumberEnabled: false,
		displayPhoto: getImageUrl('Gems_Blue_Full_Dark_Shorts_Front__38384.1633713083.png'),
		tags: ['shorts', 'youth', 'women', 'men', 'customizable', 'full']
	},
	{
		id: 'product-791',
		label: 'Full Sub Tank Team Custom Product',
		name: 'Full Sub Tank Team Custom Product',
		basePrice: 5500,
		description: `
This tank is made from EnduraBounce, our lightweight, breathable, performance poly fabric to keep you feeling cool. Our classic Jersey, for all types of players.

This jersey is fully sublimated that allows you to make this jersey as funky as you want with unlimited design options.

###### Production and Shipping:
- **Standard Production Timeline:** 20 - 25 Business Days
- **Minimum Order:** 12 Pieces
- **Minimum Reorder:** 1 Piece
- **Art Fees:** Two Hours With Purchase
- **Additional Art Time:** $50/Hour (Paid Upfront)
- **Set Up, Names, and Numbers:** Free with Purchase
- **Shipping:** 5-7 Business Days (Free to one location)

		`,
		options: [],
		customNameEnabled: false,
		customNumberEnabled: false,
		displayPhoto: getImageUrl('Vibrate_Indigo_Full_Dark_TT_Front__45954.1633713222.png'),
		tags: ['shirt', 'tank', 'youth', 'women', 'men', 'customizable', 'full']
	},
	{
		id: 'product-790',
		label: 'Full Sub Long Sleeve Hood Team Custom Product',
		name: 'Full Sub Long Sleeve Hood Team Custom Product',
		basePrice: 7000,
		description: `
This long sleeve hood is made from EnduraBounce, our lightweight, breathable, performance poly fabric to keep you feeling cool. Our classic Jersey, for all types of players.

This jersey is fully sublimated that allows you to make this jersey as funky as you want with unlimited design options.

###### Production and Shipping:
- **Standard Production Timeline:** 20 - 25 Business Days
- **Minimum Order:** 12 Pieces
- **Minimum Reorder:** 1 Piece
- **Art Fees:** Two Hours With Purchase
- **Additional Art Time:** $50/Hour (Paid Upfront)
- **Set Up, Names, and Numbers:** Free with Purchase
- **Shipping:** 5-7 Business Days (Free to one location)

		`,
		options: [],
		customNameEnabled: false,
		customNumberEnabled: false,
		displayPhoto: getImageUrl('Rise_Violet_Full_Dark_LSH_Front__51959.1633713449.png'),
		tags: ['shirt', 'long sleeve', 'hood', 'youth', 'women', 'men', 'customizable', 'full']
	},
	{
		id: 'product-789',
		label: 'Full Sub Long Sleeve Team Custom Product',
		name: 'Full Sub Long Sleeve Team Custom Product',
		basePrice: 6500,
		description: `
This long sleeve is made from EnduraBounce, our lightweight, breathable, performance poly fabric to keep you feeling cool. Our classic Jersey, for all types of players.

This jersey is fully sublimated that allows you to make this jersey as funky as you want with unlimited design options.

###### Production and Shipping:
- **Standard Production Timeline:** 20 - 25 Business Days
- **Minimum Order:** 12 Pieces
- **Minimum Reorder:** 1 Piece
- **Art Fees:** Two Hours With Purchase
- **Additional Art Time:** $50/Hour (Paid Upfront)
- **Set Up, Names, and Numbers:** Free with Purchase
- **Shipping:** 5-7 Business Days (Free to one location)
		`,
		options: [],
		customNameEnabled: false,
		customNumberEnabled: false,
		displayPhoto: getImageUrl('Horizontals_Red_Full_Dark_LS_Front__41304.1633713302.png'),
		tags: ['shirt', 'long sleeve', 'youth', 'women', 'men', 'customizable', 'full']
	},

	{
		id: 'product-780',
		label: 'Full Sub Short Sleeve Team Custom Product',
		name: 'Full Sub Short Sleeve Team Custom Product',
		basePrice: 6000,
		description: `
This short sleeve is made from EnduraBounce, our lightweight, breathable, performance poly fabric to keep you feeling cool. Our classic Jersey, for all types of players.

This jersey is fully sublimated that allows you to make this jersey as funky as you want with unlimited design options.

###### Production and Shipping:
- **Standard Production Timeline:** 20 - 25 Business Days
- **Minimum Order:** 12 Pieces
- **Minimum Reorder:** 1 Piece
- **Art Fees:** Two Hours With Purchase
- **Additional Art Time:** $50/Hour (Paid Upfront)
- **Set Up, Names, and Numbers:** Free with Purchase
- **Shipping:** 5-7 Business Days (Free to one location)
		`,
		options: [],
		customNameEnabled: false,
		customNumberEnabled: false,
		displayPhoto: getImageUrl('Gems_Blue_Full_Dark_SS_Front__94985.1633713418.png'),
		tags: ['shirt', 'short sleeve', 'youth', 'women', 'men', 'customizable', 'full']
	}
];

// write a getter to get the product by id
export const getTeamCustomBundleById = (id: string) => {
	return TeamBundleApparelJson.find((product) => product.id === id);
};
