import styled from '@emotion/styled';
import * as MuiIcons from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import React, { useEffect, useState } from 'react';

import { Feature } from './types';

interface SmallFeatureCardProps extends Feature {
	onClick: () => void;
}

const CardModifier = styled.div<{ bgColor: string; imageUrl?: string }>`
	width: 405px;
	height: 200px;
	border-radius: 20px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	transition:
		transform 0.3s ease-in-out,
		box-shadow 0.3s ease-in-out;
	background: ${(props) => `linear-gradient(to right, 
		${props.bgColor} 0%, 
		${props.bgColor} 60%, 
		${props.bgColor}00 75%
	), url(${props.imageUrl}) right/contain no-repeat`};
	padding: 32px;
	box-sizing: border-box;
	position: relative;
	overflow: hidden;
`;

const CardContainer = styled.div`
	position: relative;
	cursor: pointer;
	height: 100%;
`;

const CardContent = styled.div`
	position: relative;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 65%;
`;

const Title = styled.h3`
	font-weight: bold;
	text-align: left;
	margin-bottom: 16px;
	font-size: 1.7rem;
	color: #fff;
	position: relative;
	z-index: 1;
	text-transform: uppercase;
`;

const Description = styled.p`
	text-align: left;
	margin-bottom: 24px;
	color: #fff;
	flex-grow: 1;
	position: relative;
	z-index: 1;
	font-size: 16px;
`;

const CardItem = styled.div`
	flex: 0 0 auto;
	scroll-snap-align: start;
	position: relative;
	cursor: pointer;

	height: 100%;
	transition: transform 0.3s cubic-bezier(0, 0, 0.5, 1);
	&:hover {
		transform: scale(1.05);
	}
`;

const SmallFeatureCard: React.FC<SmallFeatureCardProps> = ({ title, shortDescription, onClick, imageUrl, bgColor, icon }) => {
	const [DynamicIcon, setDynamicIcon] = useState<React.ElementType | null>(null);

	useEffect(() => {
		const loadIcon = async () => {
			if (icon) {
				const iconName = icon as keyof typeof MuiIcons;
				const IconComponent = MuiIcons[iconName];
				setDynamicIcon(() => IconComponent);
			}
		};

		loadIcon();
	}, [icon]);

	return (
		<CardItem>
			<CardContainer>
				<CardModifier bgColor={bgColor} imageUrl={imageUrl}>
					<CardContent>
						<Title>{title}</Title>
						<Description>{shortDescription}</Description>
					</CardContent>
				</CardModifier>
			</CardContainer>
		</CardItem>
	);
};

export default SmallFeatureCard;
