import { ApolloProvider } from '@apollo/client';
import React, { ReactElement, ReactNode } from 'react';

import { createApolloClient } from '../utils/apolloClient';

interface Props {
	children: ReactNode;
}

const apolloClient = createApolloClient();

const ApolloWrapper = (props: Props): ReactElement => {
	return <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>;
};

export default ApolloWrapper;
