import styled from '@emotion/styled';

const ImageCredit = styled('span')`
	position: absolute;
	bottom: 5px;
	right: 10px;
	color: rgba(255, 255, 255, 0.7);
	font-size: 0.7rem;
	z-index: 2;
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
`;

export default ImageCredit;
