import { LoaderFunction } from 'react-router-dom';

import { useFriendlyKeyHelper } from '../../core/hooks/useFriendlyKeyHelper';
import { GetStoreContentsDocument, GetStoreContentsQuery } from '../../generated/graphql';
import { createApolloClient } from '../../utils/apolloClient';

const apolloClient = createApolloClient();

interface LoaderParams {
	storeId?: string;
}

const TeamStoreDataLoader: LoaderFunction = async ({ params }: { params: LoaderParams }) => {
	const { storeId } = params;

	if (!storeId) {
		throw new Error('Store ID is missing');
	}

	const variables = useFriendlyKeyHelper(storeId);

	try {
		const { data } = await apolloClient.query<GetStoreContentsQuery>({
			query: GetStoreContentsDocument,
			variables
		});

		if (!data) {
			throw new Error('Failed to fetch product data');
		}

		return data;
	} catch (error) {
		throw new Error(`Loader error:`);
	}
};

export default TeamStoreDataLoader;
