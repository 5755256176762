import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { PersistOptions } from 'zustand/middleware';

import { Package, Product } from './cartStoreDefs';

const LOCAL_STORAGE_KEY = 'C_CART_STORE';

interface CartState {
	storeData: {
		[storeId: string]: (Product | Package)[];
	};
	currentStoreId?: string;
	addProduct: (props: { storeId: string; item: Product | Package }) => void;
	removeProduct: (props: { storeId: string; productIndex: number }) => void;
	editProduct: (props: { storeId: string; productIndex: number; updatedItem: Product | Package }) => void;
	addLabel: (props: { storeId: string; productIndex: number; orderFor: string }) => void;
	setCurrentStore: (props: { storeId: string }) => void;
	clearStoreCart: (props: { storeId: string }) => void;
	updateProductConfiguration: (props: {
		storeId: string;
		productIndex: number;
		updateFn: (product: Product | Package) => Product | Package;
	}) => void;
}

export const useCartStore = create<CartState>()(
	devtools(
		persist(
			(set) => ({
				storeData: {},
				currentStoreId: undefined,

				addProduct: ({ storeId, item }) =>
					set(
						(state) => ({
							storeData: {
								...state.storeData,
								[storeId]: [...(state.storeData[storeId] || []), item]
							}
						}),
						false,
						'Cart Add Product'
					),

				removeProduct: ({ storeId, productIndex }) =>
					set(
						(state) => ({
							storeData: {
								...state.storeData,
								[storeId]: state.storeData[storeId].filter((_, index) => index !== productIndex)
							}
						}),
						false,
						'Cart Remove Product'
					),

				editProduct: ({ storeId, productIndex, updatedItem }) =>
					set(
						(state) => ({
							storeData: {
								...state.storeData,
								[storeId]: state.storeData[storeId].map((item, index) =>
									index === productIndex ? updatedItem : item
								)
							}
						}),
						false,
						'Cart Edit Product'
					),

				addLabel: ({ storeId, productIndex, orderFor }) =>
					set(
						(state) => ({
							storeData: {
								...state.storeData,
								[storeId]: state.storeData[storeId].map((item, index) =>
									index === productIndex ? { ...item, order_for: orderFor } : item
								)
							}
						}),
						false,
						'Cart Label Product'
					),

				setCurrentStore: ({ storeId }) => set({ currentStoreId: storeId }, false, 'Set Current Store'),

				clearStoreCart: ({ storeId }) =>
					set(
						(state) => ({
							storeData: {
								...state.storeData,
								[storeId]: []
							}
						}),
						false,
						'Cart Clear Store'
					),

				updateProductConfiguration: ({ storeId, productIndex, updateFn }) =>
					set(
						(state) => ({
							storeData: {
								...state.storeData,
								[storeId]: state.storeData[storeId].map((item, index) =>
									index === productIndex ? updateFn(item) : item
								)
							}
						}),
						false,
						'Cart Update Product Config'
					)
			}),
			{
				name: LOCAL_STORAGE_KEY,
				getStorage: () => localStorage
			} as PersistOptions<CartState>
		)
	)
);
