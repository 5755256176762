import styled from '@emotion/styled';

export const BundleStepsWrapper = styled.div`
	display: flex;

	//background-color: var(--separator);
	flex-wrap: wrap;
`;

export const BundleStep = styled.div<{ isActive: boolean; isDisabled: boolean }>`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	cursor: ${(props) => (props.isDisabled ? 'not-allowed' : props.isActive ? 'default' : 'pointer')};
	opacity: ${(props) => (props.isDisabled ? 0.3 : props.isActive ? 1 : 0.6)};
	border: ${(props) => (props.isDisabled ? '1px solid var(--gold)' : '1px solid var(--green)')};
	border-bottom: ${(props) => (props.isActive ? '10px solid var(--white)' : '10px solid var(--grey)')};
	background-color: ${(props) => (props.isActive ? 'var(--white)' : 'var(--grey)')};
	padding: 10px 20px 0 20px;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	margin: 10px 10px 0 10px;
	pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};

	&:hover {
		opacity: ${(props) => (props.isDisabled ? 0.3 : 1)};
	}
`;

export const ProductImage = styled.img`
	width: 50px;
	height: 50px;
	object-fit: cover;
	border-radius: 50%;
	margin-bottom: 5px;
`;

export const ProductLabel = styled.span`
	font-size: 14px;
	text-align: center;
`;

export const BottomNav = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 20px;
	background-color: #fff;
	box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
`;
