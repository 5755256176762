import styled from '@emotion/styled';
import LogoutIcon from '@mui/icons-material/Logout';
import { Button, Grid } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import React, { Fragment, useEffect } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';

import BreadcrumbConnectedWrapper from '../../core/components/BreadcrumbConnectedWrapper';
import { formatCurrencyValueFromServer } from '../../core/utilities/currency';
import { MyAccountLoaderReturn } from '../../loaders/myAccountLoader';
import { useAuth } from '../../providers/authProvider';
import { useBreadcrumbs } from '../../providers/breadcrumbProvider';
import { RouteDefinitions } from '../../routeDefinitions';

const BodyWrapper = styled('div')`
	display: block;
	width: 95%;
	max-width: 1240px;
	margin: 24px auto;
`;

function Account() {
	const { user, setToken } = useAuth();
	const { storeOrders } = useLoaderData() as MyAccountLoaderReturn;
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const { addBreadcrumb, removeBreadcrumb } = useBreadcrumbs();

	useEffect(() => {
		const breadcrumb = { name: 'My Account', path: RouteDefinitions.MY_ACCOUNT() };
		addBreadcrumb(breadcrumb);

		return () => {
			removeBreadcrumb(breadcrumb.path);
		};
	}, []);

	const handleLogout = () => {
		setToken(null);
		enqueueSnackbar('You have been signed out.', { variant: 'info' });
		navigate('/');
	};

	const columns: GridColDef[] = [
		{
			field: 'order_readable_column',
			headerName: 'Order #',
			type: 'number',
			width: 150,
			headerAlign: 'left',
			align: 'left'
		},
		{
			field: 'created_at',
			headerName: 'Order Date',
			type: 'date',
			width: 150,
			valueGetter: (value) => new Date(value.row.created_at)
		},
		{
			field: 'order_items',
			headerName: 'Items',
			type: 'string',
			flex: 1,
			sortable: false,
			filterable: false,
			renderCell: (params) => {
				return params.row.order_items.map((item: { id: React.Key | null | undefined; image_url: string | undefined }) => (
					<img width={32} alt={'item image'} key={item.id} src={item.image_url} />
				));
			}
		},
		{
			field: 'total',
			headerName: 'Total',
			type: 'number',
			flex: 1,
			headerAlign: 'right',
			align: 'right',
			valueGetter: (value) => formatCurrencyValueFromServer(value.row.total)
		},
		{
			field: 'status',
			headerName: 'Status',
			width: 120,
			headerAlign: 'center',
			align: 'center',
			cellClassName: (params) => `status-${params.row.status.replace(' ', '')}`
		}
	];

	const StripedDataGrid = styled(DataGrid)`
		.MuiDataGrid-row:nth-child(odd) {
			background-color: var(--grey);
		}

		img {
			border: 1px solid var(--separator);
			border-radius: 8px;
			margin-right: 6px;
		}

		.MuiDataGrid-footerContainer {
			border-top: none;
		}

		.MuiTablePagination-toolbar p {
			margin: 0;
		}

		.status-PAID .MuiDataGrid-cellContent {
			color: var(--green);
		}
		.status-INDESIGN .MuiDataGrid-cellContent {
			color: var(--secondary);
		}
		.status-EXPIRED .MuiDataGrid-cellContent {
			color: var(--red);
		}
		.status-PENDING .MuiDataGrid-cellContent {
			color: var(--gold);
		}
	`;

	return (
		<>
			<BreadcrumbConnectedWrapper />
			<BodyWrapper>
				<Grid container columnSpacing={2} rowSpacing={2}>
					<Grid item xs={12} textAlign={'right'}>
						<h3>{user?.name}</h3>
						<p>{user?.email}</p>
						<Button size={'small'} startIcon={<LogoutIcon />} onClick={handleLogout}>
							Sign Out
						</Button>
					</Grid>

					<Grid item xs={12} display={'flex'} flexDirection={'column'}>
						<h4>Recent Orders</h4>
						<StripedDataGrid
							rows={storeOrders}
							columns={columns}
							initialState={{
								sorting: {
									sortModel: [{ field: 'order_readable_column', sort: 'desc' }]
								},
								pagination: {
									paginationModel: {
										pageSize: 10
									}
								}
							}}
							pageSizeOptions={[10, 25, 50, 100]}
							disableRowSelectionOnClick
							sx={{
								display: 'flex',
								borderRadius: 4,
								marginY: 4
							}}
						/>
					</Grid>
				</Grid>
			</BodyWrapper>
		</>
	);
}

export default Account;
