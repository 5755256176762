import styled from '@emotion/styled';
import { Dialog, DialogContent } from '@mui/material';
import React, { ReactElement } from 'react';

import Button from '../Button';

interface SimpleConfirmationDialogProps {
	title: string;
	message: string;
	image?: string;
	primaryButtonText: string;
	secondaryButtonText: string;
	onPrimaryAction: () => void;
	onSecondaryAction: () => void;
	onClose: () => void;
}

const SimpleConfirmationDialog = ({
	title,
	message,
	image,
	primaryButtonText,
	secondaryButtonText,
	onPrimaryAction,
	onSecondaryAction,
	onClose
}: SimpleConfirmationDialogProps): ReactElement => {
	return (
		<StyledDialog open={true} onClose={onClose}>
			<StyledDialogContent>
				<DialogBody>
					<ImageAndContentContainer>
						<TextContent>
							<h4>{title}</h4>
							<p>{message}</p>
						</TextContent>
					</ImageAndContentContainer>
					{image && (
						<ImageContainer>
							<img src={image} alt={message} />
						</ImageContainer>
					)}
					<ActionContainer>
						<StyledButton color="green" onClick={onPrimaryAction}>
							{primaryButtonText}
						</StyledButton>
						<StyledButton color="grey" onClick={onSecondaryAction}>
							{secondaryButtonText}
						</StyledButton>
					</ActionContainer>
				</DialogBody>
			</StyledDialogContent>
		</StyledDialog>
	);
};

const StyledDialog = styled(Dialog)`
	.MuiDialog-paper {
		border-radius: 15px;
		max-width: 500px;
		padding: 20px;
	}
`;

const StyledDialogContent = styled(DialogContent)`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const DialogBody = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const ImageAndContentContainer = styled.div`
	text-align: center;
	align-items: center;
	margin-bottom: 20px;
	width: 100%;
`;

const ImageContainer = styled.div`
	flex-shrink: 0;
	margin-right: 20px;
	margin-left: auto;
	margin-right: auto;

	img {
		border-radius: 8px;
		max-width: 200px;
		max-height: 200px;
	}
`;

const TextContent = styled.div`
	flex-grow: 1;

	h4 {
		margin: 0;
		font-size: 24px;
		font-weight: 700;
		white-space: nowrap;
	}

	p {
		margin: 5px 0 0;
		font-size: 16px;
		color: #555;
		white-space: nowrap;
	}
`;

const ActionContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;
`;

const StyledButton = styled(Button)<{ color: string }>`
	background-color: ${({ color }) => (color === 'green' ? '#28a745' : '#dcdcdc')};
	color: ${({ color }) => (color === 'green' ? '#fff' : '#555')};
	border-radius: 20px;
	font-weight: bold;
	padding: 10px 20px;
	width: 100%;

	&:hover {
		background-color: ${({ color }) => (color === 'green' ? '#218838' : '#c0c0c0')};
	}
`;

export default SimpleConfirmationDialog;
