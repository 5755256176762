import React from 'react';

import Banner from './Banner';
import MenuBar from './Menu/MenuBar';

function Header() {
	return (
		<header>
			{/* <Banner /> */}
			<MenuBar />
		</header>
	);
}

export default Header;
