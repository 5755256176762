import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import BreadcrumbConnectedWrapper from '../../core/components/BreadcrumbConnectedWrapper';
import HeroContainer from '../../core/components/HeroContainer';
import { useBreadcrumbs } from '../../providers/breadcrumbProvider';
import { RouteDefinitions } from '../../routeDefinitions';
import { getImageUrl } from '../../utils/imageHelper';

function TeamApparelContainer() {
	const navigate = useNavigate();
	const { addBreadcrumb, removeBreadcrumb } = useBreadcrumbs();

	// const data = useLoaderData() as GetStoreContentsQuery;
	// const storeData = data.team_store[0];

	useEffect(() => {
		const breadcrumb = { name: 'Custom Team Apparel', path: RouteDefinitions.TEAM_APPAREL() };
		addBreadcrumb(breadcrumb);

		return () => {
			removeBreadcrumb(breadcrumb.path);
		};
	}, []);

	return (
		<>
			<HeroContainer
				heading={'Products'}
				label={'Save big on team orders!'}
				imageUrl={getImageUrl('Product_Page_Header.jpg')}
			/>
			<BreadcrumbConnectedWrapper />
			<Outlet />
		</>
	);
}

export default TeamApparelContainer;
