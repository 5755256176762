import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import React from 'react';

import { getImageUrl } from '../utils/imageHelper';
import ImageCredit from './ImageCredit';

const PartnerStoreSectionContainer = styled('section')`
	display: flex;
	flex-direction: column;
	width: 95%;
	max-width: 1240px;
	margin: auto;
	overflow: hidden;
	gap: 24px;
	align-items: center;
	justify-content: center;
	padding: 70px;
	@media only screen and (max-width: 900px) {
		padding: 24px;
	}
`;

type PanelProps = {
	src: string;
	credit?: string;
};

const Panel = styled('a')(
	(props: PanelProps) => `
        display: block;
        background-image: url("${props.src}");
        background-size: cover;
        height: 300px;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 12px;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        transition: transform 0.3s ease;

        @media only screen and (max-width: 900px) {
            height: 200px;
        }

        &:hover {
            text-decoration: none;
            transform: scale(1.03);
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0.6));
            z-index: 1;
        }
    `
);

const PanelContent = styled('div')`
	position: absolute;
	bottom: 20px;
	left: 20px;
	z-index: 2;
`;

const PanelTitle = styled('h3')`
	color: white;
	font-size: 1.5rem;
	margin: 0;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

function PartnerStoreSection() {
	return (
		<PartnerStoreSectionContainer className={'grey-box rounded'}>
			<h2>BROWSE BY PARTNER</h2>
			<Grid container rowSpacing={2} columnSpacing={2}>
				<Grid item xs={12} md={5}>
					<Panel
						src={getImageUrl('partner/discdiversity.jpg')}
						href={'https://teams.breakmark.com/group/collaborations/showcase/discdiversity'}
					>
						<PanelContent>
							<PanelTitle className={'typography-contrast'}>Disc Diversity</PanelTitle>
						</PanelContent>
					</Panel>
				</Grid>
				<Grid item xs={12} md={7}>
					<Panel src={getImageUrl('partner/pul.jpg')} href={'https://teams.breakmark.com/group/pul'}>
						<PanelContent>
							<PanelTitle className={'typography-contrast'}>Premier Ultimate League</PanelTitle>
						</PanelContent>
					</Panel>
				</Grid>

				<Grid item xs={12} md={7}>
					<Panel src={getImageUrl('partner/wul.jpg')} href={'https://teams.breakmark.com/group/wul'}>
						<PanelContent>
							<PanelTitle className={'typography-contrast'}>Western Ultimate League</PanelTitle>
						</PanelContent>
						<ImageCredit>Nathan Brodie</ImageCredit>
					</Panel>
				</Grid>
				<Grid item xs={12} md={5}>
					<Panel src={getImageUrl('partner/collab.jpg')} href={'https://teams.breakmark.com/group/collaborations'}>
						<PanelContent>
							<PanelTitle className={'typography-contrast'}>Collaboration Store</PanelTitle>
						</PanelContent>
					</Panel>
				</Grid>
			</Grid>
		</PartnerStoreSectionContainer>
	);
}

export default PartnerStoreSection;
