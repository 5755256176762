import styled from '@emotion/styled';
import { Breadcrumbs, Grid } from '@mui/material';
import React from 'react';
import { ChevronRight } from 'react-feather';
import { NavLink } from 'react-router-dom';

import { getImageUrl } from '../utils/imageHelper';

function About() {
	const BreadcrumbsWrapper = styled('div')`
		display: flex;
		flex-direction: column;
		width: 95%;
		max-width: 1240px;
		margin: auto;
	`;

	const Separator = styled('div')`
		width: 100%;
		border-bottom: 1px solid var(--separator);
		margin: 0 0 24px;
	`;

	const BodyWrapper = styled('div')`
		display: block;
		width: 95%;
		max-width: 1240px;
		margin: 24px auto;
	`;
	const BodyImage = styled('img')`
		display: block;
		width: 100%;
		border-radius: 20px;
	`;

	return (
		<>
			<BreadcrumbsWrapper>
				<Separator />
				<Breadcrumbs aria-label="breadcrumb" separator={<ChevronRight width={18} height={18} />}>
					<NavLink to={'/'}>
						<p className={'no-margin'}>Home</p>
					</NavLink>
					<NavLink to={'/About'}>
						<p className={'no-margin'}>
							<strong>About</strong>
						</p>
					</NavLink>
				</Breadcrumbs>
			</BreadcrumbsWrapper>
			<BodyWrapper>
				<Grid container columnSpacing={2} rowSpacing={2}>
					<Grid item xs={12}>
						<h5>Our Story</h5>
					</Grid>

					<Grid item xs={12} md={8}>
						<p>
							Breakmark started to take shape when founder Mathew Goldblatt designed custom sweatshirts for his high
							school track team. The positive response sparked his passion for creating unique, high-quality
							apparel. In college, Mathew continued by designing for his ultimate frisbee team, and after seeing how
							much his teammates loved the gear, he realized there was potential to reach more teams. In 2005,
							Breakmark officially came to life, and ever since, we've been dedicated to helping teams stand out
							with personalized products that showcase their team spirit and identity.
						</p>
						<h5>Why "Breakmark"?</h5>
						<p>
							In ultimate frisbee, a "break mark" throw refers to bypassing a defender and creating new offensive
							opportunities. This concept resonates with our philosophy. At Breakmark, we believe in breaking
							through barriers and challenging the norm, much like a break mark changes the game on the field. We
							aim to do the same by delivering exceptional custom apparel.
						</p>

						<h5>Our Team</h5>
						<p>
							We’re proud to have a team spread across the U.S., with members in North Carolina, Massachusetts,
							Pennsylvania, Washington, Arkansas, and Minnesota. Our production facilities are located in
							Massachusetts, North Carolina, and Egypt, allowing us to combine global talent and resources to create
							the best customized products for our customers.
						</p>
						<h5>Partnership over Sponsorship</h5>
						<p>
							At Breakmark, we believe in building partnerships rather than offering traditional sponsorships. The
							term "sponsorship" often leads to expectations of free products, while our focus is on true
							collaboration. By forming partnerships, we work together to support each other’s growth, with no
							contracts needed. Our goal is to create long-term, mutually beneficial relationships.
						</p>
					</Grid>

					<Grid item xs={12} md={4}>
						<BodyImage src={getImageUrl('about.jpg')} alt={'Quadball'} />
					</Grid>
				</Grid>
			</BodyWrapper>
		</>
	);
}

export default About;
