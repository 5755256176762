import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import React from 'react';
interface FilterOption {
	label: string;
	value: string;
}

interface FilterCategory {
	name: string;
	options: FilterOption[];
}

interface SideNavFilterProps {
	categories: FilterCategory[];
	filters: Record<string, Set<string>>;
	onFilterChange: (category: string, option: string, checked: boolean) => void;
}
const SideNavFilter: React.FC<SideNavFilterProps> = ({ categories, filters, onFilterChange }) => {
	return (
		<>
			{categories.map((category) => (
				<Accordion key={category.name} defaultExpanded={true}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography>{category.name}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<FormGroup>
							{category.options.map((option) => (
								<FormControlLabel
									key={option.value}
									control={
										<Checkbox
											checked={filters[category.name]?.has(option.value) || false}
											onChange={(e) => onFilterChange(category.name, option.value, e.target.checked)}
										/>
									}
									label={option.label}
								/>
							))}
						</FormGroup>
					</AccordionDetails>
				</Accordion>
			))}
		</>
	);
};

export default SideNavFilter;
