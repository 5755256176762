import React, { ReactElement, ReactNode } from 'react';

interface Props {
	children?: ReactNode;
}
const DNF404 = ({ children }: Props): ReactElement => {
	return (
		<div className={'padded centered'}>
			<img src={'/images/SadBrowser.png'} width={300} alt={'Sad Browser'} />
			<div>
				<h3>Page Not Found</h3>
				<p>We can't find the page you're looking for</p>
				<p>
					<a href={'/'}>Return Home</a>
				</p>
				{children}
			</div>
		</div>
	);
};

export default DNF404;
