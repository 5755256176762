import styled from '@emotion/styled';
import { Breadcrumbs, Grid } from '@mui/material';
import React from 'react';
import { ChevronRight } from 'react-feather';
import { NavLink } from 'react-router-dom';

import ProductPreview, { Product } from '../components/ProductPreview';
import BodyWrapper from '../core/components/BodyWrapper';

function Team() {
	const products: Product[] = [
		{
			id: '123',
			image: '/images/products/RedWhiteJersey.png',
			name: 'Red White Jersey',
			stars: 4.0,
			basePrice: 65
		},
		{
			id: '456',
			image: '/images/products/RedBucketHat.png',
			name: 'Red Red Bucket Hat',
			stars: 3.5,
			basePrice: 40
		},

		{
			id: '123',
			image: '/images/products/allstarbundle.png',
			name: 'All Star Bundle',
			stars: 4.5,
			basePrice: 75
		},
		{
			id: '456',
			image: '/images/products/epicbundle.png',
			name: 'Epic Bundle',
			stars: 3.5,
			basePrice: 95
		},
		{
			id: '789',
			image: '/images/products/barginbintshirt.png',
			name: 'Bargin Bin Short Sleeve*',
			stars: 4.5,
			basePrice: 20
		}
	];

	const BreadcrumbsWrapper = styled('div')`
		display: flex;
		flex-direction: column;
		width: 95%;
		max-width: 1240px;
		margin: auto;
	`;

	const Separator = styled('div')`
		width: 100%;
		border-bottom: 1px solid var(--separator);
		margin: 0 0 24px;
	`;

	type PanelProps = {
		src: string;
	};
	const HeaderPanel = styled('div')(
		(props: PanelProps) => `
        display: block;
        background-color: var(--black);
        background-image: url("${props.src}");
        background-size: cover;
        height: 250px;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 20px;
        box-sizing: border-box;
        margin-bottom: 24px;
        @media only screen and (max-width: 900px) {
            height: 190px;
            background-size: contain;
        }
        &:hover {
            text-decoration: none;
        }
    `
	);

	const SectionLogo = styled('div')(
		(props: PanelProps) => `
        display: block;
        background-color: var(--black);
        background-image: url("${props.src}");
        background-size: 90%;
        height: 250px;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 20px;
        box-sizing: border-box;
        margin-bottom: 24px;
        @media only screen and (max-width: 900px) {
            height: 190px;
            background-size: contain;
        }
        &:hover {
            text-decoration: none;
        }
    `
	);

	return (
		<>
			<BreadcrumbsWrapper>
				<Separator />
				<Breadcrumbs aria-label="breadcrumb" separator={<ChevronRight width={18} height={18} />}>
					<NavLink to={'/'}>
						<p className={'no-margin'}>Home</p>
					</NavLink>
					<NavLink to={'/Group/pul'}>
						<p className={'no-margin'}>
							<strong>Premiere Ultimate League</strong>
						</p>
					</NavLink>
					<NavLink to={'/Group/pul/indyred'}>
						<p className={'no-margin'}>
							<strong>Indy Red</strong>
						</p>
					</NavLink>
				</Breadcrumbs>
			</BreadcrumbsWrapper>
			<BodyWrapper>
				<Grid container columnSpacing={2} rowSpacing={2}>
					<Grid item xs={12} sm={6} md={3}>
						<SectionLogo src={'https://assets.breakmark.com/prod/BA_fl0uSx.png'} />
					</Grid>
					<Grid item xs={12} sm={6} md={9}>
						<HeaderPanel src={'https://assets.breakmark.com/prod/kRQ5E1423.jpg'} />
					</Grid>
				</Grid>
				<Grid container rowSpacing={2} columnSpacing={2}>
					{products.map((p) => (
						<Grid key={p.id} item xs={6} md={3}>
							<ProductPreview {...p} />
						</Grid>
					))}
				</Grid>
			</BodyWrapper>
		</>
	);
}

export default Team;
