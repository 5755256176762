import styled from '@emotion/styled';
import React from 'react';

import { formatCurrencyValueFromServer } from '../../core/utilities/currency';
import { BundleProductFragment } from '../../generated/graphql';

type Props = {
	product: BundleProductFragment;
};
const PriceBlock = styled('div')`
	display: flex;
	gap: 8px;
	margin-left: 8px;
`;

const ProductPrice = styled('h5')`
	color: var(--typography-base);
`;

const BundleProductPrice = ({ product }: Props) => {
	return (
		<PriceBlock>
			<ProductPrice>{formatCurrencyValueFromServer(product.base_price || 0)}</ProductPrice>
		</PriceBlock>
	);
};

export default BundleProductPrice;
