import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';

import ProductPreview, { Product } from '../../components/ProductPreview';
import { GetPartnerProductsDocument } from '../../generated/graphql';

type ProductListProps = {
	teams: string[];
	filters: string[];
};

function ProductList({ teams, filters }: ProductListProps) {
	const [products, setProducts] = useState<Product[]>([]);
	const { data, loading } = useQuery(GetPartnerProductsDocument, {
		variables: { stores: teams }
	});

	useEffect(() => {
		const pros = data?.store_product.map(
			(p) =>
				({
					id: p.id,
					image: p.display_photo,
					name: p.label,
					basePrice: p.product.base_price,
					customPriceOverride: p.custom_price_override
				}) as Product
		);
		if (pros) {
			setProducts(pros);
		}
	}, [data]);

	const Wrapper = styled('div')`
		display: flex;
		width: 100%;
		max-width: 1240px;
		margin: auto;
		padding: 10px;
		gap: 32px;
	`;

	return (
		<Wrapper>
			<Grid container rowSpacing={2} columnSpacing={2}>
				{products.map((p) => (
					<Grid key={p.id} item xs={6} md={3}>
						<ProductPreview {...p} />
					</Grid>
				))}
			</Grid>
		</Wrapper>
	);
}

export default ProductList;
