import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const Wrapper = styled('div')`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 1240px;
	margin: auto;
	padding: 10px;
`;

const Table = styled('table')`
	width: 100%;
	border: 1px solid var(--separator);
	border-radius: 8px;

	th {
		background-color: var(--grey);
		padding: 10px;
		text-align: center;
	}
`;

type CellProps = {
	sizing?: string;
	activeSizing: string;
};
const Cell = styled('td')<CellProps>`
	padding: 10px;
	text-align: center;
	cursor: pointer;
	background-color: ${(props) => (props.sizing === props.activeSizing ? 'var(--primary-base)' : '')};
	&:hover {
		background-color: var(--primary-base-hover);
		text-decoration: none;
	}

	a {
		:hover {
			text-decoration: none;
		}
	}
`;

function DesignSizing() {
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const [activeSizing, setActiveSizing] = React.useState<string>(searchParams.get('type') || 'mensShortSleeve');

	useEffect(() => {
		const type = searchParams.get('type');
		if (type && SizeImages[type]) {
			setActiveSizing(type);
		} else if (!type) {
			setSearchParams({ type: 'mensShortSleeve' });
		}
	}, [searchParams, setSearchParams]);

	const handleSizingClick = (sizing: string) => {
		setActiveSizing(sizing);
		setSearchParams({ type: sizing });
	};

	const SizeImages: { [key: string]: any } = {
		mensShortSleeve: {
			image: '/images/sizing/short-sleeve.webp',
			chart: '/images/sizing/short-sleeve-mens.webp'
		},
		mensLongSleeve: {
			image: '/images/sizing/long-sleeve.png',
			chart: '/images/sizing/long-sleeve-mens.webp'
		},
		mensLongSleeveHooded: {
			image: '/images/sizing/long-sleeve-hooded.webp',
			chart: '/images/sizing/long-sleeve-hooded-mens.webp'
		},
		mensTankTop: {
			image: '/images/sizing/tank.webp',
			chart: '/images/sizing/tank-mens.webp'
		},
		mensTankReversible: {
			image: '/images/sizing/tank-reversible.webp',
			chart: '/images/sizing/tank-reversible-mens.webp'
		},
		womensShortSleeve: {
			image: '/images/sizing/short-sleeve.webp',
			chart: '/images/sizing/short-sleeve-womens.webp'
		},
		womensLongSleeve: {
			image: '/images/sizing/long-sleeve.png',
			chart: '/images/sizing/long-sleeve-womens.webp'
		},
		womensLongSleeveHooded: {
			image: '/images/sizing/long-sleeve-hooded.webp',
			chart: '/images/sizing/long-sleeve-hooded-womens.webp'
		},
		womensTankTop: {
			image: '/images/sizing/tank.webp',
			chart: '/images/sizing/tank-womens.webp'
		},
		womensTankReversible: {
			image: '/images/sizing/tank-reversible.webp',
			chart: '/images/sizing/tank-reversible-womens.webp'
		},
		youthShortSleeve: {
			image: '/images/sizing/short-sleeve.webp',
			chart: '/images/sizing/short-sleeve-youth.webp'
		},
		youthLongSleeve: {
			image: '/images/sizing/long-sleeve.png',
			chart: '/images/sizing/long-sleeve-youth.webp'
		},
		youthLongSleeveHooded: {
			image: '/images/sizing/long-sleeve-hooded.webp',
			chart: '/images/sizing/long-sleeve-hooded-youth.webp'
		},
		youthTankTop: {
			image: '/images/sizing/tank.webp',
			chart: '/images/sizing/tank-youth.webp'
		},
		youthTankReversible: {
			image: '/images/sizing/tank-reversible.webp',
			chart: '/images/sizing/tank-reversible-youth.webp'
		},
		shorts5inch: {
			image: '/images/sizing/shorts.webp',
			chart: '/images/sizing/shorts-5inch.png'
		},
		shorts8inch: {
			image: '/images/sizing/shorts.webp',
			chart: '/images/sizing/shorts-8inch.webp'
		},
		shortsWomen: {
			image: '/images/sizing/shorts.webp',
			chart: '/images/sizing/shorts-womens.png'
		},
		shortsYouth8inch: {
			image: '/images/sizing/shorts.webp',
			chart: '/images/sizing/shorts-youth-8inch.webp'
		},
		socks: {
			image: '',
			chart: '/images/sizing/socks.webp'
		}
	};

	const ImageContainer = styled('div')`
		width: 50%;
		aspect-ratio: 1 / 1;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;

		@media (max-width: 768px) {
			width: 100%;
			margin-bottom: 20px;
		}
	`;

	const StyledImage = styled('img')`
		max-width: 100%;
		max-height: 100%;
		object-fit: contain;
	`;

	const ImageWrapper = styled('div')`
		display: flex;
		flex-direction: row;

		@media (max-width: 768px) {
			flex-direction: column;
		}
	`;

	return (
		<Wrapper>
			<div style={{ padding: '20px', textAlign: 'end' }}>
				<a href={'/images/sizing/SizeChartsAll.pdf'} target="_blank" rel="noopener noreferrer">
					Download SizeChartsAll.pdf
				</a>
			</div>
			<Table>
				<thead>
					<tr>
						<th></th>
						<th>Short Sleeve</th>
						<th>Long Sleeve</th>
						<th>Long Sleeve Hooded</th>
						<th>Tank Top</th>
						<th>Tank Reversible</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th>Mens</th>
						<Cell
							sizing={'mensShortSleeve'}
							activeSizing={activeSizing}
							onClick={() => handleSizingClick('mensShortSleeve')}
						>
							<a>view</a>
						</Cell>
						<Cell
							sizing={'mensLongSleeve'}
							activeSizing={activeSizing}
							onClick={() => handleSizingClick('mensLongSleeve')}
						>
							<a>view</a>
						</Cell>
						<Cell
							sizing={'mensLongSleeveHooded'}
							activeSizing={activeSizing}
							onClick={() => handleSizingClick('mensLongSleeveHooded')}
						>
							<a>view</a>
						</Cell>
						<Cell sizing={'mensTankTop'} activeSizing={activeSizing} onClick={() => handleSizingClick('mensTankTop')}>
							<a>view</a>
						</Cell>
						<Cell
							sizing={'mensTankReversible'}
							activeSizing={activeSizing}
							onClick={() => handleSizingClick('mensTankReversible')}
						>
							<a>view</a>
						</Cell>
					</tr>
					<tr>
						<th>Womens</th>
						<Cell
							sizing={'womensShortSleeve'}
							activeSizing={activeSizing}
							onClick={() => handleSizingClick('womensShortSleeve')}
						>
							<a>view</a>
						</Cell>
						<Cell
							sizing={'womensLongSleeve'}
							activeSizing={activeSizing}
							onClick={() => handleSizingClick('womensLongSleeve')}
						>
							<a>view</a>
						</Cell>
						<Cell
							sizing={'womensLongSleeveHooded'}
							activeSizing={activeSizing}
							onClick={() => handleSizingClick('womensLongSleeveHooded')}
						>
							<a>view</a>
						</Cell>
						<Cell
							sizing={'womensTankTop'}
							activeSizing={activeSizing}
							onClick={() => handleSizingClick('womensTankTop')}
						>
							<a>view</a>
						</Cell>
						<Cell
							sizing={'womensTankReversible'}
							activeSizing={activeSizing}
							onClick={() => handleSizingClick('womensTankReversible')}
						>
							<a>view</a>
						</Cell>
					</tr>
					<tr>
						<th>Youth</th>
						<Cell
							sizing={'youthShortSleeve'}
							activeSizing={activeSizing}
							onClick={() => handleSizingClick('youthShortSleeve')}
						>
							<a>view</a>
						</Cell>
						<Cell
							sizing={'youthLongSleeve'}
							activeSizing={activeSizing}
							onClick={() => handleSizingClick('youthLongSleeve')}
						>
							<a>view</a>
						</Cell>
						<Cell
							sizing={'youthLongSleeveHooded'}
							activeSizing={activeSizing}
							onClick={() => handleSizingClick('youthLongSleeveHooded')}
						>
							<a>view</a>
						</Cell>
						<Cell
							sizing={'youthTankTop'}
							activeSizing={activeSizing}
							onClick={() => handleSizingClick('youthTankTop')}
						>
							<a>view</a>
						</Cell>
						<Cell
							sizing={'youthTankReversible'}
							activeSizing={activeSizing}
							onClick={() => handleSizingClick('youthTankReversible')}
						>
							<a>view</a>
						</Cell>
					</tr>
				</tbody>
				<thead>
					<tr>
						<th></th>
						<th>Shorts 8"</th>
						<th>Shorts 5"</th>
						<th>Shorts Women</th>
						<th>Shorts Youth 8"</th>
						<th>Socks</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th></th>
						<Cell sizing={'shorts8inch'} activeSizing={activeSizing} onClick={() => handleSizingClick('shorts8inch')}>
							<a>view</a>
						</Cell>
						<Cell sizing={'shorts5inch'} activeSizing={activeSizing} onClick={() => handleSizingClick('shorts5inch')}>
							<a>view</a>
						</Cell>
						<Cell sizing={'shortsWomen'} activeSizing={activeSizing} onClick={() => handleSizingClick('shortsWomen')}>
							<a>view</a>
						</Cell>
						<Cell
							sizing={'shortsYouth8inch'}
							activeSizing={activeSizing}
							onClick={() => handleSizingClick('shortsYouth8inch')}
						>
							<a>view</a>
						</Cell>
						<Cell sizing={'socks'} activeSizing={activeSizing} onClick={() => handleSizingClick('socks')}>
							<a>view</a>
						</Cell>
					</tr>
				</tbody>
			</Table>
			<ImageWrapper>
				<ImageContainer>
					<StyledImage src={SizeImages[activeSizing].image} alt="Product" />
				</ImageContainer>
				<ImageContainer>
					<StyledImage src={SizeImages[activeSizing].chart} alt="Size Chart" />
				</ImageContainer>
			</ImageWrapper>
		</Wrapper>
	);
}

export default DesignSizing;
