import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Breadcrumbs,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Grid
} from '@mui/material';
import React, { useEffect } from 'react';
import { ChevronDown, ChevronRight } from 'react-feather';
import { NavLink } from 'react-router-dom';

import SearchBox from '../../components/SearchBox';
import { GetStoresByActivityDocument } from '../../generated/graphql';
import ActivityPicker from './ActivityPicker';
import ProductList from './ProductList';

function PartnersSearch() {
	const [activity, setActivity] = React.useState('Ultimate');
	const [groups, setGroups] = React.useState<string[]>([]);
	const [teams, setTeams] = React.useState<string[]>([]);
	const [searchSets, setSearchSets] = React.useState<any[]>([]);
	const [filters, setFilters] = React.useState<string[]>([]);
	const { data, loading } = useQuery(GetStoresByActivityDocument, {
		variables: { activity: activity }
	});

	useEffect(() => {
		console.log('useEffect', data, groups);
		if (data && data.team_store && data.team_store.length > 0) {
			setGroups(data.team_store[0].id);
		}
	}, [data, setGroups]);

	useEffect(() => {
		console.log('groups', groups);
	}, [groups]);

	useEffect(() => {
		console.log('teams', teams);
		setSearchSets([]);
		data?.team_store.forEach((ts) => {
			if (groups.includes(ts.id)) {
				ts.store_is_showcasing_stores.forEach((s) => {
					if (teams.includes(s.id)) {
						setSearchSets((ss) => ss.concat(s.search_sets));
					}
				});
			}
		});
	}, [teams]);

	const BreadcrumbsWrapper = styled('div')`
		display: flex;
		flex-direction: column;
		width: 95%;
		max-width: 1240px;
		margin: auto;
	`;

	const Separator = styled('div')`
		width: 100%;
		border-bottom: 1px solid var(--separator);
		margin: 0 0 24px;
	`;

	const BodyWrapper = styled('div')`
		display: block;
		width: 95%;
		max-width: 1240px;
		margin: 24px auto;
	`;

	const handleGroupsChange = (event: any, checked: boolean) => {
		if (checked) {
			setGroups([...groups, event.target.value]);
		} else {
			const g = [...groups];
			g.splice(g.indexOf(event.target.value), 1);
			setGroups(g);
		}
	};

	const handleTeamsChange = (event: any, checked: boolean) => {
		if (checked) {
			setTeams([...teams, event.target.value]);
		} else {
			const t = [...teams];
			t.splice(t.indexOf(event.target.value), 1);
			setTeams(t);
		}
	};

	const handleFiltersChange = (event: any, checked: boolean) => {
		console.log('handleFiltersChange', event.target.value, checked);
		if (checked) {
			setFilters([...filters, event.target.value]);
		} else {
			const f = [...filters];
			f.splice(f.indexOf(event.target.value), 1);
			setFilters(f);
		}
	};

	return (
		<>
			<BreadcrumbsWrapper>
				<Separator />
				<Breadcrumbs aria-label="breadcrumb" separator={<ChevronRight width={18} height={18} />}>
					<NavLink to={'/'}>
						<p className={'no-margin'}>Home</p>
					</NavLink>
					<NavLink to={'/Partners'}>
						<p className={'no-margin'}>
							<strong>Partners</strong>
						</p>
					</NavLink>
				</Breadcrumbs>
			</BreadcrumbsWrapper>
			<BodyWrapper>
				<Grid container columnSpacing={2} rowSpacing={2}>
					<Grid item xs={12}>
						<ActivityPicker activity={activity} onChange={(a) => setActivity(a)} />
						<SearchBox />
					</Grid>

					{loading ? (
						<Grid item xs={3}>
							loading
						</Grid>
					) : (
						<Grid item xs={3}>
							<Accordion elevation={0} defaultExpanded={true}>
								<AccordionSummary expandIcon={<ChevronDown />}>
									<p className={'no-margin'}>Groups</p>
								</AccordionSummary>
								<AccordionDetails>
									<FormGroup>
										{data?.team_store.map((ts) => (
											<FormControlLabel
												key={ts.id}
												control={
													<Checkbox
														size={'small'}
														checked={groups.includes(ts.id)}
														value={ts.id}
														onChange={handleGroupsChange}
													/>
												}
												label={<small>{ts.name}</small>}
											/>
										))}
									</FormGroup>
								</AccordionDetails>
							</Accordion>

							<Accordion elevation={0} defaultExpanded={true}>
								<AccordionSummary expandIcon={<ChevronDown />}>
									<p className={'no-margin'}>Teams</p>
								</AccordionSummary>
								<AccordionDetails>
									<FormGroup>
										{data?.team_store.map((ts) => {
											if (groups.includes(ts.id)) {
												return ts.store_is_showcasing_stores.map((s) => (
													<FormControlLabel
														key={s.id}
														control={
															<Checkbox
																size={'small'}
																checked={teams.includes(s.id)}
																value={s.id}
																onChange={handleTeamsChange}
															/>
														}
														label={<small>{s.label}</small>}
													/>
												));
											}
										})}
									</FormGroup>
								</AccordionDetails>
							</Accordion>

							<Accordion elevation={0} defaultExpanded={true}>
								<AccordionSummary expandIcon={<ChevronDown />}>
									<p className={'no-margin'}>Products</p>
								</AccordionSummary>
								<AccordionDetails>
									<FormGroup>
										{searchSets.map((ss, indx) => (
											<FormControlLabel
												key={ss.label}
												control={
													<Checkbox
														size={'small'}
														checked={filters.includes(indx.toString())}
														value={indx.toString()}
														onChange={handleFiltersChange}
													/>
												}
												label={<small>{ss.label}</small>}
											/>
										))}
									</FormGroup>
								</AccordionDetails>
							</Accordion>
						</Grid>
					)}

					<Grid item xs={9}>
						<ProductList teams={teams} filters={filters} />
					</Grid>
				</Grid>
			</BodyWrapper>
		</>
	);
}

export default PartnersSearch;
