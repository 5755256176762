/* eslint-disable no-ex-assign */
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import { useMutation } from '@apollo/client';
import {
	Alert,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Snackbar,
	TextField
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import ReCAPTCHA from 'react-google-recaptcha';

import { CreateNewLeadDocument } from '../../generated/graphql';
import { findEnvAttr } from '../../utils/process';

registerPlugin(
	FilePondPluginImageExifOrientation,
	FilePondPluginImagePreview,
	FilePondPluginFileValidateType,
	FilePondPluginFileValidateSize
);

interface TeamSalesDialogProps {
	open: boolean;
	onClose: () => void;
	onSubmit: (formData: TeamSalesFormData) => void;
	productId?: string; // New prop for product ID
}

export interface TeamSalesFormData {
	firstName: string;
	lastName: string;
	phone: string;
	email: string;
	teamAffiliation: string;
	needByDate: Date | null;
	designFile1: File | null;
	designFile2: File | null;
	productId?: string; // New field for product ID
	comments: string; // New field for comments
}

const uploadFile = async (signedUrl: string, file: File) => {
	await fetch(signedUrl, {
		method: 'PUT',
		body: file,
		headers: {
			'Content-Type': file.type
		}
	});
};

const TeamSalesDialog: React.FC<TeamSalesDialogProps> = ({ open, onClose, productId }) => {
	const [formData, setFormData] = useState<TeamSalesFormData>({
		firstName: '',
		lastName: '',
		phone: '',
		email: '',
		teamAffiliation: '',
		needByDate: null,
		designFile1: null,
		designFile2: null,
		productId: productId,
		comments: '' // Initialize comments as an empty string
	});
	const captchaRef = useRef<ReCAPTCHA>(null);

	const [createNewLead, { loading }] = useMutation(CreateNewLeadDocument);
	const { enqueueSnackbar } = useSnackbar();

	const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | Date | null, fieldName?: string) => {
		if (event instanceof Date || event === null) {
			setFormData((prevData) => ({ ...prevData, needByDate: event }));
		} else if (typeof event === 'object' && 'target' in event) {
			const { name, value } = event.target;
			setFormData((prevData) => ({ ...prevData, [name]: value }));
		}
	};

	const handleFileUpload = (fieldName: 'designFile1' | 'designFile2') => (files: any) => {
		setFormData((prevData) => ({ ...prevData, [fieldName]: files[0]?.file || null }));
	};

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		const token = await captchaRef.current?.executeAsync();
		captchaRef.current?.reset();

		const hasFiles = formData.designFile1 || formData.designFile2;

		// Extract file name and type for designFile1
		let designFile1Name = '';
		let designFile1Type = '';
		if (formData.designFile1) {
			designFile1Name = formData.designFile1.name;
			designFile1Type = formData.designFile1.type;
		}

		// Extract file name and type for designFile2
		let designFile2Name = '';
		let designFile2Type = '';
		if (formData.designFile2) {
			designFile2Name = formData.designFile2.name;
			designFile2Type = formData.designFile2.type;
		}

		try {
			const { data } = await createNewLead({
				variables: {
					leadInput: {
						product_id: productId!,
						captcha_token: token || '',
						email: formData.email,
						first_name: formData.firstName,
						last_name: formData.lastName,
						need_by_date: formData.needByDate ? formData.needByDate.toISOString().split('T')[0] : '',
						team_affiliation: formData.teamAffiliation,
						comments: formData.comments,
						// Add the new file information to the leadInput
						design_file_name_1: designFile1Name,
						design_file_type_1: designFile1Type,
						design_file_name_2: designFile2Name,
						design_file_type_2: designFile2Type
					}
				}
			});
			// eslint-disable-next-line prefer-const
			let uploadError = false;
			console.log('hasFiles', hasFiles);
			console.log('data', data?.createNewLead);
			console.log('formData', formData.designFile1);
			console.log('formData2', formData.designFile2);

			if (hasFiles) {
				if (
					data?.createNewLead?.design_file_signed_url &&
					data?.createNewLead?.design_file_signed_url !== '' &&
					formData.designFile1
				) {
					try {
						await uploadFile(data.createNewLead.design_file_signed_url, formData.designFile1);
					} catch (uploadError) {
						console.error('Error uploading files:', uploadError);
						enqueueSnackbar('Error uploading files. Please try again.', { variant: 'error' });
						uploadError = true;
					}
				}
				if (
					data?.createNewLead?.design_file_two_signed_url &&
					data?.createNewLead?.design_file_two_signed_url !== '' &&
					formData.designFile2
				) {
					try {
						await uploadFile(data.createNewLead.design_file_two_signed_url, formData.designFile2);
					} catch (uploadError) {
						console.error('Error uploading files:', uploadError);
						enqueueSnackbar('Error uploading files. Please try again.', { variant: 'error' });
						uploadError = true;
					}
				}
			}

			if (!uploadError) {
				enqueueSnackbar('Team sales inquiry submitted successfully!', { variant: 'success' });
			}
			onClose();
		} catch (err) {
			console.error('Error creating lead:', err);
			enqueueSnackbar('Error submitting inquiry. Please try again.', { variant: 'error' });
		}
	};

	// New function to check if all required fields are filled
	const isFormValid = (): boolean => {
		return (
			formData.firstName.trim() !== '' &&
			formData.lastName.trim() !== '' &&
			formData.email.trim() !== '' &&
			formData.teamAffiliation.trim() !== ''
		);
	};

	return (
		<>
			<Dialog open={open} onClose={onClose}>
				<DialogTitle>Team Sales Inquiry</DialogTitle>
				<form onSubmit={handleSubmit}>
					<DialogContent>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<TextField
									required
									fullWidth
									label="First Name"
									name="firstName"
									value={formData.firstName}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									required
									fullWidth
									label="Last Name"
									name="lastName"
									value={formData.lastName}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField fullWidth label="Phone" name="phone" value={formData.phone} onChange={handleChange} />
							</Grid>
							<Grid item xs={12}>
								<TextField
									required
									fullWidth
									type="email"
									label="Email"
									name="email"
									value={formData.email}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									required
									fullWidth
									label="Team/League Affiliation"
									name="teamAffiliation"
									value={formData.teamAffiliation}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12}>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<DatePicker
										label="When do you need gear by?"
										value={formData.needByDate}
										onChange={(newValue) => handleChange(newValue, 'needByDate')}
										slotProps={{
											textField: {
												fullWidth: true
											}
										}}
									/>
								</LocalizationProvider>
							</Grid>
							<Grid item xs={12}>
								<FilePond
									maxFileSize={'5MB'}
									files={formData.designFile1 ? [formData.designFile1] : []}
									onupdatefiles={handleFileUpload('designFile1')}
									allowMultiple={false}
									labelIdle='Drag & Drop your design or <span class="filepond--label-action">Browse</span>'
									acceptedFileTypes={['image/*', 'application/pdf']}
								/>
							</Grid>
							<Grid item xs={12}>
								<FilePond
									maxFileSize={'5MB'}
									files={formData.designFile2 ? [formData.designFile2] : []}
									onupdatefiles={handleFileUpload('designFile2')}
									allowMultiple={false}
									labelIdle='Drag & Drop your second design or <span class="filepond--label-action">Browse</span>'
									acceptedFileTypes={['image/*', 'application/pdf']}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									multiline
									rows={4}
									label="Comments"
									name="comments"
									value={formData.comments}
									onChange={handleChange}
									placeholder="Any additional information or special requests?"
								/>
							</Grid>
							<ReCAPTCHA
								sitekey={findEnvAttr('REACT_APP_GOOGLE_CAPTCHA_PUBLIC_KEY')}
								size="invisible"
								ref={captchaRef}
							/>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={onClose} disabled={loading}>
							Cancel
						</Button>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							disabled={!isFormValid() || loading}
							startIcon={loading ? <CircularProgress size={20} /> : null}
						>
							{loading ? 'Submitting...' : 'Submit'}
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</>
	);
};

export default TeamSalesDialog;
