import styled from '@emotion/styled';
import React, { ReactNode } from 'react';

interface ButtonBaseProps {
	padding?: 'small' | 'medium';
	fullWidth?: boolean;
	onClick?: () => void;
	useColorForBorder?: boolean;
	children: ReactNode;
	disabled?: boolean;
	className?: string;
	startIcon?: ReactNode;
	disableHover?: boolean;
}

interface ButtonProps extends ButtonBaseProps {
	color?: 'black' | 'grey' | 'white' | 'green' | 'red' | 'gold';
	variant?: 'primary' | 'secondary' | 'tertiary';
}

const ButtonBaseStyled = styled('button')<ButtonBaseProps>`
	display: ${({ fullWidth }) => (fullWidth ? 'flex' : 'inline-flex')};
	gap: 8px;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: ${({ padding }) => (padding === 'small' ? '12px 24px' : '15px 48px')};
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	font-weight: 600;
	border-radius: 16px;
	transition:
		opacity 0.3s ease,
		background-color 0.3s ease;
	opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};

	.icon-wrapper {
		display: flex;
		align-items: center;
		svg {
			font-size: 1.5rem;
		}
	}
`;

const ButtonStyled = styled(ButtonBaseStyled)<ButtonProps>`
	border: 1px solid ${({ useColorForBorder, color }) => (useColorForBorder ? `var(--${color})` : 'var(--separator)')};
	background-color: var(--${({ color }) => color || 'transparent'});
	color: ${({ color }) => (color === 'black' || color === 'green' ? 'var(--typography-contrast)' : 'var(--typography-base)')};

	&:hover {
		${({ disabled, disableHover, color }) =>
			!disableHover &&
			color &&
			`opacity: ${disabled ? 0.6 : 0.8};
       background-color: ${!disabled ? `var(--${color}-hover)` : 'inherit'};`}
	}

	&:active {
		${({ disabled, disableHover, color }) =>
			!disableHover &&
			color &&
			`opacity: ${disabled ? 0.6 : 1};
       background-color: ${!disabled ? `var(--${color}-press)` : 'inherit'};`}
	}
`;

const Button: React.FC<ButtonProps> = ({
	color = 'grey',
	padding = 'medium',
	onClick,
	fullWidth = false,
	useColorForBorder = false,
	children,
	disabled = false,
	className = '',
	startIcon,
	variant,
	disableHover = false,
	...rest
}) => {
	return (
		<ButtonStyled
			color={color}
			padding={padding}
			fullWidth={fullWidth}
			useColorForBorder={useColorForBorder}
			onClick={!disabled ? onClick : undefined}
			disabled={disabled}
			disableHover={disableHover}
			className={`${variant ? variant : ''} ${className}`}
			{...rest}
		>
			{startIcon && <span className="icon-wrapper">{startIcon}</span>}
			<span>{children}</span>
		</ButtonStyled>
	);
};

export default Button;

export const PrimaryButton = styled(ButtonStyled)`
	background-color: var(--primary-base);
	color: var(--typography-contrast);
	border: 1px solid var(--primary-base);

	&:hover {
		${({ disableHover }) =>
			!disableHover &&
			`
			background-color: var(--primary-base-hover);
			border-color: var(--primary-base-hover);
		`}
	}

	&:active {
		${({ disableHover }) =>
			!disableHover &&
			`
			background-color: var(--primary-base-press);
			border-color: var(--primary-base-press);
		`}
	}
`;

export const SecondaryButton = styled(ButtonStyled)`
	background-color: var(--secondary-base);
	color: var(--typography-contrast);
	border: 1px solid var(--secondary-base);

	&:hover {
		${({ disableHover }) =>
			!disableHover &&
			`
			background-color: var(--secondary-base-hover);
			border-color: var(--secondary-base-hover);
		`}
	}

	&:active {
		${({ disableHover }) =>
			!disableHover &&
			`
			background-color: var(--secondary-base-press);
			border-color: var(--secondary-base-press);
		`}
	}
`;

export const TertiaryButton = styled(ButtonStyled)`
	background-color: var(--tertiary-base);
	color: var(--typography-contrast);
	border: 1px solid var(--tertiary-base);

	&:hover {
		${({ disableHover }) =>
			!disableHover &&
			`
			background-color: var(--tertiary-base-hover);
			border-color: var(--tertiary-base-hover);
		`}
	}

	&:active {
		${({ disableHover }) =>
			!disableHover &&
			`
			background-color: var(--tertiary-base-press);
			border-color: var(--tertiary-base-press);
		`}
	}
`;
