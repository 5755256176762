import { getImageUrl } from '../../utils/imageHelper';

interface DesignInspiration {
	id: string;
	image_url: string;
}

const data: DesignInspiration[] = [
	{
		id: 'sd-image-90df6acd-c104-4d3e-a7ba-8c9b6ab98f13',
		image_url: getImageUrl('inspiration/jinx-dark-111-front.png')
	},
	{
		id: 'sd-image-65e7bead-c935-4ceb-af2f-bf4da05a2683',
		image_url: getImageUrl('inspiration/frisbeans-dark-111-front.png')
	},
	{
		id: 'sd-image-5e2301c5-b70a-4340-9170-28be0fb45124',
		image_url: getImageUrl('inspiration/rubberhuckies-dark-111-front-dark.png')
	},
	{
		id: 'sd-image-f16bcc50-e6b4-4293-ad41-c734ac6f0259',
		image_url: getImageUrl('inspiration/fgcu-dark-5p-hat-custom.png')
	},
	{
		id: 'sd-image-dfb542c0-dc6c-4701-9836-9d55e4144052',
		image_url: getImageUrl('inspiration/valleyultimate-dark-custom-5panel-angled.png')
	},
	{
		id: 'sd-image-e9b2321c-5625-463c-bc0b-e5417d8ff88c',
		image_url: getImageUrl('inspiration/dgs-light-5p-hat-custom-2.png')
	},
	{
		id: 'sd-image-ecdce5b9-d8e0-47eb-a9cb-2d9ad12c121e',
		image_url: getImageUrl('inspiration/dd-2022-night-custom.png')
	},
	{
		id: 'sd-image-6853ef82-0844-44c1-b461-340f108cd84b',
		image_url: getImageUrl('inspiration/alamode-light-111-front.png')
	},
	{
		id: 'sd-image-d2701314-5b9c-4074-861a-0abe68be5827',
		image_url: getImageUrl('inspiration/swarm-alt-113-front.png')
	},
	{
		id: 'sd-image-63c875ed-0745-4176-b658-2fc0e5add220',
		image_url: getImageUrl('inspiration/kc-discgolf-fans-111-front-dark.png')
	},
	{
		id: 'sd-image-dae8426b-70fd-43b1-9a02-ce12c4fc73fc',
		image_url: getImageUrl('inspiration/color-bomb-light-custom-5panel-angled.png')
	},
	{
		id: 'sd-image-2450e346-1cf9-4bd7-b940-a7ecaf7777b2',
		image_url: getImageUrl('inspiration/scarecrow-farmfresh-5panel-angled.png')
	},
	{
		id: 'sd-image-7892d4a0-4e88-45bb-bbb6-2273ba634765',
		image_url: getImageUrl('inspiration/pride-trans-5panel-angled.png')
	},
	{
		id: 'sd-image-378fbbb2-6e78-4dc8-b5f0-e8d554099700',
		image_url: getImageUrl('inspiration/scarecrow-veggies-5panel-angled.png')
	},
	{
		id: 'sd-image-a045b7b7-db9e-4f81-8e52-641d303e5bd5',
		image_url: getImageUrl('inspiration/qc2020-light-112-front.png')
	},
	{
		id: 'sd-image-2398ccbd-122d-422f-a83d-67db10a8a31b',
		image_url: getImageUrl('inspiration/nwchallenge20-lshood.png')
	},
	{
		id: 'sd-image-12d4451c-01a7-4186-9a5f-b676d0d5c2c2',
		image_url: getImageUrl('inspiration/lobsterpot21-sunrise-112-front.png')
	},
	{
		id: 'sd-image-20cf0238-c1dd-4a03-9775-1f0811cc8cf9',
		image_url: getImageUrl('inspiration/stars-5panel-angled.png')
	},
	{
		id: 'sd-image-a73aaa08-dd70-4b8c-a762-7f3140d05cbf',
		image_url: getImageUrl('inspiration/sevenhills-dark-colorblock-5panel-angled.png')
	},
	{
		id: 'sd-image-241f0783-be6f-4d54-8cd9-695caf3801b2',
		image_url: getImageUrl('inspiration/crownpeach-light-pattern-5panel-angled.png')
	},
	{
		id: 'sd-image-4343dc84-12a3-4354-b824-3b5b25adca41',
		image_url: getImageUrl('inspiration/haboob-5p-hat.png')
	},
	{
		id: 'sd-image-59d769f5-4470-441d-8d0c-efd429272a4d',
		image_url: getImageUrl('inspiration/charlotte-111-front-dark.png')
	},
	{
		id: 'sd-image-2da003a1-2fdd-4dc6-9069-d7d9adf3be44',
		image_url: getImageUrl('inspiration/latb-staff-113-front.png')
	},
	{
		id: 'sd-image-15dd964c-dd31-4f47-beaf-c31d573cbc3b',
		image_url: getImageUrl('inspiration/il-nightmare-light-111-front.png')
	},
	{
		id: 'sd-image-026b440a-7af9-41c0-b756-61652110d21b',
		image_url: getImageUrl('inspiration/spaceneedle-light-5panel-angled.png')
	},
	{
		id: 'sd-image-bc89886b-fe5b-4c28-9eae-f296454d9596',
		image_url: getImageUrl('inspiration/flood-zone-light-custom-5panel-angled.png')
	},
	{
		id: 'sd-image-027bafd9-a7bb-40a0-b0b6-7400c7219617',
		image_url: getImageUrl('inspiration/color-bomb-dark-pattern-5panel-angled.png')
	},
	{
		id: 'sd-image-dd2ce93e-b56b-4cec-9b01-f9c8ed7f05a8',
		image_url: getImageUrl('inspiration/ajan-light-5p-hat-custom.png')
	},
	{
		id: 'sd-image-752e59f0-ea3c-4de1-ae5c-f5dd00ee977a',
		image_url: getImageUrl('inspiration/mnstate2022-112-front-dark.png')
	},
	{
		id: 'sd-image-6e796942-6b30-4437-a48e-ab36252d15f1',
		image_url: getImageUrl('inspiration/springreign-mermaid-113.png')
	},
	{
		id: 'sd-image-a783e945-0275-41fb-a790-55db2ce13781',
		image_url: getImageUrl('inspiration/madison-united-dark-111-front.png')
	},
	{
		id: 'sd-image-02ea1dd4-002b-4e2c-892d-5b7d3bceef11',
		image_url: getImageUrl('inspiration/black-pearl-dark-pattern-5panel-angled.png')
	},
	{
		id: 'sd-image-3066426f-8fca-403a-98b4-16038a156f53',
		image_url: getImageUrl('inspiration/alamode-dark-custom-5panel-angled.png')
	},
	{
		id: 'sd-image-cb4b8132-be78-4eff-8600-d3b65a685219',
		image_url: getImageUrl('inspiration/fgcu-dark-5p-hat-pattern.png')
	},
	{
		id: 'sd-image-2df021b6-095c-489f-8bcd-8a3a7d7ddf81',
		image_url: getImageUrl('inspiration/il-nightmare-dark-5p-hat-custom.png')
	},
	{
		id: 'sd-image-6b7ca63b-951a-4f6a-b126-2cccc7fb4128',
		image_url: getImageUrl('inspiration/panic-alt-113-front.png')
	},
	{
		id: 'sd-image-5db2937d-ff2a-4d1e-8677-ebcf9999e4f7',
		image_url: getImageUrl('inspiration/uwec-sol-221-front-dark.png')
	},
	{
		id: 'sd-image-af4562e1-6057-4914-b582-70f5d50262dd',
		image_url: getImageUrl('inspiration/surge-dark-333-front.png')
	},
	{
		id: 'sd-image-96b75c7a-2199-4b84-9f17-f897138399b1',
		image_url: getImageUrl('inspiration/nightcap-dark-pattern-5panel-angled.png')
	},
	{
		id: 'sd-image-824485a0-5c8d-41c6-a5bc-8be6b4bf6c25',
		image_url: getImageUrl('inspiration/fgcu-light-5p-hat-pattern.png')
	},
	{
		id: 'sd-image-27fb641e-3016-425b-ab34-d0c06ec3776b',
		image_url: getImageUrl('inspiration/dd-combo-5panel-angled.png')
	},
	{
		id: 'sd-image-a9104c67-80e9-4d27-a7e0-30a833c4a5e7',
		image_url: getImageUrl('inspiration/discgolf-ak-5panel-angled.png')
	},
	{
		id: 'sd-image-5fe8f317-e78b-42dd-915f-f97086e40426',
		image_url: getImageUrl('inspiration/trexburg-dark-lshood-front.png')
	},
	{
		id: 'sd-image-696ff9e4-f4c1-4ac9-9a9b-5e0f7024ac7a',
		image_url: getImageUrl('inspiration/whitewater-light-112-front.png')
	},
	{
		id: 'sd-image-7354c32c-139d-4843-be99-c9ba8a663bf8',
		image_url: getImageUrl('inspiration/lochsa-dark-333-front.png')
	}
	// ... more items
];

export default data;
