import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import React from 'react';
import * as Icons from 'react-feather';
import { useNavigate } from 'react-router-dom';

import { menuItems } from '../../config/menuItems';

interface MobileMenuProps {
	anchorEl: HTMLElement | null;
	open: boolean;
	handleClick: (event: React.MouseEvent<HTMLElement>) => void;
	handleClose: () => void;
}

function MobileMenu({ anchorEl, open, handleClick, handleClose }: MobileMenuProps) {
	const navigate = useNavigate();

	const openLink = (url: string, target?: string) => {
		if (target === '_blank') {
			window.open(url, '_blank');
		} else {
			navigate(url);
		}
		handleClose();
	};

	return (
		<Box component={'div'} sx={{ display: { md: 'none', xs: 'block' } }}>
			<IconButton
				aria-controls={open ? 'demo-positioned-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
			>
				<Icons.Menu />
			</IconButton>
			<Menu
				id="demo-positioned-menu"
				aria-labelledby="demo-positioned-button"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right'
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'right'
				}}
			>
				<MenuItem onClick={() => openLink('/')}>Home</MenuItem>
				{menuItems.map((item, index) => (
					<MenuItem key={index} onClick={() => openLink(item.to, item.target)}>
						{item.label}
					</MenuItem>
				))}
			</Menu>
		</Box>
	);
}

export default MobileMenu;
