import { Box, Button, Container, Grid, Typography } from '@mui/material';
import React from 'react';

import BodyWrapper from '../core/components/BodyWrapper';
import BreadcrumbConnectedWrapper from '../core/components/BreadcrumbConnectedWrapper';

const CustomerSupport: React.FC = () => {
	return (
		<>
			<BreadcrumbConnectedWrapper />
			<BodyWrapper>
				<Grid container columnSpacing={2} rowSpacing={2}>
					<Grid item xs={12}>
						<h3>Customer Support</h3>
					</Grid>

					<Grid item xs={12} md={8}>
						<h4>Contact Us for Order Support</h4>
						<p>
							If you need assistance with your order or have any questions, our customer support team is here to
							help. You can reach us through the following methods:
						</p>
						<h5>Email Support</h5>
						<p>
							For general inquiries or non-urgent issues, please email us at support@breakmark.com. We aim to
							respond to all emails within 24 hours during business days.
						</p>

						<h5>Order Information</h5>
						<p>
							When contacting us about an order, please have your order number ready. This will help us assist you
							more efficiently. You can find your order number in the confirmation email you received after placing
							your order.
						</p>
					</Grid>
				</Grid>
			</BodyWrapper>
		</>
	);
};

export default CustomerSupport;
