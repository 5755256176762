import { Grid } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import TeamSalesDialog, { TeamSalesFormData } from '../../components/Dialogs/TeamSalesDialog';
import ProductDetailGenericActions from '../../components/ProductDetailGenericActions';
import ProductSection from '../../components/ProductSection';
import TeamApparelFAQ from '../../components/TeamApparelFAQ';
import { useBreadcrumbs } from '../../providers/breadcrumbProvider';
import { SimplifiedProduct } from '../ProductDetail/ParseServerProductUtilities';
import ProductDetail from '../ProductDetail/ProductDetail';
import { TeamBundleApparelJson } from './teamApparelJson';

function TeamApparelItem() {
	const { productId } = useParams();

	const { addBreadcrumb, removeBreadcrumb } = useBreadcrumbs();
	const product = TeamBundleApparelJson.find((bundle) => bundle.id === productId);

	const [dialogOpen, setDialogOpen] = useState(false);

	const handleOpenDialog = () => setDialogOpen(true);
	const handleCloseDialog = () => setDialogOpen(false);

	const handleTeamSalesSubmit = (formData: TeamSalesFormData) => {
		console.log('Form submitted:', formData);
		// Handle the form submission here (e.g., send data to an API)
		handleCloseDialog();
	};

	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);
	// useEffect(() => {
	// 	if (product) {
	// 		addBreadcrumb({
	// 			name: product.label as string,
	// 			path: location.pathname
	// 		});
	// 	}

	// 	return () => {
	// 		removeBreadcrumb(location.pathname);
	// 	};
	// }, [product, addBreadcrumb, removeBreadcrumb, location]);

	if (!product) {
		return (
			<ProductSection>
				<div> No product found</div>
			</ProductSection>
		);
	}
	const memoizedGenericAction = useMemo(() => {
		return <ProductDetailGenericActions buttonAction={handleOpenDialog} buttonLabel={'Start your Order!'} />;
	}, []);

	const customDetails = <TeamApparelFAQ />;

	return (
		<ProductSection>
			<Grid container rowSpacing={2} columnSpacing={3}>
				<ProductDetail
					product={product}
					disableQuantityField
					customActionContainer={memoizedGenericAction}
					customDetails={customDetails}
				/>
			</Grid>
			{dialogOpen && (
				<TeamSalesDialog
					open={dialogOpen}
					onClose={handleCloseDialog}
					onSubmit={handleTeamSalesSubmit}
					productId={productId}
				/>
			)}
		</ProductSection>
	);
}

export default TeamApparelItem;
