import { useEffect, useState } from 'react';

import { OptionValue } from '../../stores/cart/action';
import { ProductCustomizationDef } from '../types/ProductCustomizationDef';
import { ProductOptionDef } from '../types/ProductOptionDef';
import { handleStoreDiscount } from '../utilities/currency';

interface BasePriceOptions {
	basePrice: number;
	customPrice?: number;
}

interface PriceCalculationOptions {
	basePrice: number;
	customPriceOverride?: number;
	customNamePrice?: number;
	customNumberPrice?: number;
	options?: OptionValue[];
	discountRate?: number;
	discountType?: 'dollar' | 'percent';
	customization?: ProductCustomizationDef;
	calculateCustomizations?: boolean;
}

const getBasePrice = ({ basePrice, customPrice }: BasePriceOptions) => {
	return customPrice && customPrice > 0 ? customPrice : basePrice || 0;
};

const useProductPrice = ({
	basePrice,
	customPriceOverride,
	customNamePrice,
	customNumberPrice,
	discountRate,
	discountType,
	customization,
	calculateCustomizations = true
}: PriceCalculationOptions) => {
	const [price, setPrice] = useState(0);

	useEffect(() => {
		const basePriceValue = getBasePrice({
			basePrice: basePrice || 0,
			customPrice: customPriceOverride || 0
		});

		if (!calculateCustomizations) {
			const discountedPrice = handleStoreDiscount({
				discountRate: discountRate,
				type: discountType,
				incomingValue: basePriceValue
			});
			setPrice(discountedPrice);
			return;
		}

		const baselinePrice = handleStoreDiscount({
			discountRate: discountRate,
			type: discountType,
			incomingValue: basePriceValue
		});

		let modifyBy = 0;
		// options?.forEach((opt) => {
		// 	if (opt.priceModifiedAs && opt.priceModifiedAs !== 0) {
		// 		// const modifiedPrice = handleStoreDiscount({
		// 		// 	allowNegativeValues: true,
		// 		// 	discountRate: discountRate,
		// 		// 	type: discountType,
		// 		// 	incomingValue: opt.priceModifiedAs
		// 		// });
		// 		modifyBy += opt.priceModifiedAs;
		// 	}
		// });

		if (customNamePrice && customization?.name && customization.name.length > 0) {
			const modifiedPrice = handleStoreDiscount({
				allowNegativeValues: true,
				discountRate: discountRate,
				type: discountType,
				incomingValue: customNamePrice
			});
			modifyBy += modifiedPrice;
		}

		if (customNumberPrice && customization?.number && customization.number.length > 0) {
			const modifiedPrice = handleStoreDiscount({
				allowNegativeValues: true,
				discountRate: discountRate,
				type: discountType,
				incomingValue: customNumberPrice
			});
			modifyBy += modifiedPrice;
		}

		setPrice(baselinePrice + modifyBy);
	}, [
		basePrice,
		customPriceOverride,
		customNamePrice,
		customNumberPrice,
		discountRate,
		discountType,
		customization,
		calculateCustomizations
	]);

	return price;
};

export default useProductPrice;
