import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';

interface Breadcrumb {
	name: string;
	path: string;
}

interface BreadcrumbContextType {
	breadcrumbs: Breadcrumb[];
	addBreadcrumb: (breadcrumb: Breadcrumb) => void;
	removeBreadcrumb: (path: string) => void;
}

interface LayoutProps {
	children: ReactNode;
}

const BreadcrumbContext = createContext<BreadcrumbContextType | undefined>(undefined);

export const BreadcrumbProvider: React.FC<LayoutProps> = ({ children }) => {
	const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([
		{ name: 'Home', path: '/' } // Initialize with Home breadcrumb
	]);

	const addBreadcrumb = useCallback((breadcrumb: Breadcrumb) => {
		setBreadcrumbs((prevBreadcrumbs) => {
			if (!prevBreadcrumbs.some((b) => b.path === breadcrumb.path)) {
				const newBreadcrumbs = [...prevBreadcrumbs, breadcrumb];
				// Sort breadcrumbs by path length to ensure hierarchical order
				newBreadcrumbs.sort((a, b) => a.path.length - b.path.length);
				return newBreadcrumbs;
			}
			return prevBreadcrumbs;
		});
	}, []);

	const removeBreadcrumb = useCallback((path: string) => {
		setBreadcrumbs((prevBreadcrumbs) => prevBreadcrumbs.filter((b) => b.path !== path));
	}, []);

	return (
		<BreadcrumbContext.Provider value={{ breadcrumbs, addBreadcrumb, removeBreadcrumb }}>
			{children}
		</BreadcrumbContext.Provider>
	);
};

export const useBreadcrumbs = () => {
	const context = useContext(BreadcrumbContext);
	if (!context) {
		throw new Error('useBreadcrumbs must be used within a BreadcrumbProvider');
	}
	return context;
};
