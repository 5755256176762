import React from 'react';
import { Outlet } from 'react-router-dom';

import ProtectedRoute from '../../components/ProtectedRoute';

function My() {
	return (
		<ProtectedRoute>
			<Outlet />
		</ProtectedRoute>
	);
}

export default My;
