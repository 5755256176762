import styled from '@emotion/styled';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import React from 'react';

import { Feature } from './types';

interface FeatureDialogProps {
	open: boolean;
	onClose: () => void;
	feature: Feature | null;
}

const FeatureDialog: React.FC<FeatureDialogProps> = ({ open, onClose, feature }) => {
	if (!feature) return null;

	return (
		<StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
			<DialogContent>
				<FeatureContainer>
					<FeatureImage src={feature.dialogImage} alt={feature.title} />
					<TextOverlay>
						<FeatureTitle variant="h4">{feature.title}</FeatureTitle>
						<FeatureSubtitle>{feature.subtitle}</FeatureSubtitle>
					</TextOverlay>
				</FeatureContainer>
				<FeatureDescription>{feature.description}</FeatureDescription>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} variant="contained" color="primary">
					Close
				</Button>
			</DialogActions>
		</StyledDialog>
	);
};

const StyledDialog = styled(Dialog)`
	.MuiDialog-paper {
		background-color: rgba(255, 255, 255, 0.9);
		backdrop-filter: blur(20px);
	}
`;

const FeatureContainer = styled.div`
	position: relative;
	margin-bottom: 24px;
`;

const FeatureImage = styled.img`
	width: 100%;
	max-height: 400px;
	object-fit: cover;
	border-radius: 8px;
`;

const TextOverlay = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 20px;
	background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.7) 60%, rgba(0, 0, 0, 0) 100%);
	color: white;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
`;

const FeatureTitle = styled(Typography)`
	font-weight: 700;
	margin-bottom: 8px;
	color: white;
`;

const FeatureSubtitle = styled(Typography)`
	font-size: 18px;
	font-weight: 500;
	color: white;
`;

const FeatureDescription = styled(Typography)`
	font-size: 16px;
	line-height: 1.6;
	margin-bottom: 24px;
`;

export default FeatureDialog;
