import React, { useEffect } from 'react';

import { useConfigStore } from '../../stores/productConfiguration/useConfigureProduct';
import { SimplifiedProduct, TransformProductForConfig } from '../ProductDetail/ParseServerProductUtilities';
import ProductDetail from '../ProductDetail/ProductDetail';

interface Props {
	isActive: boolean;
	product: SimplifiedProduct;

	handleNext: () => void;
}

function BundleProductDetailWrapper({ isActive, product, handleNext }: Props) {
	const { clearConfig, initConfig } = useConfigStore();

	useEffect(() => {
		if (isActive) {
			const configProduct = TransformProductForConfig(product);
			initConfig({ baseProduct: configProduct });
		}
		return () => {
			clearConfig();
		};
	}, [isActive, initConfig, clearConfig, product]);

	return <ProductDetail disablePriceLabelForBundle disableQuantityField addtNextCall={handleNext} product={product} />;
}

export default BundleProductDetailWrapper;
