import styled from '@emotion/styled';
import React from 'react';
import { NavLink } from 'react-router-dom';

import useProductPrice from '../core/hooks/useProductPrice';
import { formatCurrencyValueFromServer } from '../core/utilities/currency';

export interface Product {
	id: string;
	image?: string;
	name: string;
	stars?: number;
	basePrice: number;
	customPriceOverride?: number;
	discountRate?: number | undefined;
	discountType?: 'dollar' | 'percent' | undefined;
}

export interface ProductPreviewProps {
	id: string;
	image?: string;
	name: string;
	stars?: number;
	basePrice: number;
	customPriceOverride?: number;
	discountRate?: number | undefined;
	discountType?: 'dollar' | 'percent' | undefined;
	navPath?: string;
}

const ProductPreviewContainer = styled('div')`
	display: flex;
	flex-direction: column;
	height: 100%;
	cursor: pointer;
	border-radius: 20px;
	transition: all 0.3s ease;

	&:hover {
		transform: scale(1.03);
	}

	@media only screen and (max-width: 900px) {
	}
`;

type PanelProps = {
	src: string;
};
const ProductImage = styled('div')(
	(props: PanelProps) => `
        background-color: var(--white);
        background-image: url("${props.src}");
        background-size: contain;
        aspect-ratio: 1;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 20px;
        border: 1px solid var(--separator);
        transition: all 0.3s ease;
        flex-shrink: 0;
    `
);

const ProductInfo = styled('div')`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	justify-content: space-between;
`;

const ProductName = styled('div')`
	margin: 8px 8px 0;
	font-size: 16px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const PriceContainer = styled('div')`
	margin: 8px;
	font-size: 14px;
	display: flex;
	align-items: center;
	gap: 8px;
`;

const DiscountedPrice = styled('span')`
	font-weight: bold;
	color: var(--typography-base);
`;

const OriginalPrice = styled('span')`
	text-decoration: line-through;
	color: var(--typography-muted);
	font-size: 0.9em;
`;

const DiscountBadge = styled('span')`
	background: var(--discount-bg);
	color: var(--typography-discount);
	padding: 2px 4px;
	border-radius: 4px;
	font-size: 0.8em;
`;

const ProductPreview = ({
	id,
	image,
	name,
	basePrice,
	customPriceOverride,
	discountRate,
	discountType,
	navPath
}: ProductPreviewProps) => {
	const price = useProductPrice({
		basePrice,
		customPriceOverride,
		discountRate,
		discountType
	});

	const isDiscounted = price < basePrice;
	const discountPercentage = isDiscounted ? Math.round(((basePrice - price) / basePrice) * 100) : 0;

	return (
		<NavLink to={navPath || `Product/${id}`} className={'no-decoration'}>
			<ProductPreviewContainer>
				{image && <ProductImage src={image} />}
				<ProductInfo>
					<ProductName>{name}</ProductName>
					<PriceContainer>
						<DiscountedPrice>{formatCurrencyValueFromServer(price)}</DiscountedPrice>
						{isDiscounted && (
							<>
								<OriginalPrice>{formatCurrencyValueFromServer(basePrice)}</OriginalPrice>
								<DiscountBadge>-{discountPercentage}%</DiscountBadge>
							</>
						)}
					</PriceContainer>
				</ProductInfo>
			</ProductPreviewContainer>
		</NavLink>
	);
};

export default ProductPreview;
