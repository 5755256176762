import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import Button from '../../components/Button';
import ProductAddedDialog from '../../components/Dialogs/ProductAddedDialog';
import { calculateBundledItemsTotalAddonPrice, formatCurrencyValueFromServer } from '../../core/utilities/currency';
import { GetPackageByPackageIdQuery } from '../../generated/graphql';
import useBundleStore from '../../stores/bundles/useBundleStore';
import { useCartStore } from '../../stores/updatedCart/cartStore';
import { AddToCartContainer, P3, ReviewContainer, ReviewDetails, ReviewImage, ReviewItem } from './ReviewBundleStyledComponents';

interface ReviewBundleProps {
	bundleInfo: GetPackageByPackageIdQuery;
}

const ReviewBundle: React.FC<ReviewBundleProps> = ({ bundleInfo }) => {
	const { bundleId, storeId } = useParams();
	const bundleItems = useBundleStore((state) => state.bundleItems);
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const addProduct = useCartStore((state) => state.addProduct);

	const bundleDetails = bundleInfo.store_package[0];

	const handleActionClick = () => {
		const priceAddons = calculateBundledItemsTotalAddonPrice(Object.values(bundleItems));

		addProduct({
			storeId: storeId!,
			item: {
				type: 'package',
				id: bundleId!,
				label: bundleDetails.label || 'Bundle',
				image: bundleDetails.photo_url || '',
				price: bundleDetails.custom_price + priceAddons,
				products: Object.values(bundleItems)
			}
		});
		setIsDialogOpen(true);
	};

	return (
		<ReviewContainer>
			{Object.values(bundleItems).map((pkg) => {
				const { label, image, id, options, custom_name, custom_number } = pkg;
				const { ...rest } = options;

				return (
					<ReviewItem key={id}>
						<ReviewImage src={image || ''} alt={label} />
						<ReviewDetails>
							<Typography variant="h6">{label}</Typography>
							{Object.values(rest).map((item) => (
								<P3 key={item.availableOptionId}>
									<strong>{item.label}</strong>: {item.value || ' '}
									{item.priceModifiedAs > 0 && (
										<div>
											<small>+{formatCurrencyValueFromServer(item.priceModifiedAs)}</small>
										</div>
									)}
								</P3>
							))}
							{custom_name && (
								<P3>
									<strong>Custom Name</strong>: {custom_name || ' '}
								</P3>
							)}
							{custom_number !== undefined && (
								<P3>
									<strong>Custom Number</strong>: {custom_number || ' '}
								</P3>
							)}
						</ReviewDetails>
					</ReviewItem>
				);
			})}
			<AddToCartContainer>
				<Button color={'green'} fullWidth={true} onClick={handleActionClick}>
					Add to Cart
				</Button>
			</AddToCartContainer>
			{isDialogOpen && (
				<ProductAddedDialog label={bundleDetails.label || ''} image={bundleDetails.photo_url || ''} onClose={() => {}} />
			)}
		</ReviewContainer>
	);
};

export default ReviewBundle;
