import { RouteDefinitions } from '../routeDefinitions';

export interface MenuItem {
	label: string;
	to: string;
	target?: string;
}

export const menuItems: MenuItem[] = [
	{ label: 'Custom Team Gear', to: RouteDefinitions.CUSTOM() },
	{ label: 'Products', to: RouteDefinitions.TEAM_APPAREL() },
	{ label: 'Custom Collaborations', to: 'https://teams.breakmark.com/group/collaborations', target: '_blank' },
	{ label: 'Western Ultimate League', to: 'https://teams.breakmark.com/group/wul', target: '_blank' },
	{ label: 'Premier Ultimate League', to: 'https://teams.breakmark.com/group/pul', target: '_blank' }
];
