import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

let apolloClient: ApolloClient<any> | null = null;

export function createApolloClient() {
	if (!apolloClient) {
		// Create the HttpLink to your GraphQL endpoint
		const httpLink = new HttpLink({
			uri: process.env['REACT_APP_APOLLO_ENDPOINT']
		});

		// Create the authLink to include the Authorization header
		const authLink = setContext((_, { headers }) => {
			// Get the authentication token from localStorage if it exists
			const token = localStorage.getItem('authToken');

			// If there's no token, don't add the authorization header
			if (!token) {
				return { headers };
			}

			return {
				headers: {
					...headers,
					authorization: `Bearer ${token}`
				}
			};
		});

		// Combine authLink and httpLink
		const link = ApolloLink.from([authLink, httpLink]);

		// Create the Apollo Client
		apolloClient = new ApolloClient({
			link,
			cache: new InMemoryCache()
		});
	}

	return apolloClient;
}
