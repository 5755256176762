import React, { useEffect, useMemo } from 'react';

import ProductPrice from '../../components/ProductPrice';
import { calculateBundledItemsTotalAddonPrice } from '../../core/utilities/currency';
import useBundleStore from '../../stores/bundles/useBundleStore';
import { Product } from '../../stores/cart/action';
import { useConfigStore } from '../../stores/productConfiguration/useConfigureProduct';

interface BundleHeaderPriceProps {
	totalPrice: number;
}

function BundleHeaderPrice({ totalPrice }: BundleHeaderPriceProps) {
	const bundleItems = useBundleStore((state) => state.bundleItems);
	const currentConfiguredProduct = useConfigStore((state) => state.currentConfig);

	const totalUpdatedPrice = useMemo(() => {
		const bundleItemsArray = Object.values(bundleItems);
		const currentConfiguredProductArray = currentConfiguredProduct ? [currentConfiguredProduct as Product] : [];
		return calculateBundledItemsTotalAddonPrice([...bundleItemsArray, ...currentConfiguredProductArray]) + totalPrice;
	}, [bundleItems, currentConfiguredProduct]);

	return <ProductPrice basePrice={totalUpdatedPrice} />;
}

export default BundleHeaderPrice;
