import styled from '@emotion/styled';
import { Breadcrumbs, Grid } from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import React from 'react';
import { ChevronRight, File } from 'react-feather';
import { NavLink } from 'react-router-dom';

import data from './Templates.json';

function DesignTemplates() {
	const BodyWrapper = styled('div')`
		display: block;
		width: 95%;
		max-width: 1240px;
		margin: 24px auto;
	`;

	const BreadcrumbsWrapper = styled('div')`
		display: flex;
		flex-direction: column;
		width: 95%;
		max-width: 1240px;
		margin: auto;
	`;

	const Separator = styled('div')`
		width: 100%;
		border-bottom: 1px solid var(--separator);
		margin: 0 0 24px;
	`;

	const columns: GridColDef[] = [
		{
			field: 'category',
			headerName: 'Category',
			width: 220,
			headerAlign: 'left',
			align: 'left',
			type: 'singleSelect',
			valueOptions: [
				'FULL SUBLIMATION MEN',
				'FULL SUBLIMATION WOMEN',
				'FULL SUBLIMATION YOUTH',
				'FULL SUBLIMATION OTHER',
				'SOLID SUBLIMATION MEN',
				'SOLID SUBLIMATION WOMEN',
				'SOLID SUBLIMATION YOUTH'
			]
		},
		{
			field: 'title',
			headerName: 'Title',
			type: 'string',
			width: 270,
			sortable: false
		},
		{
			field: 'href',
			headerName: 'Link',
			type: 'string',
			flex: 1,
			sortable: false,
			filterable: false,
			renderCell: (params) => {
				return (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<File />
						&nbsp;
						<a href={params.row.href} key={params.row.id} target={'_blank'} rel={'noreferrer'}>
							{params.row.href}
						</a>
					</div>
				);
			}
		}
	];

	const StripedDataGrid = styled(DataGrid)`
		.MuiDataGrid-row:nth-child(odd) {
			background-color: var(--grey);
		}

		.MuiDataGrid-footerContainer {
			border-top: none;
		}

		.MuiTablePagination-toolbar p {
			margin: 0;
		}
	`;

	return (
		<>
			<BreadcrumbsWrapper>
				<Separator />
				<Breadcrumbs aria-label="breadcrumb" separator={<ChevronRight width={18} height={18} />}>
					<NavLink to={'/'}>
						<p className={'no-margin'}>Home</p>
					</NavLink>
					<NavLink to={'/Design/Inspiration'}>
						<p className={'no-margin'}>
							<strong>Design Template</strong>
						</p>
					</NavLink>
				</Breadcrumbs>
			</BreadcrumbsWrapper>
			<BodyWrapper>
				<Grid container columnSpacing={2} rowSpacing={2}>
					<Grid item xs={12} display={'flex'} flexDirection={'column'}>
						<h4>Design Template Files</h4>
						<StripedDataGrid
							rows={data}
							columns={columns}
							getRowId={(row) => row.href}
							initialState={{
								pagination: {
									paginationModel: {
										pageSize: 100
									}
								}
							}}
							slots={{ toolbar: GridToolbar }}
							slotProps={{
								toolbar: {
									showQuickFilter: true
								}
							}}
							pageSizeOptions={[100]}
							disableRowSelectionOnClick
							sx={{
								display: 'flex',
								borderRadius: 4,
								marginY: 4
							}}
						/>
					</Grid>
				</Grid>
			</BodyWrapper>
		</>
	);
}

export default DesignTemplates;
