import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	palette: {
		primary: {
			main: 'rgba(0, 0, 0, 1)'
		},
		secondary: {
			main: '#F0F0F0'
		},
		error: {
			main: 'rgba(255, 48, 48, 1)'
		}
	}
});

export default theme;
