export const getShortSizeDescriptor = (key: string, value: string) => {
	if (key.toLowerCase() !== 'size') {
		return value;
	}

	const lowerValue = value.toLowerCase();

	if (lowerValue === 'small') {
		return 'S';
	} else if (lowerValue === 'extra small') {
		return 'XS';
	} else if (lowerValue === 'extra extra small') {
		return 'XXS';
	} else if (lowerValue === 'medium') {
		return 'M';
	} else if (lowerValue === 'large') {
		return 'L';
	} else if (lowerValue === 'extra large') {
		return 'XL';
	} else if (lowerValue === 'extra extra large') {
		return 'XXL';
	} else if (lowerValue === 'extra extra extra large') {
		return 'XXXL';
	} else if (lowerValue === 'extra extra extra extra large') {
		return 'XXXXL';
	}
	return value;
};

/**
 * Mens/Unisex tops: XS-4XL

Womens tops: XS-2XL

Adult Shorts: XS-2XL

Youth tops: XS-XL

Youth shorts: SM-XL


 */

export const getShirtSizeDisabled = (key: string): string[] | undefined => {
	if (key.toLowerCase() === 'womens') {
		return ['XXS', 'XXXL', 'XXXXL'];
	} else if (key.toLowerCase() === 'youth') {
		return ['XXS', 'XXL', 'XXXL', 'XXXXL'];
	}
	return undefined;
};

export const getShortSizeDisabled = (key: string): string[] | undefined => {
	if (key.toLowerCase().includes('youth')) {
		return ['XXS', 'XS', 'XXL', 'XXXL', 'XXXXL'];
	}
	return ['XXS', 'XXXL', 'XXXXL'];
};
