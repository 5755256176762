import { useMemo } from 'react';

import { FormattedProduct, ProductOption, ProductOptionList } from '../core/types/ProductDef';
import { StoreProductFragment } from '../generated/graphql';

export const useFormattedStoreProduct = (storeProduct?: StoreProductFragment[]) => {
	const storeProductFormatted = useMemo(() => {
		if (!storeProduct) {
			return [];
		}

		if (storeProduct.length === 0) {
			return [];
		}

		return storeProduct.map((pro): FormattedProduct => {
			const {
				bundle_only,
				store_product_has_options,
				custom_name_enabled,
				custom_number_enabled,
				id,
				order,
				friendly_url_key,
				product,
				label,
				custom_name_price,
				custom_number_price,
				description,
				display_photo,
				custom_price_override,
				enforce_limit_qty,
				limit_order_qty,
				qty_purchased_calc
			} = pro;

			if (!store_product_has_options) {
				return {
					bundle_only,
					custom_name_enabled,
					custom_number_enabled,
					id,
					friendly_url_key: friendly_url_key || undefined,
					order,
					product: {
						top_size_restriction: product.top_size_restriction,
						short_size_restriction: product.short_size_restriction,
						base_price:
							custom_price_override && custom_price_override > 0 ? custom_price_override : product.base_price || 0
					},
					label: label || undefined,
					custom_name_price: custom_name_price || undefined,
					custom_number_price: custom_number_price || undefined,
					description: description || undefined,
					display_photo: display_photo || undefined,
					productOptions: [],
					enforce_limit_qty,
					limit_order_qty,
					qty_purchased_calc,
					additional_image_list: pro.additional_image_list
				};
			}

			// Header keys don't have option_values
			const objectHeaderKeys = store_product_has_options.filter((f) => f.available_option_value === null);
			const optionValues = store_product_has_options.filter((f) => f.available_option_value !== null);

			const productOptions = objectHeaderKeys.map((headerKey): ProductOptionList => {
				const options = optionValues.filter((f) => f.available_option.id === headerKey.available_option.id);

				const list: ProductOption[] = options
					.map((o): ProductOption => {
						if (!o.available_option_value) {
							return {
								id: '',
								label: '',
								order: 0
							};
						}

						return {
							order: o.order,
							imageUrl: o.image_url || undefined,
							secondaryImageUrl: o.secondary_image_url || undefined,
							id: o.available_option_value.id,
							label: o.label_override ? o.label_override : o.available_option_value.label,
							priceModifier:
								o.price_modifier_override !== null && typeof o.price_modifier_override === 'number'
									? o.price_modifier_override
									: o.available_option_value.price_modifier
						};
					})
					.filter((a) => a.id !== '');

				return {
					id: headerKey.available_option.id,
					label: headerKey.available_option.display_name
						? headerKey.available_option.display_name
						: headerKey.available_option.name,
					order: headerKey.order,
					uiTestId: headerKey.available_option.ui_test_id || undefined,
					options: list
				};
			});

			return {
				bundle_only,
				custom_name_enabled,
				custom_number_enabled,
				id,
				friendly_url_key: friendly_url_key || undefined,
				order,
				product: {
					top_size_restriction: product.top_size_restriction,
					short_size_restriction: product.short_size_restriction,
					base_price:
						custom_price_override && custom_price_override > 0 ? custom_price_override : product.base_price || 0
				},
				label: label || undefined,
				custom_name_price: custom_name_price || undefined,
				custom_number_price: custom_number_price || undefined,
				description: description || undefined,
				display_photo: display_photo || undefined,
				productOptions,
				limit_order_qty,
				qty_purchased_calc,
				enforce_limit_qty,
				additional_image_list: pro.additional_image_list
			};
		});
	}, [storeProduct]);

	return storeProductFormatted;
};
