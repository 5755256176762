// Routes.ts
export class RouteDefinitions {
	static HOME = () => '/';
	static ABOUT = () => '/About';
	static FAQ = () => '/FrequentlyAskedQuestions';
	static CART = () => '/Cart';
	static TEAM_STORE_CART = (storeId = ':storeId') => `/Cart/${storeId}`;
	static CUSTOM = () => '/Custom';
	static GROUP = (group = ':group') => `/Group/${group}`;
	static TEAM = (group = ':group', team = ':team') => `/Group/${group}/${team}`;
	static IN_STOCK = () => '/InStock';
	static LOGIN = () => '/Login';
	static PARTNERS = () => '/Partners';
	static PRODUCT_DETAIL = (id = ':id') => `/Product/${id}`;
	static SEARCH = () => '/Search';
	static SEARCH_WITH_PARAM = (search = ':search') => `/Search/${search}`;
	static TEAM_STORE = (storeId = ':storeId') => `/TeamStore/${storeId}`;
	static TEAM_STORE_PRODUCT = (storeId = ':storeId', productId = ':productId') => `/TeamStore/${storeId}/product/${productId}`;
	static TEAM_STORE_BUNDLE = (storeId = ':storeId', bundleId = ':bundleId') => `/TeamStore/${storeId}/bundle/${bundleId}`;
	static STYLE_GUIDE = () => '/styleguide';
	static NOT_FOUND = () => '*';
	static DESIGN = () => '/Design';
	static DESIGN_INSPIRATION = () => '/Design/Inspiration';
	static DESIGN_TEMPLATES = () => '/Design/Templates';
	static DESIGN_SIZING = () => '/Design/Sizing';

	static CHECKOUT_CALLBACK = () => '/callback*';
	// Auth Specific Routes
	static MY = () => '/My';
	static MY_ACCOUNT = () => '/My/Account/*';
	static BUNDLE_BUILDER = () => '/BundleBuilder';
	static TEAM_APPAREL = () => '/TeamApparel';
	static TEAM_APPAREL_ITEM = (productId = ':productId') => `/TeamApparel/${productId}`;
	static CUSTOM_TEAM_APPAREL = (category?: string, subcategory?: string) =>
		`/custom-team-apparel${category ? `/${category}` : ''}${subcategory ? `/${subcategory}` : ''}`;

	// Example of a method that could be useful in the future
	static buildGroupRoute(group: string, team?: string): string {
		return team ? this.TEAM(group, team) : this.GROUP(group);
	}

	static TERMS_AND_CONDITIONS = () => '/terms-and-conditions';
	static PRIVACY_POLICY = () => '/privacy-policy';
	static CUSTOMER_SUPPORT = () => '/customer-support';
}
