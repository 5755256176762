import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { GetTeamStoreOrderDocument, TeamStoreOrderFragment } from '../../generated/graphql';
import useFormatServerOrders from '../../hooks/useFormatServerOrders';
import { Package, Product } from '../../stores/cart/action';
import { useCartStore } from '../../stores/updatedCart/cartStore';

const Container = styled.div`
	max-width: 1100px;
	margin: 0 auto;
	text-align: center;
	margin-bottom: 40px;
`;

const Title = styled(Typography)`
	margin-top: 30px;
	margin-bottom: 30px;
`;

const ExpandedContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 15px;
	margin-top: 15px;
	margin-bottom: 15px;

	@media (max-width: 600px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (max-width: 480px) {
		grid-template-columns: repeat(1, 1fr);
	}
`;

const DetailImage = styled.img`
	width: 100px;
	height: 100px;
	margin-right: 40px;
`;

const ItemContainer = styled.div`
	background-color: #fff;
	display: flex;
	border: 1px solid --separator;
	padding: 15px;
	text-align: left;
	border-radius: 8px;
`;

const ProductName = styled.p`
	padding-bottom: 10px;
`;

const BackToOrders = styled.div`
	margin-top: 20px;
	text-align: center;
	width: 100%;

	& > :not(:first-child) {
		margin-left: 16px;
	}
`;

const Callback: React.FC = () => {
	const location = useLocation();
	const clearStoreCart = useCartStore((state) => state.clearStoreCart);

	const searchParams = new URLSearchParams(location.search);

	const storeId = searchParams.get('storeId');
	const status = searchParams.get('status');
	const orderIds = searchParams.get('orderIds');
	const groupOrStore = searchParams.get('type');

	const teamStoreOrder = useQuery(GetTeamStoreOrderDocument, {
		variables: {
			_eq: storeId,
			orderIds: orderIds
		}
	});
	const orders: TeamStoreOrderFragment | undefined = useMemo(() => {
		if (!teamStoreOrder.data?.order || teamStoreOrder.data.order.length === 0) {
			return undefined;
		}

		return teamStoreOrder.data.order.reduce((acc: TeamStoreOrderFragment, cur: TeamStoreOrderFragment, index: number) => {
			// Skip the first item because it's used as the initial value.
			if (index === 0) return acc;

			// If order_items is undefined on either object, default to an empty array.
			const accOrderItems = acc.order_items || [];
			const curOrderItems = cur.order_items || [];

			return { ...acc, order_items: [...accOrderItems, ...curOrderItems] };
		}, teamStoreOrder.data.order[0]);
	}, [teamStoreOrder?.data?.order]);

	useEffect(() => {
		const storeId = searchParams.get('storeId');
		if (storeId && status === 'success') {
			clearStoreCart({ storeId });
		}
	}, [searchParams]);

	if (status !== 'success') {
		return (
			<Container>
				<Title variant="h2">Your order has been cancelled.</Title>
				<small>Please revisit the cart and make whatever changes you need to make prior to checking out again.</small>
			</Container>
		);
	}

	const formattedOrders = useFormatServerOrders(orders);

	return (
		<Container>
			<Title variant="h2">Thank you for your order!</Title>
			<small>
				Thank you for your order, you will be receiving a receipt from us shortly. Your order will start production as
				soon as your store captain has approved the entire order!
			</small>
			{teamStoreOrder.data && teamStoreOrder.data.order && formattedOrders && (
				<ExpandedContainer>
					{formattedOrders.map(
						(orderDetail: Product | Package, i: number): ReactElement => (
							<ItemContainer key={i}>
								{orderDetail.image && <DetailImage src={orderDetail.image} alt={orderDetail.label} />}
								<div>
									<ProductName>
										<strong>{orderDetail.label}</strong>
									</ProductName>
									{orderDetail.order_for && orderDetail.order_for !== '' && (
										<small>
											Ordered For: <strong>{orderDetail.order_for}</strong>
										</small>
									)}
									<p></p>
								</div>
							</ItemContainer>
						)
					)}
				</ExpandedContainer>
			)}
		</Container>
	);
};

export default Callback;
