import styled from '@emotion/styled';

type ImageProps = {
	src: string;
};
export const Logo = styled('div')<ImageProps>`
	width: 205px;
	margin-top: -5px;
	background-image: url('${(props) => props.src}');
	background-size: contain;
	aspect-ratio: 5;
	background-repeat: no-repeat;
	background-position: center;
	@media only screen and (max-width: 450px) {
		width: 155px;
	}
`;

export default Logo;
