import { getImageUrl } from '../../utils/imageHelper';
import { Feature } from './types';

export const largeFeatures: Feature[] = [
	{
		id: '1',
		title: 'Performance Apparel',
		subtitle: 'Lightweight and breathable for peak performance.',
		imageUrl: getImageUrl('feature/Performance_Jerseys.jpg'),
		dialogImage: getImageUrl('feature/Performance_Jerseys_dialog.jpg'),
		description:
			'Our Performance Jerseys are crafted with athletes in mind. Featuring lightweight and breathable materials, they ensure peak performance during intense matches. The fabric is designed to wick moisture away, keeping you cool and dry even when the game heats up. Whether on the field or the court, these jerseys provide comfort, mobility, and durability for top performance.',
		shortDescription: 'short description',
		bgColor: '#4AA98C'
	},
	{
		id: '2',
		title: 'Custom Team Designs',
		subtitle: 'Create your own unique team identity.',
		imageUrl: getImageUrl('feature/Custom_Team_Jerseys.jpg'),
		dialogImage: getImageUrl('feature/Custom_Team_Jerseys_dialog.jpg'),
		description:
			"Create a unique identity for your team with our custom design options. At Breakmark, we believe your team's jersey should be as distinct as your strategy. Choose from a variety of templates, colors, and patterns, or upload your own logo and artwork to make your jersey truly one-of-a-kind. Our team of designers is ready to assist in bringing your vision to life with high-quality prints and bold designs that will make you stand out.",
		shortDescription: 'short description',
		bgColor: '#479C8D'
	},
	{
		id: '3',
		title: 'Durability in Action',
		subtitle: 'Built to withstand intense play.',
		imageUrl: getImageUrl('feature/Durable_In_action.jpg'),
		dialogImage: getImageUrl('feature/Durability_In_Action_dialog.jpg'),
		description:
			"Built to withstand even the toughest games, our jerseys are made from high-performance materials that offer excellent resistance to wear and tear. Whether you're sliding into bases or battling on the ultimate field, our jerseys can take the hits while maintaining their structure and fit. Enjoy a perfect balance between comfort and rugged durability, designed for long-lasting use.",
		shortDescription: 'short description',
		bgColor: '#43908F'
	},
	{
		id: '5',
		title: 'All-Weather Comfort',
		subtitle: 'Stay comfortable in any condition, rain or shine.',
		imageUrl: getImageUrl('feature/All_Weather.jpg'),
		dialogImage: getImageUrl('feature/All_Weather_dialog.jpg'),
		description:
			'Stay comfortable in any weather with our all-season jersey options. Whether you’re playing in sweltering heat or a light drizzle, these jerseys are designed to regulate your body temperature, keeping you warm when it’s cool and cool when it’s warm. Plus, the breathable fabric ensures moisture is kept at bay, so you can focus on the game, not the conditions.',
		shortDescription: 'short description',
		bgColor: '#408390'
	},
	{
		id: '4',
		title: 'Eco-Friendly Practices',
		subtitle: 'Sustainably sourced fabrics for environmentally conscious teams.',
		imageUrl: getImageUrl('feature/Eco_Friendly.jpg'),
		dialogImage: getImageUrl('feature/Eco_Friendly_dialog.jpg'),
		description:
			'Breakmark believes in treating everyone fairly and doing the right thing. Our sublimated production partner produces in Egypt. There they have certificates for WRAP and ISO. In order to qualify for these certifications, external auditors assess a multitude of factors for how workers are treated, and how the work impacts the environment. A few key points are that workers are paid fair wages, paid for overtime, have healthcare benefits with a nurse on site, and also receive contributions toward retirement.  Our screen printer and embroider are based in the Boston, MA area. We purposely sought out decorators that, rather than just being cheap and fast, take the time to properly go through the decoration process. This results in quality products we stand by.',
		shortDescription: 'short description',
		bgColor: '#3D7691'
	},
	{
		id: '7',
		title: 'Unisex and Women’s Fit',
		subtitle: 'Comfortable options for everyone on your team.',
		imageUrl: getImageUrl('feature/Unisex_and_Women_Fit.jpg'),
		dialogImage: getImageUrl('feature/Unisex_and_Women_Fit_dialog.jpg'),
		description:
			'We offer a variety of fit options to ensure every player is comfortable and confident. From our unisex fit, designed to suit all body types, to our women’s-specific cut, our jerseys provide a comfortable fit for everyone. Made with flexible and lightweight materials, our jerseys allow for full freedom of movement so you can perform your best no matter your size or shape.',
		shortDescription: 'short description',
		bgColor: '#396992'
	}
];

export const smallFeatures: Feature[] = [
	{
		id: '0',
		title: 'Start your order',
		subtitle: '',
		imageUrl: getImageUrl('small/Start_Your_Order.jpg'),
		dialogImage: getImageUrl('temp/horz.jpg'),
		shortDescription: 'Contact our sales team to explore the best options for your team.',
		bgColor: '#4AA98C'
	},
	{
		id: '1',
		title: 'Pick Your Style',
		subtitle: 'Choose full or solid sublimation',
		imageUrl: getImageUrl('small/Pick_Your_Style.jpg'),
		dialogImage: getImageUrl('temp/horz.jpg'),
		description: '',
		shortDescription: "Decide between full sublimation or solid sublimation for your team's custom gear.",
		bgColor: '#479C8D'
	},
	{
		id: '2',
		subtitle: 'Outfit your team with the essentials',
		imageUrl: getImageUrl('small/Select_Your_Bundle.jpg'),
		dialogImage: getImageUrl('temp/horz.jpg'),
		title: 'Select Your Bundle',
		shortDescription: 'Choose the perfect set of apparel and accessories to outfit your entire team.',
		bgColor: '#43908F'
	},
	{
		id: '3',
		subtitle: '"Collaborate with our design team"',
		imageUrl: getImageUrl('small/Customize_your_gear.jpg'),
		dialogImage: getImageUrl('temp/horz.jpg'),
		title: 'Customize Your Gear',
		shortDescription: "Collaborate with our design team to bring your team's unique look to life.",
		bgColor: '#408390'
	},
	{
		id: '5',
		subtitle: "Launch your team's custom shop",
		imageUrl: getImageUrl('small/Get_Your_Store.jpg'),
		dialogImage: getImageUrl('temp/horz.jpg'),
		title: 'Get Your Store',
		shortDescription: "Launch your team's custom Breakmark store to easily collect individual orders.",
		bgColor: '#3D7691'
	},
	{
		id: '6',
		title: 'Place Your Order',
		subtitle: 'Confirm and submit your order',
		imageUrl: getImageUrl('small/Place_your_order.jpg'),
		dialogImage: getImageUrl('temp/horz.jpg'),
		shortDescription: 'Confirm your selections and submit your full team order for processing.',
		bgColor: '#396992'
	},
	{
		id: '7',
		title: 'Production Begins',
		subtitle: 'We start creating the pieces in your order and will send tracking information once production is complete.',
		imageUrl: getImageUrl('small/Production_Begins.jpg'),
		dialogImage: getImageUrl('temp/horz.jpg'),
		shortDescription: 'Production begins, and tracking details follow upon completion.',
		bgColor: '#365D93'
	},
	{
		id: '8',
		subtitle: "Follow your team's shipment",
		title: 'Receive your Order',
		imageUrl: getImageUrl('small/Track_Your_Order.jpg'),
		dialogImage: getImageUrl('temp/horz.jpg'),
		shortDescription: "Review your order on delivery, and we'll resolve any issues.",
		bgColor: '#325095'
	},
	{
		id: '10',
		subtitle: 'Show off your fresh new gear',
		title: 'Hit the Field',
		imageUrl: getImageUrl('small/Hit_The_Field.jpg'),
		dialogImage: getImageUrl('temp/horz.jpg'),
		shortDescription: "Gear up and step onto the field, showing off your team's fresh new look.",
		bgColor: '#2F4396'
	}
];
