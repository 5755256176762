import styled from '@emotion/styled';
import { Breadcrumbs, Chip, Grid, InputAdornment, TextField } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { ChevronRight, Search } from 'react-feather';
import { NavLink } from 'react-router-dom';

import data from './InspirationData';

const BodyWrapper = styled('div')`
	display: block;
	width: 95%;
	max-width: 1240px;
	margin: 24px auto;
`;

const BreadcrumbsWrapper = styled('div')`
	display: flex;
	flex-direction: column;
	width: 95%;
	max-width: 1240px;
	margin: auto;
`;

const Separator = styled('div')`
	width: 100%;
	border-bottom: 1px solid var(--separator);
	margin: 0 0 24px;
`;

type ImageProps = {
	src: string;
};
const DesignImage = styled('div')(
	(props: ImageProps) => `
        background-color: var(--white);
        background-image: url("${props.src}");
        background-size: contain;
        aspect-ratio: 1;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
    `
);

const DesignCard = styled('div')`
	background: var(--white);
	border-radius: 8px;
	padding: 16px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	transition: transform 0.2s;
	cursor: pointer;

	&:hover {
		transform: translateY(-4px);
	}
`;

function DesignInspiration() {
	return (
		<>
			<BreadcrumbsWrapper>
				<Separator />
				<Breadcrumbs aria-label="breadcrumb" separator={<ChevronRight width={18} height={18} />}>
					<NavLink to={'/'}>
						<p className={'no-margin'}>Home</p>
					</NavLink>
					<NavLink to={'/Design/Inspiration'}>
						<p className={'no-margin'}>
							<strong>Design Inspiration</strong>
						</p>
					</NavLink>
				</Breadcrumbs>
			</BreadcrumbsWrapper>
			<BodyWrapper>
				<Grid container columnSpacing={2} rowSpacing={2}>
					<Grid item xs={12}>
						<h5>Design Inspiration</h5>
						<p>
							If you're not sure what design direction you want to take for your team, scroll through the awesome
							designs below. Gather inspiration to help narrow down your ideas. Then, collaborate with our artists
							to help create your vision! Contact us with your ideas and questions.
						</p>
					</Grid>

					{data.map((design) => (
						<Grid key={design.id} item xs={12} sm={6} md={4}>
							<DesignCard>
								<DesignImage src={design.image_url} />
							</DesignCard>
						</Grid>
					))}
				</Grid>
			</BodyWrapper>
		</>
	);
}

export default DesignInspiration;
