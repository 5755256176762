import { Grid, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { useState } from 'react';

import InputText from '../../components/InputText';
import { ProductCustomizationDef } from '../../core/types/ProductCustomizationDef';
import { formatCurrencyValueFromServer } from '../../core/utilities/currency';
import { ProductByIdFragment } from '../../generated/graphql';
import { useConfigStore } from '../../stores/productConfiguration/useConfigureProduct';
import { SimplifiedProduct } from './ParseServerProductUtilities';
import { OptionsBlock } from './ProductDetailStyledComponents';

interface CustomNameNumberProps {
	product: SimplifiedProduct;
}

const CustomNameNumber: React.FC<CustomNameNumberProps> = ({ product }) => {
	const [custom, setCustom] = useState(false);
	const { currentConfig, updateCustomField } = useConfigStore();

	const handleSelectUpdate = (evt: SelectChangeEvent) => {
		if (evt.target.value === 'CUSTOM') {
			updateCustomField({ field: 'custom_name', value: '' });
			updateCustomField({ field: 'custom_number', value: '' });
			setCustom(true);
			return;
		}
		setCustom(false);
		const selectedIndex = parseInt(evt.target.value as string, 10);
		const selectedItem = product?.customNameNumberDropdown?.[selectedIndex];

		updateCustomField({ field: 'custom_name', value: selectedItem?.name || '' });
		updateCustomField({ field: 'custom_number', value: `${selectedItem?.number}` });
	};

	return (
		<>
			<small>Custom Name / Number</small>
			<OptionsBlock>
				<Grid container columnSpacing={2} rowSpacing={2}>
					{product.customNameNumberDropdown && product.customNameNumberDropdown.length > 0 && (
						<Grid item xs={12}>
							<Select onChange={handleSelectUpdate} size={'small'} fullWidth>
								{product.customNameNumberDropdown.map(
									(
										specific: {
											number:
												| string
												| number
												| boolean
												| React.ReactElement<any, string | React.JSXElementConstructor<any>>
												| Iterable<React.ReactNode>
												| React.ReactPortal
												| null
												| undefined;
											name:
												| string
												| number
												| boolean
												| React.ReactElement<any, string | React.JSXElementConstructor<any>>
												| Iterable<React.ReactNode>
												| React.ReactPortal
												| null
												| undefined;
										},
										index: number
									) => (
										<MenuItem key={index} value={index}>
											#{specific.number} - {specific.name}
										</MenuItem>
									)
								)}
								<MenuItem value="CUSTOM">Custom Number</MenuItem>
							</Select>
						</Grid>
					)}
					{(!product.customNameNumberDropdown?.length || custom) && (
						<>
							{product.customNameEnabled && (
								<Grid item xs={8}>
									<InputText
										value={currentConfig?.custom_name || ''}
										placeholder={'Name'}
										helperText={
											product.customNamePrice === 0 || !product.customNamePrice
												? undefined
												: `+${formatCurrencyValueFromServer(product.customNamePrice || 0)}`
										}
										onChange={(evt) => updateCustomField({ field: 'custom_name', value: evt })}
									/>
									<small>
										Additional price of {formatCurrencyValueFromServer(product.customNamePrice || 0)}
									</small>
								</Grid>
							)}
							{product.customNumberEnabled && (
								<Grid item xs={4}>
									<InputText
										value={currentConfig?.custom_number || ''}
										placeholder={'Number'}
										helperText={
											product.customNumberPrice === 0 || !product.customNumberPrice
												? undefined
												: `+${formatCurrencyValueFromServer(product.customNumberPrice || 0)}`
										}
										onChange={(evt: string) => updateCustomField({ field: 'custom_number', value: evt })}
									/>
									<small>
										Additional price of {formatCurrencyValueFromServer(product.customNumberPrice || 0)}
									</small>
								</Grid>
							)}
						</>
					)}
				</Grid>
			</OptionsBlock>
		</>
	);
};

export default CustomNameNumber;
