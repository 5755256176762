import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';
import React, { ReactElement } from 'react';

import Button from '../../components/Button';
import { formatCurrencyValueFromServer, handleStoreDiscount } from '../../core/utilities/currency';
import useBundleStore from '../../stores/bundles/useBundleStore';
import { useConfigStore } from '../../stores/productConfiguration/useConfigureProduct';
import { getShortSizeDescriptor } from '../../utils/sizeHelpers';
import { ProductOptionValue, SimplifiedProduct } from './ParseServerProductUtilities';
import { OptionsBlock, Separator } from './ProductDetailStyledComponents';

interface Props {
	style?: React.CSSProperties;
	product: SimplifiedProduct;
}

interface HandleOptionClickArgs {
	optionId: string;
	clickedOptionValue: ProductOptionValue;
}

const ProductOptions = ({ product }: Props): ReactElement => {
	const isBundle = useBundleStore((state) => state.isBundle);
	const { currentConfig, updateOption } = useConfigStore();

	const handleOptionClick = ({ optionId, clickedOptionValue }: HandleOptionClickArgs) => {
		const modifiedPriceWithDiscount = handleStoreDiscount({
			allowNegativeValues: isBundle ? false : true,
			discountRate: product.discountRate,
			type: product.discountType,
			incomingValue: clickedOptionValue.priceModifier
		});

		updateOption({
			optionId,
			value: {
				value: clickedOptionValue.label,
				availableOptionValueId: clickedOptionValue.availableOptionValueId,
				label: clickedOptionValue.label,
				priceModifiedAs: modifiedPriceWithDiscount,
				imageUrl: clickedOptionValue.imageUrl || undefined,
				availableOptionId: optionId
			}
		});
	};

	const getPriceModifier = (availableOptionId: string) => {
		const selected =
			currentConfig?.options &&
			Object.values(currentConfig?.options).filter((s) => s.availableOptionId === availableOptionId);
		const modifiedPrice = selected?.[0]?.priceModifiedAs;
		if (modifiedPrice && modifiedPrice !== 0) {
			if (modifiedPrice > 0) {
				return `(+${formatCurrencyValueFromServer(modifiedPrice)})`;
			} else {
				return `(${formatCurrencyValueFromServer(modifiedPrice)})`;
			}
		} else {
			return '';
		}
	};

	return (
		<>
			{product.options.map((option) => (
				<React.Fragment key={option.optionId}>
					<Separator />
					<small>
						<strong>Select a {option.displayName}</strong>
						&nbsp;{getPriceModifier(option.optionId)}
					</small>
					<OptionsBlock>
						{option.values.map((optionValue) => (
							<Button
								padding="small"
								key={`${optionValue.availableOptionValueId}`}
								color={
									currentConfig?.options?.[option.optionId]?.availableOptionValueId ===
									optionValue.availableOptionValueId
										? 'black'
										: 'grey'
								}
								useColorForBorder={!!optionValue.imageUrl}
								onClick={() => handleOptionClick({ optionId: option.optionId, clickedOptionValue: optionValue })}
							>
								{optionValue.imageUrl ? (
									<Tooltip title={optionValue.label}>
										<StyledImage src={optionValue.imageUrl} alt={optionValue.label} loading="lazy" />
									</Tooltip>
								) : (
									<span>{getShortSizeDescriptor(option.optionName, optionValue.label)}</span>
								)}
							</Button>
						))}
					</OptionsBlock>
				</React.Fragment>
			))}
		</>
	);
};
const StyledImage = styled.img`
	max-width: 80px;
	height: auto;
	object-fit: contain; /* Ensures images fit within the given dimensions without distortion */
`;

export default ProductOptions;
