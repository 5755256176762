import ChevronRight from '@mui/icons-material/ChevronRight';
import { Breadcrumbs } from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { BreadcrumbsWrapper, Separator } from '../../pages/ProductDetail/ProductDetailStyledComponents';
import { useBreadcrumbs } from '../../providers/breadcrumbProvider';

const BreadcrumbConnectedWrapper: React.FC = () => {
	const { breadcrumbs } = useBreadcrumbs();

	return (
		<BreadcrumbsWrapper>
			<Separator />
			<Breadcrumbs aria-label="breadcrumb" separator={<ChevronRight width={18} height={18} />}>
				{breadcrumbs.map((breadcrumb) => (
					<NavLink key={breadcrumb.path} to={breadcrumb.path}>
						<p className="no-margin">
							<strong>{breadcrumb.name}</strong>
						</p>
					</NavLink>
				))}
			</Breadcrumbs>
		</BreadcrumbsWrapper>
	);
};

export default BreadcrumbConnectedWrapper;
