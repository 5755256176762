import { LoaderFunction } from 'react-router-dom';

import {
	GetPackageByPackageIdDocument,
	GetPackageByPackageIdQuery,
	GetPackageProductsByIdDocument,
	GetPackageProductsByIdQuery
} from '../../generated/graphql';
import { createApolloClient } from '../../utils/apolloClient';
import { PreprocessProduct, SimplifiedProduct } from '../ProductDetail/ParseServerProductUtilities';

const apolloClient = createApolloClient();

interface LoaderParams {
	bundleId?: string;
}

export interface BundleDataLoaderReturn {
	bundleInfo: GetPackageByPackageIdQuery;
	bundleProducts: SimplifiedProduct[];
}

const ProductDetailLoader: LoaderFunction = async ({ params }: { params: LoaderParams }) => {
	const { bundleId } = params;

	if (!bundleId) {
		throw new Error('Package ID is missing');
	}

	try {
		const { data: packageData } = await apolloClient.query<GetPackageByPackageIdQuery>({
			query: GetPackageByPackageIdDocument,
			variables: {
				_eq: bundleId
			}
		});

		if (!packageData) {
			throw new Error('Failed to fetch package');
		}
		const { data } = await apolloClient.query<GetPackageProductsByIdQuery>({
			query: GetPackageProductsByIdDocument,
			variables: {
				_in: packageData.store_package[0].product_ids
			}
		});

		if (!data) {
			throw new Error('Failed to fetch product data');
		}

		const simplifiedProducts = data.store_product.map((product) => PreprocessProduct(product));

		return {
			bundleInfo: packageData,
			bundleProducts: simplifiedProducts
		};
	} catch (error) {
		throw new Error(`Loader error:`);
	}
};

export default ProductDetailLoader;
