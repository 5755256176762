import { Grid, Typography } from '@mui/material';
import React from 'react';

import BodyWrapper from '../core/components/BodyWrapper';
import BreadcrumbConnectedWrapper from '../core/components/BreadcrumbConnectedWrapper';

const PrivacyPolicy: React.FC = () => {
	return (
		<>
			<BreadcrumbConnectedWrapper />
			<BodyWrapper>
				<Grid container columnSpacing={2} rowSpacing={2}>
					<Grid item xs={12}>
						<h3>Privacy Policy</h3>
					</Grid>

					<Grid item xs={12} md={8}>
						<Typography variant="body1" paragraph>
							At Breakmark, we are committed to protecting your privacy. This Privacy Policy explains how we
							collect, use, and safeguard your personal information.
						</Typography>
						<Typography variant="h5" gutterBottom>
							1. Information We Collect
						</Typography>
						<Typography variant="body1" paragraph>
							We collect information you provide directly to us, such as when you create an account, place an order,
							or contact our customer support.
						</Typography>
						<Typography variant="h5" gutterBottom>
							2. How We Use Your Information
						</Typography>
						<Typography variant="body1" paragraph>
							We use the information we collect to provide, maintain, and improve our services, process
							transactions, and communicate with you.
						</Typography>
					</Grid>

					<Grid item xs={12} md={4}></Grid>
				</Grid>
			</BodyWrapper>
		</>
	);
};

export default PrivacyPolicy;
