import { AdditionalImage } from '../../core/types/ProductDef';
import { ProductByIdFragment } from '../../generated/graphql';
import { Product } from '../../stores/cart/action';

// Defines a single option value that a user can select, with related information.
export interface ProductOptionValue {
	availableOptionValueId: string; // Unique ID for the option value (e.g., "Small", "Medium", etc.)
	label: string; // The name or label for the option value (e.g., "Small", "Large")
	priceModifier: number; // The price modifier associated with this option (e.g., +$5 for larger sizes)
	imageUrl: string | null; // URL for the image associated with the option, if any
	secondaryImageUrl: string | null; // Optional secondary image URL for more visual context
}

// Represents a product option (e.g., "Size", "Color") and the possible values for that option.
export interface ProductOption {
	optionId: string; // Unique ID for the product option (e.g., "Size", "Color")
	optionName: string; // The internal name for the option (e.g., "Size")
	displayName: string; // The user-friendly name for the option (e.g., "Select Size")
	values: ProductOptionValue[]; // A list of all available values for this option
}

// This interface is a cleaned-up version of the raw product data that combines important fields in a more usable format.
export interface SimplifiedProduct {
	id: string; // Product ID
	label: string; // Product label
	name: string; // Team store or product name
	basePrice: number; // Base price of the product (can be null if not set)
	description?: string; // Optional description of the product
	options: ProductOption[]; // A list of all product options with their values (cleaned and mapped)
	sizeChartUrl?: string; // URL to the size chart, if available
	customPriceOverride?: number; // Custom price override for the product
	customNameEnabled: boolean; // Whether custom names are allowed on the product
	customNamePrice?: number; // Price for adding a custom name, if allowed
	customNumberEnabled: boolean; // Whether custom numbers are allowed on the product
	customNumberPrice?: number; // Price for adding a custom number, if allowed
	discountRate?: number; // Discount rate for the product (could be a percentage or dollar-based)
	discountType?: 'dollar' | 'percent'; // Discount type (e.g., "dollar" or "percent")
	displayPhoto?: string; // The main product display image, if available
	additionalImages?: AdditionalImage[]; // Additional images, if available
	customNameNumberDropdown?: {
		name: string;
		number: number;
	}[];
}

// Utility to map raw product options and their values to a cleaned structure. This avoids repetitive mapping in the component.
// We are transforming the `options` and `option_values` into a more usable format for rendering in the UI.
const mapProductOptions = (product: ProductByIdFragment): ProductOption[] => {
	return product.options.map((opt) => {
		// Filter option values that belong to the current option (e.g., find all size options for the "Size" group)
		const values = product.option_values
			.filter((ov) => ov.available_option_id === opt.available_option.id)
			.map(
				(ov): ProductOptionValue => ({
					// For each option value, extract relevant details such as the ID, label, price, and images
					availableOptionValueId: ov.available_option_value?.id, // Ensure we have a value ID, fall back to empty if missing
					label: ov.label_override || ov.available_option_value?.label || '', // Use the overridden label if available
					priceModifier: ov.price_modifier_override ?? ov.available_option_value?.price_modifier ?? 0, // Handle price overrides
					imageUrl: ov.image_url || null, // Extract the image URL if provided
					secondaryImageUrl: ov.secondary_image_url || null // Extract secondary image URL if available
				})
			);

		// Return the mapped product option with its associated values (e.g., "Size" with "Small", "Medium", etc.)
		return {
			optionId: opt.available_option.id, // The ID for the product option (e.g., "Size")
			optionName: opt.available_option.name, // The internal name for the option (e.g., "Size")
			displayName: opt.available_option.display_name || opt.available_option.name, // Use the display name or fall back to the internal name
			values // The list of option values that belong to this option (e.g., "Small", "Medium", "Large")
		};
	});
};

// Utility to transform the entire raw product fragment into a cleaner structure.
// This makes the product easier to work with in the components, as we pre-process the data before passing it down.
export const PreprocessProduct = (product: ProductByIdFragment): SimplifiedProduct => {
	return {
		id: product.id, // Product ID from the raw data
		label: product.label || 'Product Label',
		name: product.team_store.name, // Name from the team store
		basePrice: product.product.base_price ?? 0, // Use the base price or default to 0 if null
		description: product.description || undefined, // Product description, if available
		displayPhoto: product.display_photo || undefined, // Main product image, if available
		additionalImages: product.additional_image_list || undefined, // Additional images, if available
		customPriceOverride: product.custom_price_override || undefined, // Custom price override for the product
		customNameEnabled: product.custom_name_enabled, // Whether custom names are allowed on this product
		customNamePrice: product.custom_name_price || undefined, // Price for custom name, if applicable
		customNumberEnabled: product.custom_number_enabled, // Whether custom numbers are allowed on this product
		customNumberPrice: product.custom_number_price || undefined, // Price for custom number, if applicable
		options: mapProductOptions(product), // Pre-map all the product options and values
		sizeChartUrl: product.product.size_chart_url || undefined, // URL for the size chart, if available
		discountRate: product.team_store.store_discount_rate || undefined, // Discount rate, if set
		discountType:
			product.team_store.store_discount_type === 'dollar' || product.team_store.store_discount_type === 'percent'
				? product.team_store.store_discount_type
				: undefined, // Discount type (e.g., "dollar", "percent")
		customNameNumberDropdown: product.custom_name_number_dropdown || undefined
	};
};

export function TransformProductForConfig(product: SimplifiedProduct): Partial<Product> {
	return {
		id: product.id,
		type: 'product',
		label: product.label || 'Product Label',
		price: product.basePrice || 0,
		custom_name: undefined,
		custom_number: undefined,
		quantity: 1
	};
}
