import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import React from 'react';

import { RouteDefinitions } from '../routeDefinitions';
import EmailSignupBox from './EmailSignupBox';

function Footer() {
	const FooterWrapper = styled('div')`
		display: flex;
		width: 95%;
		max-width: 1240px;
		margin: auto;
		padding: 50px 0 80px 0;
		@media only screen and (max-width: 900px) {
			padding: 30px 0 70px 0;
		}
	`;

	const Separator = styled('div')`
		width: 100%;
		border-bottom: 1px solid var(--separator);
		margin: 24px 0;
	`;

	const Copyright = styled('p')`
		display: flex;
		justify-content: flex-start;
		align-items: center;
		@media only screen and (max-width: 900px) {
			justify-content: center;
		}
	`;

	const BadgesWrapper = styled('div')`
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-right: 5%;
		@media only screen and (max-width: 900px) {
			justify-content: center;
			margin-right: 0;
		}
	`;

	const Badge = styled('img')`
		width: 46px;
		height: 30px;
	`;

	return (
		<footer className="grey-box" style={{ marginTop: 100 }}>
			<FooterWrapper>
				<Grid container rowSpacing={2} columnSpacing={2}>
					<Grid item xs={12} lg={4}>
						<h4>BREAKMARK.COM</h4>
						<p>
							Get your team ready with customizable apparel that matches your style and pride. Gear up with designs
							that let you stand out and represent!
						</p>
					</Grid>

					<Grid item xs={6} md={3} lg={2}>
						<p>COMPANY</p>
						<p>
							<a href={RouteDefinitions.ABOUT()}>About</a>
						</p>
						<p>
							<a href="https://www.instagram.com/breakmark/" target="_blank" rel="noreferrer">
								Instagram
							</a>
						</p>
						<p>
							<a href="https://www.facebook.com/Breakmark/" target="_blank" rel="noreferrer">
								Facebook
							</a>
						</p>
						<p>
							<a href="https://twitter.com/breakmarkulti" target="_blank" rel="noreferrer">
								X
							</a>
						</p>
					</Grid>
					<Grid item xs={6} md={3} lg={2}>
						<p>HELP</p>
						<p>
							<a href={RouteDefinitions.CUSTOMER_SUPPORT()}>Customer Support</a>
						</p>
						<p>
							<a href={RouteDefinitions.DESIGN_SIZING()}>Sizing</a>
						</p>
						<p>
							<a href={RouteDefinitions.TERMS_AND_CONDITIONS()}>Terms & Conditions</a>
						</p>
						<p></p>
					</Grid>

					<Grid item xs={6} md={3} lg={2}>
						<p>FAQ</p>
						<p>
							<a href={RouteDefinitions.FAQ()}>Frequently Asked Questions</a>
						</p>
						<p>
							<a href={RouteDefinitions.DESIGN_TEMPLATES()}>Design Templates</a>
						</p>
					</Grid>
					<Grid item xs={6} md={3} lg={2}>
						<p>RESOURCES</p>
						<p>
							<a href={'https://teams.breakmark.com/group/collaborations'}>Collaboration Store</a>
						</p>
						<p>
							<a href={RouteDefinitions.DESIGN_INSPIRATION()}>Design Inspiration</a>
						</p>
					</Grid>

					<Grid item xs={12}>
						<Separator />
					</Grid>
					<Grid item xs={12} md={6}>
						<Copyright>Breakmark.com &copy; 2024. All Rights Reserved</Copyright>
					</Grid>
					<Grid item xs={12} md={6}>
						<BadgesWrapper>
							<Badge src={'/images/cards/visa.png'} alt={'Visa'} />
							<Badge src={'/images/cards/mastercard.png'} alt={'Mastercard'} />
							<Badge src={'/images/cards/paypal.png'} alt={'PayPal'} />
							<Badge src={'/images/cards/applepay.png'} alt={'ApplePay'} />
							<Badge src={'/images/cards/googlepay.png'} alt={'GooglePay'} />
						</BadgesWrapper>
					</Grid>
				</Grid>
			</FooterWrapper>
		</footer>
	);
}

export default Footer;
