import React from 'react';

import { SimplifiedProduct } from './ParseServerProductUtilities';
import CustomNameNumber from './ProductDetailCustomNameNumber';
import { Separator } from './ProductDetailStyledComponents';
import ProductOptions from './ProductOptions';

interface ProductCustomizationProps {
	product: SimplifiedProduct;
}

function ProductCustomization({ product }: ProductCustomizationProps) {
	return (
		<>
			{product.options.length > 0 ? (
				<>
					<ProductOptions product={product} />
					<Separator />
				</>
			) : null}
			{product.customNameEnabled || product.customNumberEnabled ? (
				<>
					<CustomNameNumber product={product} />
					<Separator />
				</>
			) : null}
		</>
	);
}

export default ProductCustomization;
