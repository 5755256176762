import { Checklist } from '@mui/icons-material';
import { SvgIcon } from '@mui/material';
import React from 'react';

import { SimplifiedProduct } from '../ProductDetail/ParseServerProductUtilities';
import { BundleStep, BundleStepsWrapper, ProductImage, ProductLabel } from './BundleDetailStyledComponents';

interface BundleProductStepsProps {
	products: SimplifiedProduct[];
	activeStep: number;
	isReviewStep: boolean;
	totalSteps: number;
	onStepClick: (step: number) => void;
}

const BundleProductSteps: React.FC<BundleProductStepsProps> = ({
	products,
	activeStep,
	isReviewStep,
	totalSteps,
	onStepClick
}) => {
	return (
		<BundleStepsWrapper>
			{products.map((product, index) => (
				<BundleStep
					key={product.id}
					isActive={index === activeStep}
					isDisabled={index > activeStep}
					onClick={() => onStepClick(index)}
				>
					<ProductImage src={product.displayPhoto || ''} alt={product.label || ''} />
					<ProductLabel>
						<p>{product.label}</p>
					</ProductLabel>
				</BundleStep>
			))}
			<BundleStep isActive={isReviewStep} isDisabled={activeStep < totalSteps} onClick={() => onStepClick(totalSteps)}>
				<SvgIcon component={Checklist} sx={{ fontSize: 55 }} />
				<ProductLabel>
					<p>Review Bundle</p>
				</ProductLabel>
			</BundleStep>
		</BundleStepsWrapper>
	);
};

export default React.memo(BundleProductSteps);
