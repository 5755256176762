import styled from '@emotion/styled';
import React from 'react';

interface HeroProps {
	heading: string;
	label: string;
	imageUrl?: string;
}

const HeroWrapperContainer = styled('div')`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 250px;
	width: 100%;
	background-color: #000;
	color: #ffffff;
	overflow: hidden;
`;

const HeroContent = styled('div')<{ imageUrl?: string }>`
	position: relative;
	height: 100%;
	width: 100%;
	max-width: 1240px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 16px;
`;

const BackgroundImage = styled('div')<{ imageUrl?: string }>`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-image: ${({ imageUrl }) => (imageUrl ? `url(${imageUrl})` : 'none')};
	background-position: right center;
	background-repeat: no-repeat;
	background-size: 75% auto;
	opacity: 0.7;

	@media (max-width: 1000px) {
		background-position: center;
		background-size: cover;
	}
`;

const Overlay = styled('div')`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0) 100%);

	@media (max-width: 1000px) {
		background: rgba(0, 0, 0, 0.6);
	}
`;

const ContentWrapper = styled('div')`
	position: relative;
	z-index: 2;
	max-width: 50%;

	@media (max-width: 1000px) {
		max-width: 100%;
	}
`;

const Heading = styled('h1')`
	font-size: 3rem;
	margin-bottom: 8px;
	color: #fff;
`;

const Label = styled('p')`
	font-size: 1rem;
	margin: 0 0 16px;
	color: #fff;
	font-weight: 500;
`;

const HeroContainer: React.FC<HeroProps> = ({ heading, label, imageUrl }) => {
	return (
		<HeroWrapperContainer>
			<HeroContent>
				<BackgroundImage imageUrl={imageUrl} />
				<Overlay />
				<ContentWrapper>
					<Label>{label}</Label>
					<Heading>{heading}</Heading>
				</ContentWrapper>
			</HeroContent>
		</HeroWrapperContainer>
	);
};

export default HeroContainer;
