import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';

function Group() {
	const BodyWrapper = styled('div')`
		display: block;
		width: 95%;
		max-width: 1240px;
		margin: 0 auto 24px;
	`;

	type PanelProps = {
		src: string;
	};
	const HeaderPanel = styled('div')(
		(props: PanelProps) => `
        display: block;
        background-color: var(--black);
        background-image: url("${props.src}");
        background-size: cover;
        height: 250px;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 20px;
        box-sizing: border-box;
        margin-bottom: 24px;
        @media only screen and (max-width: 900px) {
            background-size: contain;
            height: 190px;
        }
        &:hover {
            text-decoration: none;
        }
    `
	);

	const SectionLogo = styled('div')(
		(props: PanelProps) => `
        display: block;
        background-color: var(--black);
        background-image: url("${props.src}");
        background-size: 90%;
        height: 250px;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 20px;
        box-sizing: border-box;
        margin-bottom: 24px;
        @media only screen and (max-width: 900px) {
            background-size: contain;
            height: 190px;
        }
        &:hover {
            text-decoration: none;
        }
    `
	);

	const PartnerImage = styled('div')(
		(props: PanelProps) => `
        background-color: var(--grey);
        background-image: url("${props.src}");
        background-size: 110%;
        box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
        aspect-ratio: 1;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 50%;
        border: 1px solid var(--separator);
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px;
        cursor: pointer;
        overflow: hidden;
       
        &:hover {
            filter: invert(100%);
        }
    `
	);

	const TeamLink = styled(NavLink)`
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: start;
		gap: 12px;
		text-align: center;

		&:hover {
			text-decoration: none;
		}
	`;

	return (
		<BodyWrapper>
			<Grid container columnSpacing={2} rowSpacing={2}>
				<Grid item xs={12} sm={6} md={3}>
					<SectionLogo src={'/images/partners/western-ultimate-league.png'} />
				</Grid>
				<Grid item xs={12} sm={6} md={9}>
					<HeaderPanel src={'/images/backgrounds/wul.png'} />
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<Grid container columnSpacing={2} rowSpacing={2}>
						<Grid item xs={6} display={'flex'} justifyContent={'center'}>
							<TeamLink to={'/Group/wul/Arizona'}>
								<PartnerImage src={'https://assets.breakmark.com/prod/bpnC-jzPJ.jpg'} />
								<h6>Arizona Sidewinders</h6>
							</TeamLink>
						</Grid>
						<Grid item xs={6} display={'flex'} justifyContent={'center'}>
							<TeamLink to={'/Group/wul/Arizona'}>
								<PartnerImage src={'https://assets.breakmark.com/prod/y8Az0owxZ.jpg'} />
								<h6>Bay Area Falcons</h6>
							</TeamLink>
						</Grid>
						<Grid item xs={6}>
							<TeamLink to={'/Group/wul/Arizona'}>
								<PartnerImage src={'https://assets.breakmark.com/prod/BSiVjTLe4.jpg'} />
								<h6>Colorado Alpenglow</h6>
							</TeamLink>
						</Grid>
						<Grid item xs={6}>
							<TeamLink to={'/Group/wul/Arizona'}>
								<PartnerImage src={'https://assets.breakmark.com/prod/jzH5p9gxc.jpg'} />
								<h6>San Diego Super Bloom</h6>
							</TeamLink>
						</Grid>
						<Grid item xs={6}>
							<TeamLink to={'/Group/wul/Arizona'}>
								<PartnerImage src={'https://assets.breakmark.com/prod/OTajR72Dg.jpg'} />
								<h6>Seattle Tempest</h6>
							</TeamLink>
						</Grid>
						<Grid item xs={6}>
							<TeamLink to={'/Group/wul/Arizona'}>
								<PartnerImage src={'https://assets.breakmark.com/prod/y7g7fZCdV.jpg'} />
								<h6>Utah Wild</h6>
							</TeamLink>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} sm={6} md={9}>
					<h2 style={{ textAlign: 'center' }}>Western Ultimate League</h2>
					<p>Welcome to the Western Ultimate League replica store!</p>
					<p>
						<strong>
							For the Socks and Blankets sale, there is no code needed. The 30% discount has already been applied
							and the price has already been reduced.
						</strong>
					</p>
					<p>A portion of all sales will go to the teams.</p>
					<p>
						Please keep in mind that these are all custom, made to order items. We are going to process orders every
						Monday, which means that whatever day you order, your order will go in to production the following Monday.
						It will then take about 5 weeks until it ships out. We will forward tracking via email once your order
						ships.
					</p>
					<p>We do not take exchanges or returns on custom, made to order items.</p>
				</Grid>
			</Grid>
		</BodyWrapper>
	);
}

export default Group;
