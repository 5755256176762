import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { Product } from '../cart/action';

interface BundleState {
	bundleItems: { [id: string]: Product };
	isBundle: boolean;
	addProductToBundle: (params: { product: Product }) => void;
	removeProductFromBundle: (params: { productId: string }) => void;
	clearBundle: () => void;
	setIsBundle: (params: { isBundle: boolean }) => void;
}

export const useBundleStore = create<BundleState>()(
	devtools(
		(set) => ({
			bundleItems: {},
			isBundle: false,

			addProductToBundle: ({ product }) =>
				set(
					(state) => ({
						bundleItems: {
							...state.bundleItems,
							[product.id]: product
						}
					}),
					false,
					'addProductToBundle'
				),

			removeProductFromBundle: ({ productId }) =>
				set(
					(state) => {
						const { [productId]: _, ...remainingItems } = state.bundleItems;
						return { bundleItems: remainingItems };
					},
					false,
					'removeProductFromBundle'
				),

			clearBundle: () => set({ bundleItems: {} }, false, 'clearBundle'),

			setIsBundle: ({ isBundle }) => set({ isBundle }, false, 'setIsBundle')
		}),
		{ name: 'Bundle Store' }
	)
);

export default useBundleStore;
