import styled from '@emotion/styled';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React, { useEffect, useRef, useState } from 'react';

import FeatureCard from './FeatureCard';
import SmallFeatureCard from './SmallFeatureCard';
import { Feature } from './types';

interface FeatureGalleryProps {
	features: Feature[];
	cardType: 'feature' | 'small';
	onFeatureClick: (feature: Feature) => void;
}

const FeatureGallery: React.FC<FeatureGalleryProps> = ({ features, onFeatureClick, cardType }) => {
	const [scrollPosition, setScrollPosition] = useState(0);
	const scrollContainerRef = useRef<HTMLDivElement>(null);

	const handleScroll = (direction: 'left' | 'right') => {
		if (scrollContainerRef.current) {
			const cardWidth = scrollContainerRef.current.children[0].clientWidth;
			const gap = parseInt(getComputedStyle(scrollContainerRef.current).getPropertyValue('column-gap'));
			const scrollAmount = cardWidth + gap;
			const newPosition =
				direction === 'left'
					? Math.max(0, scrollPosition - scrollAmount)
					: Math.min(
							scrollContainerRef.current.scrollWidth - scrollContainerRef.current.clientWidth,
							scrollPosition + scrollAmount
						);

			scrollContainerRef.current.scrollTo({
				left: newPosition,
				behavior: 'smooth'
			});
			setScrollPosition(newPosition);
		}
	};

	useEffect(() => {
		const container = scrollContainerRef.current;
		if (container) {
			const handleScrollEvent = () => {
				setScrollPosition(container.scrollLeft);
			};
			container.addEventListener('scroll', handleScrollEvent);
			return () => container.removeEventListener('scroll', handleScrollEvent);
		}
	}, []);

	const isAtStart = scrollPosition === 0;
	const isAtEnd = scrollContainerRef.current
		? scrollPosition + scrollContainerRef.current.clientWidth >= scrollContainerRef.current.scrollWidth
		: false;

	const CardComponent = cardType === 'feature' ? FeatureCard : SmallFeatureCard;

	return (
		<GalleryContainer>
			<ScrollContainer ref={scrollContainerRef}>
				{features.map((feature) => (
					<CardComponent
						dialogImage={feature.dialogImage}
						shortDescription={feature.shortDescription}
						description={feature.description}
						id={feature.id}
						key={feature.id}
						title={feature.title}
						subtitle={feature.subtitle}
						imageUrl={feature.imageUrl}
						icon={feature.icon}
						onClick={() => onFeatureClick(feature)}
						bgColor={feature.bgColor}
					/>
				))}
			</ScrollContainer>
			<NavigationButton direction="left" onClick={() => handleScroll('left')} disabled={isAtStart}>
				<ChevronLeftIcon fontSize="large" />
			</NavigationButton>
			<NavigationButton direction="right" onClick={() => handleScroll('right')} disabled={isAtEnd}>
				<ChevronRightIcon fontSize="large" />
			</NavigationButton>
		</GalleryContainer>
	);
};

const GalleryContainer = styled.div`
	position: relative;
	width: 100%;
	overflow: hidden;
	--gallery-side-padding: calc(50vw - min(1680px, 87.5vw) / 2);
	--gallery-column-gap: 20px;
	padding: 40px var(--gallery-side-padding); // Added vertical padding
`;

const ScrollContainer = styled.div`
	display: flex;
	overflow-x: scroll;
	scroll-snap-type: x mandatory;
	-webkit-overflow-scrolling: touch;
	scrollbar-width: none;
	-ms-overflow-style: none;
	&::-webkit-scrollbar {
		display: none;
	}
	column-gap: var(--gallery-column-gap);
	padding: 25px;
`;

const NavigationButton = styled.button<{ direction: 'left' | 'right' }>`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	${(props) => (props.direction === 'left' ? 'left: 10px;' : 'right: 10px;')}
	background: #f5f5f5;
	border: none;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
	svg {
		width: 36px;
		height: 36px;
		fill: currentColor;
	}
`;

export default FeatureGallery;
