import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useBreadcrumbs } from '../../providers/breadcrumbProvider';
import useBundleStore from '../../stores/bundles/useBundleStore';
import BundleHeaderPrice from './BundleHeaderPrice';

interface BundleDetailHeaderProps {
	bundleName: string;
	children: React.ReactNode;
	totalPrice: number;
}

const BundleHeaderContent = styled('div')`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px;
`;
const BundleName = styled('h4')`
	margin: 0;
`;

const BundleHeaderWrapper = styled('div')`
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-bottom: 24px;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	background: linear-gradient(0deg, var(--separator) 50%, var(--white) 100%);
`;

function BundleDetailHeader({ bundleName, children, totalPrice }: BundleDetailHeaderProps) {
	const { addBreadcrumb, removeBreadcrumb } = useBreadcrumbs();
	const { setIsBundle } = useBundleStore();
	const location = useLocation();

	useEffect(() => {
		addBreadcrumb({
			name: 'Bundle Selector',
			path: location.pathname
		});
		setIsBundle({ isBundle: true });
		return () => {
			setIsBundle({ isBundle: false });
			removeBreadcrumb(location.pathname);
		};
	}, [bundleName, addBreadcrumb, removeBreadcrumb, location]);

	return (
		<BundleHeaderWrapper>
			<BundleHeaderContent>
				<BundleName>{bundleName}</BundleName>
				<BundleHeaderPrice totalPrice={totalPrice} />
			</BundleHeaderContent>
			{children}
		</BundleHeaderWrapper>
	);
}

export default BundleDetailHeader;
