import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { RouteDefinitions } from '../../routeDefinitions';
import SimpleConfirmationDialog from './SimpleConfirmationDialog';

interface ProductAddedDialogProps {
	label: string;
	image: string;
	onClose: () => void;
}

const ProductAddedDialog: React.FC<ProductAddedDialogProps> = ({ label, image, onClose }) => {
	const { storeId, groupId } = useParams();
	const navigate = useNavigate();

	const handleContinue = () => {
		onClose();
		if (window.history.state) {
			window.history.back();
		} else {
			if (storeId) {
				navigate(RouteDefinitions.TEAM_STORE(storeId));
			} else if (groupId) {
				navigate(RouteDefinitions.GROUP(groupId));
			} else {
				navigate(RouteDefinitions.HOME());
			}
		}
	};

	const handleGoToCart = () => {
		if (storeId) {
			navigate(RouteDefinitions.TEAM_STORE_CART(storeId));
		} else if (groupId) {
			navigate(RouteDefinitions.GROUP(groupId));
		} else {
			navigate(RouteDefinitions.CART());
		}
	};

	return (
		<SimpleConfirmationDialog
			title="Added to Cart"
			message={label}
			image={image}
			primaryButtonText="Proceed to Cart"
			secondaryButtonText="Keep Shopping"
			onPrimaryAction={handleGoToCart}
			onSecondaryAction={handleContinue}
			onClose={onClose}
		/>
	);
};

export default ProductAddedDialog;
