import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import { Minus, Plus } from 'react-feather';

type InputProps = {
	value: number;
	size?: 'small' | 'medium';
	onQuantityChange: (newQuantity: number) => void;
};

const InputQuantityWrapper = styled('div')<{ size?: 'small' | 'medium' }>`
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 4px;
	padding: ${({ size }) => (size === 'small' ? '0' : '6px')};
	border: 1px solid var(--separator);
`;

const QuantityInput = styled('input')`
	background: transparent;
	border: none;
	outline: none;
	font-family: Satoshi-Variable, sans-serif;
	font-size: 16px;
	height: 30px;
	width: 100%;
	text-align: center;
	-moz-appearance: textfield;

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
`;

const StyledIconButton = styled(IconButton)<{ size?: 'small' | 'medium' }>`
	height: ${({ size }) => (size === 'small' ? '30px' : '40px')};
`;

function InputQuantity({ value, size = 'medium', onQuantityChange }: InputProps) {
	const [quantity, setQuantity] = useState<number>(value);

	const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = evt.target.value;
		const numericValue = parseInt(inputValue, 10);

		if (!isNaN(numericValue) || inputValue === '') {
			const newQuantity = inputValue === '' ? 0 : numericValue;
			setQuantity(newQuantity);
			onQuantityChange(newQuantity); // Notify parent of the change
		}
	};

	const handleDecrement = () => {
		const newQuantity = quantity > 0 ? quantity - 1 : 0;
		setQuantity(newQuantity);
		onQuantityChange(newQuantity); // Notify parent of the change
	};

	const handleIncrement = () => {
		const newQuantity = quantity + 1;
		setQuantity(newQuantity);
		onQuantityChange(newQuantity); // Notify parent of the change
	};

	return (
		<InputQuantityWrapper size={size} className="grey-box rounded">
			<StyledIconButton size={size} onClick={handleDecrement}>
				<Minus width={size === 'small' ? 18 : 24} />
			</StyledIconButton>
			<QuantityInput type="number" value={quantity} onChange={handleInputChange} />
			<StyledIconButton size={size} onClick={handleIncrement}>
				<Plus width={size === 'small' ? 18 : 24} />
			</StyledIconButton>
		</InputQuantityWrapper>
	);
}

export default InputQuantity;
