import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';

import Button from '../../components/Button';
import InputText from '../../components/InputText';
import { formatCurrencyValueFromServer } from '../../core/utilities/currency';
import { TeamStoreFragment } from '../../generated/graphql';

const CheckoutBlock = styled('div')`
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 16px 12px;
	border-radius: 12px;
	margin-bottom: 12px;
`;

const PaymentOptionsContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
	gap: 16px;
	margin-top: 8px;
`;

export interface CheckoutOptions {
	paymentType: 'card' | 'check';
	name: string;
	email: string;
	attributionSelected?: string | null;
}

const TotalContainer = styled('div')`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

interface Props {
	itemCount: number;
	cartTotal: number;
	storeInfo: TeamStoreFragment;
	attributionList?: string[];
	handleCheckout(checkoutOptions: CheckoutOptions): void;
}

const checkoutSchema = Yup.object().shape({
	name: Yup.string().required('Required'),
	email: Yup.string().email('Invalid email').required('Required'),
	paymentType: Yup.string().oneOf(['card', 'check'], 'Invalid payment type').required('Required'),
	attributionSelected: Yup.string().notRequired() // Explicitly make it not required
});

const CartCheckout = ({ attributionList, storeInfo, handleCheckout, itemCount, cartTotal }: Props) => {
	const {
		handleSubmit,
		control,
		formState: { errors, isValid, isSubmitting },
		setValue
	} = useForm<CheckoutOptions>({
		defaultValues: {
			name: '',
			email: '',
			paymentType: 'card',
			attributionSelected: undefined
		},
		resolver: yupResolver(checkoutSchema),
		mode: 'onChange'
	});

	const onSubmit = (values: CheckoutOptions) => {
		handleCheckout({
			email: values.email || '',
			name: values.name || '',
			paymentType: values.paymentType,
			attributionSelected: values?.attributionSelected
		});
	};

	const renderInputField = (name: keyof CheckoutOptions, placeholder: string, color: 'grey' | 'white') => (
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<InputText required placeholder={placeholder} color={color} {...field} error={Boolean(errors[name])} />
			)}
		/>
	);

	return (
		<Grid item xs={12} md={4}>
			<CheckoutBlock className={'grey-box'}>
				<h5>Order Summary</h5>
				<h6>
					<TotalContainer>
						<div>Total ({itemCount})</div>
						<div>{formatCurrencyValueFromServer(cartTotal)}</div>
					</TotalContainer>
				</h6>
			</CheckoutBlock>
			<CheckoutBlock className={'grey-box'}>
				<div>{renderInputField('name', 'Name', 'white')}</div>
				<div>{renderInputField('email', 'Email Address', 'white')}</div>
				<PaymentOptionsContainer>
					{storeInfo.enable_check_payment && (
						<Button
							disabled={!isValid || isSubmitting}
							color={'gold'}
							fullWidth={true}
							padding="small"
							onClick={handleSubmit((values) => {
								setValue('paymentType', 'check');
								onSubmit(values);
							})}
						>
							Pay By Check
						</Button>
					)}
					{storeInfo.enable_credit_card_payment && (
						<Button
							disabled={!isValid || isSubmitting}
							color={'green'}
							fullWidth={true}
							padding="small"
							onClick={handleSubmit((values) => {
								setValue('paymentType', 'card');
								onSubmit(values);
							})}
						>
							Pay By Card
						</Button>
					)}
				</PaymentOptionsContainer>
			</CheckoutBlock>
		</Grid>
	);
};

export default CartCheckout;
