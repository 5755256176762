import styled from '@emotion/styled';
import { Grid } from '@mui/material';

// Review container
export const ReviewContainer = styled(Grid)`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	width: 100%;
`;

// Each item in the review section
export const ReviewItem = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	border-bottom: 1px solid #ddd;
	padding: 20px 0;

	&:last-child {
		border-bottom: none;
	}
`;

// Image for each product
export const ReviewImage = styled.img`
	width: 150px; // Increased width for more prominence
	height: auto;
	object-fit: cover;
	margin-right: 20px;
`;

// Details column for each product
export const ReviewDetails = styled.div`
	display: flex;
	flex-direction: column;
	width: calc(100% - 200px); // Adjusted for image and price
`;

// Price on the far right
export const Price = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100px;
	font-weight: bold;
	font-size: 18px;
`;

// Text for configuration options
export const P3 = styled.p`
	font-size: 14px;
	line-height: 1.5;
	margin: 5px 0;
`;

// Add to Cart button container
export const AddToCartContainer = styled.div`
	margin-top: 40px;
	width: 100%;
	display: flex;
	justify-content: center;
`;
