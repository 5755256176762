import React from 'react';

import { SimplifiedProduct } from './ParseServerProductUtilities';

interface ProductDetailHeaderProps {
	product: SimplifiedProduct;
}

function ProductDetailHeader({ product }: ProductDetailHeaderProps) {
	return <h3>{product.label}</h3>;
}

export default ProductDetailHeader;
