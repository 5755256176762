import { Grid } from '@mui/material';
import React from 'react';

import { SecondaryButton } from './Button';

interface ProductDetailGenericActionsProps {
	buttonAction: () => void;
	buttonLabel: string;
}

function ProductDetailGenericActions({ buttonAction, buttonLabel }: ProductDetailGenericActionsProps) {
	return (
		<>
			<Grid container marginTop={2} rowSpacing={2} columnSpacing={2}>
				<Grid item sm={8} xs={12}>
					<SecondaryButton fullWidth={true} onClick={buttonAction}>
						{buttonLabel}
					</SecondaryButton>
				</Grid>
			</Grid>
		</>
	);
}

export default ProductDetailGenericActions;
