import styled from '@emotion/styled';
import React, { ReactNode } from 'react';

interface Props {
	children: ReactNode;
}

const BodyWrapper = ({ children }: Props) => {
	const Block = styled('div')`
		display: block;
		width: 95%;
		max-width: 1240px;
		margin: 24px auto;
	`;

	return <Block>{children}</Block>;
};

export default BodyWrapper;
