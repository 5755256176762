import styled from '@emotion/styled';
import React, { useState } from 'react';

interface FAQContent {
	question: string;
	answer: string;
}

interface FAQSection {
	title: string;
	content: FAQContent[];
}

const data: FAQSection[] = [
	{
		title: 'Orders',
		content: [
			{
				question: 'How do I start an order?',
				answer: 'Starting an order is easy! Click the Start your order button on any product or bundle page.'
			},
			{
				question: 'How long does it take to get my team order?',
				answer: 'Production is about 20-25 business days from start to ship. We start production when we have all order information complete.'
			},
			{
				question: 'What is a team store?',
				answer: "A team store is our way to collect all of your player's order information, simplifying the process with no spreadsheets or cash handling."
			}
		]
	},
	{
		title: 'Designs',
		content: [
			{
				question: 'What is the design process?',
				answer: "We work with you to help figure out what you want to purchase and how you'd like to design it."
			},
			{
				question: "I don't have any design experience, what do I do?",
				answer: 'No problem! Our talented team of designers will help bring your designs to life with any input you provide.'
			},
			{
				question: 'Can I get samples before placing the order?',
				answer: 'You pay a $20/sample fully refundable deposit. Once samples are returned, we refund the deposit.'
			}
		]
	},
	{
		title: 'Collaboration Store',
		content: [
			{
				question: 'What is the Collaboration Store?',
				answer: 'A collaboration store is a way for teams, events, and organizations to offer their products and fundraise.'
			},
			{
				question: 'How much money goes back to the team?',
				answer: 'Teams receive 20% of the sale.'
			},
			{
				question: 'When do teams get paid?',
				answer: 'Teams get paid at the end of their collaboration store period.'
			}
		]
	},
	{
		title: 'Shipping & Returns',
		content: [
			{
				question: 'Is shipping free?',
				answer: 'Team orders ship to 1 location for free, but individual player orders have associated shipping costs.'
			},
			{
				question: "If something doesn't fit can I get a refund?",
				answer: 'All products are custom and made to order, so we do not offer returns or exchanges.'
			},
			{
				question: 'What happens if there is something wrong with my order?',
				answer: 'If we made a mistake, we will remake your item at no charge.'
			}
		]
	}
];

const Container = styled.div`
	max-width: 800px;
	margin: 0 auto;
	padding: 2rem 1rem;
`;

const Title = styled.h1`
	text-align: center;
	margin-bottom: 2rem;
	color: #333;
`;

const SectionTitle = styled.h2`
	color: #333;
	margin: 2rem 0 1rem;
	font-size: 1.5rem;
`;

const FAQContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const FAQItem = styled.div`
	border: 1px solid #e0e0e0;
	border-radius: 8px;
	overflow: hidden;
`;

const Question = styled.button<{ isOpen: boolean }>`
	width: 100%;
	padding: 1rem;
	background: ${(props) => (props.isOpen ? '#f5f5f5' : 'white')};
	border: none;
	text-align: left;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: 500;

	&:hover {
		background: #f5f5f5;
	}

	&:after {
		content: '${(props) => (props.isOpen ? '−' : '+')}';
		font-size: 1.5rem;
		margin-left: 1rem;
	}
`;

const Answer = styled.div<{ isOpen: boolean }>`
	padding: ${(props) => (props.isOpen ? '1rem' : '0 1rem')};
	max-height: ${(props) => (props.isOpen ? '500px' : '0')};
	overflow: hidden;
	transition: all 0.3s ease-in-out;
	background: white;
`;

const FAQ: React.FC = () => {
	const [openItems, setOpenItems] = useState<string[]>([]);

	const toggleItem = (questionId: string) => {
		setOpenItems((prev) => (prev.includes(questionId) ? prev.filter((item) => item !== questionId) : [...prev, questionId]));
	};

	return (
		<Container>
			<Title>Frequently Asked Questions</Title>
			{data.map((section) => (
				<div key={section.title}>
					<SectionTitle>{section.title}</SectionTitle>
					<FAQContainer>
						{section.content.map((item, index) => {
							const questionId = `${section.title}-${index}`;
							return (
								<FAQItem key={questionId}>
									<Question
										isOpen={openItems.includes(questionId)}
										onClick={() => toggleItem(questionId)}
										aria-expanded={openItems.includes(questionId)}
									>
										{item.question}
									</Question>
									<Answer isOpen={openItems.includes(questionId)}>{item.answer}</Answer>
								</FAQItem>
							);
						})}
					</FAQContainer>
				</div>
			))}
		</Container>
	);
};

export default FAQ;
